import ContentContainer from 'components/layout/ContentContainer';
import { useContext, useState } from 'react';
import ProductsTopbar from './ProductsTopbar';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import { DataViewMode, ISelectOption, LIST_SORT } from 'types/globalTypes';
import { IProductDto } from 'types/marketpleace/product.interface';
import { getSorts } from './utils/helpers';
import ProductsTable from './table/ProductsTable';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import productServices from 'services/marketpleace/productServices';
import ProductsList from './table/ProductsList';
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Products = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const [sortBy, setSortBy] = useState<ISelectOption<LIST_SORT> | null>(null);

  const [viewType, setViewType] = useState<keyof typeof DataViewMode>('table');

  const [queryString, setQueryString] = useState('');

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: sellerProducts,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IProductDto>(
    productServices.getFiltered,
    debounceSearchInput
  );

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAdmin = permission[0]?.Id === -1;

  const isProductDetailActive = permission.some((tab: any) => tab.Id === 46);

  const detailproductPermission = isAdmin ? true : isProductDetailActive;

  return (
    <ContentContainer
      title={'Produkty'}
      TopBar={
        <ProductsTopbar
          refresh={refresh}
          sorts={getSorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleQueryChange={handleQueryChange}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
        />
      }>
      <div
        onClick={() => {
          if (!detailproductPermission) {
            toast.error('Nie masz uprawnień. Skontaktuj się z Administratorem')
          }
        }}
      >
        <div
          style={{
            pointerEvents: detailproductPermission ? 'auto' : 'none'
          }}
        >
          {viewType === 'table' ? (
            <ProductsTable
              data={sellerProducts}
              isDataLoading={loading}
              sortBy={sortBy}
              containerRef={containerRef}
              lastItemRef={lastItemRef}
            />
          ) : (
            <ProductsList
              data={sellerProducts}
              isDataLoading={loading}
              sortBy={sortBy}
              containerRef={containerRef}
              lastItemRef={lastItemRef}
            />
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Products;
