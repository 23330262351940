import { INavbarItem } from "./navbarTypes";

import { ReactComponent as HomeIco } from "assets/icons/new-home-ico.svg";
import { ReactComponent as AcademyIco } from "assets/icons/new-academy-ico.svg";
import { ReactComponent as EventIco } from "assets/icons/new-event-ico.svg";
import { ReactComponent as PlayerIco } from "assets/icons/new-player-ico.svg";
import { ReactComponent as ScannerIco } from "assets/icons/new-scanner-ico.svg";
import { ReactComponent as TrainerIco } from "assets/icons/new-trainer-ico.svg";
import { ReactComponent as StructureIco } from "assets/icons/new-structure-ico.svg";
import { ReactComponent as ShopIco } from "assets/icons/new-shop-ico.svg";
import { ReactComponent as ContactIco } from "assets/icons/new-contact-ico.svg";
import { ReactComponent as FilesIco } from "assets/icons/new-files-ico.svg";
import { ReactComponent as ManagmentIco } from "assets/icons/new-managment-ico.svg";

export const getEventsPathWithDate = () => {
  const today = new Date();

  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(today.getDate() - 3);

  const startYear = threeDaysAgo.getFullYear();
  const startMonth = (threeDaysAgo.getMonth() + 1).toString().padStart(2, "0");
  const startDay = threeDaysAgo.getDate().toString().padStart(2, "0");

  const startDate = `${startYear}-${startMonth}-${startDay}`;

  const threeDaysLater = new Date();
  threeDaysLater.setDate(today.getDate() + 3);

  const endYear = threeDaysLater.getFullYear();
  const endMonth = (threeDaysLater.getMonth() + 1).toString().padStart(2, "0");
  const endDay = threeDaysLater.getDate().toString().padStart(2, "0");

  const endDate = `${endYear}-${endMonth}-${endDay}`;

  return `/events?OccurenceDate=${startDate}T00%3A01%3A00Z&EndDate=${endDate}T23%3A59%3A00Z`;
};

export const navLinks: Array<INavbarItem> = [
  {
    label: "Home",
    icon: HomeIco,
    path: "/",
  },
  {
    label: "Moja akademia",
    icon: AcademyIco,
    path: "/academy",
  },
  {
    label: "Zajęcia",
    icon: EventIco,

    items: [
      {
        label: "Zajęcia",
        path: getEventsPathWithDate(),
      },
      {
        label: "Drafty",
        path: "/drafts",
      },
    ],
  },
  {
    label: "Zawodnicy",
    icon: PlayerIco,
    path: "/players",
  },
  {
    label: "Radar zawodników",
    icon: ScannerIco,
    items: [
      {
        label: "Dostępni zawodnicy",
        path: "/outside-players/available",
      },
      {
        label: "Zaproszenia",
        path: "/outside-players/requested",
      },
    ],
  },
  {
    label: "Trenerzy",
    icon: TrainerIco,
    path: "/trainers",
  },
  {
    label: "Radar trenerów",
    icon: ScannerIco,
    items: [
      {
        label: "Dostępni trenerzy",
        path: "/outside-trainers/available",
      },
      {
        label: "Zaproszenia",
        path: "/outside-trainers/requested",
      },
    ],
  },
  {
    label: "Grupy",
    icon: StructureIco,
    path: "/structure",
  },
  {
    label: "Sklep",
    icon: ShopIco,
    items: [
      {
        label: "Zamówienia",
        path: "/shop/orders",
      },
      {
        label: "Składki",
        path: "/shop/passes",
      },
      {
        label: "Produkty",
        path: "/shop/products",
      },
      {
        label: "Kategorie",
        path: "/shop/categories",
      },
      {
        label: "Atrybuty",
        path: "/shop/attributes",
      },
      {
        label: "Dostawy",
        path: "/shop/delivery",
      },
    ],
  },
  {
    label: "Kontakt",
    icon: ContactIco,
    path: "/contacts",
  },
  {
    label: "Pliki wewnętrzne",
    icon: FilesIco,
    path: "/files",
  },
  {
    label: "Zarządzanie",
    icon: ManagmentIco,
    path: "/users",
  },
];
