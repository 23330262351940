import ContentContainer from "components/layout/ContentContainer";
import { DefaultSortContext } from "contexts/defaultSortContext";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import React, { useContext, useEffect, useState } from "react";
import passService from "services/transfer/passServices";
import { ISelectOption } from "types/globalTypes";
// @ts-ignore
import Modal from "react-modal";
import {
  IPassTransaction,
  PASSES_SORT_LIST,
  PASS_TRANSACTION_STATUS,
} from "types/transfer/pass.interface";
import { useDebounce } from "use-debounce";
import PassesTopbar from "./PassesTopbar";
import PassesTable from "./PassesTable";
import { enumToSelectOptions } from "utils/baseUtils";
import { FilterPassData } from "types/filterPassData";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PassesDetail from "./PassesDetail";

const getTransactionSorts = enumToSelectOptions(PASSES_SORT_LIST);

function filterTransaction(data: any, filters: any, query: string) {
  return data.filter((obj: any) => {
    const transactionDateFrom = new Date(obj.Period.StartAt)
      .toISOString()
      .split("T")[0];
    const transactionDateTo = new Date(obj.Period.EndAt)
      .toISOString()
      .split("T")[0];

    const dataFrom = filters.dataFrom
      ? new Date(filters.dataFrom).toISOString().split("T")[0]
      : null;
    const dataTo = filters.dataTo
      ? new Date(filters.dataTo).toISOString().split("T")[0]
      : null;

    const isWithinDate =
      (!dataFrom || transactionDateFrom >= dataFrom) &&
      (!dataTo || transactionDateTo <= dataTo);

    const isStatusMatch =
      typeof filters.state !== "number" ||
      PASS_TRANSACTION_STATUS[obj.Status] ===
        PASS_TRANSACTION_STATUS[filters.state];

    const passPrice = obj.Price.Gross;
    const filterPassValueFrom =
      filters.passValueFrom !== undefined ? +filters.passValueFrom : null;
    const filterPassValueTo =
      filters.passValueTo !== undefined ? +filters.passValueTo : null;

    const filterPassByPrice =
      (!filterPassValueFrom || passPrice >= filterPassValueFrom) &&
      (!filterPassValueTo || passPrice <= filterPassValueTo);

    const searchPlayer = query ? obj.Buyer.Name.includes(query) : true;

    return isStatusMatch && isWithinDate && filterPassByPrice && searchPlayer;
  });
}

const Passes = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<ISelectOption<PASSES_SORT_LIST> | null>(null);
  const [queryString, setQueryString] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState<
    IPassTransaction[]
  >([]);
  const [selectedPassId, setSelectedPassId] = useState("");
  const permission = useSelector((state: any) => state.permissions.permissions);

  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    items: transactions,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IPassTransaction>(
    passService.getTransactions,
    debounceSearchInput,
  );

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  const [appliedFilter, setAppliedFilter] = useState({});

  const dataForFilterTable = (value: FilterPassData) => {
    setAppliedFilter(value);
  };

  useEffect(() => {
    const filteredData = filterTransaction(
      transactions,
      appliedFilter,
      debounceSearchInput,
    );

    if (Object.keys(appliedFilter).length !== 0 || debounceSearchInput) {
      setFilteredTransactions(filteredData);
    } else {
      setFilteredTransactions(transactions);
    }
  }, [appliedFilter, debounceSearchInput, transactions]);

  const isAdmin = permission[0]?.Id === -1;
  const isPermissionActive = permission.some((tab: any) => tab.Id === 216);

  const isPermissionPlayerDetail = permission.some(
    (tab: any) => tab.Id === 134,
  );

  const detailPassPermission =
    isAdmin || (isPermissionActive && isPermissionPlayerDetail);

  const closeModalOpen = () => {
    setIsModalOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  return (
    <ContentContainer
      title={"Składki"}
      TopBar={
        <PassesTopbar
          sortBy={sortBy}
          setSortBy={setSortBy}
          defaultSort={defaultSort}
          sorts={getTransactionSorts}
          handleQueryChange={handleQueryChange}
          refresh={refresh}
          dataForFilterTable={dataForFilterTable}
        />
      }
    >
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModalOpen}
        style={customStyles}
      >
        <PassesDetail
          selectedPassId={selectedPassId}
          closeModalOpen={closeModalOpen}
        />
      </Modal>

      {filteredTransactions.length === 0 &&
      Object.keys(appliedFilter).length !== 0 &&
      !debounceSearchInput.length ? (
        <div className="pl-8 text-base pt-8 flex w-full justify-center items-center text-center text-white opacity-70 h-12 font-medium bg-white bg-opacity-5 uppercase">
          Brak elementów spełniających filtrowanie
        </div>
      ) : (
        <div
          onClick={() => {
            if (!detailPassPermission) {
              toast.error(
                "Nie masz uprawnień. Skontaktuj się z Administratorem",
              );
            }
          }}
        >
          <div
            style={{
              pointerEvents: !detailPassPermission ? "none" : "auto",
            }}
          >
            <PassesTable
              containerRef={containerRef}
              data={
                filteredTransactions.length > 0
                  ? filteredTransactions
                  : transactions
              }
              isDataLoading={loading}
              lastItemRef={lastItemRef}
              sortBy={sortBy}
              detailPassPermission={detailPassPermission}
              selectedPassId={selectedPassId}
              setSelectedPassId={setSelectedPassId}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </div>
      )}
    </ContentContainer>
  );
};

export default Passes;
