import { forwardRef, useImperativeHandle, useState } from "react";
import cs from "classnames";

import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import PanelButtons from "./PanelButtons";

interface IDropdownProps {
  canAdd?: boolean;
  canEdit?: boolean;
  canSave?: boolean;
  label: string;
  initialExpanded?: boolean;
  editable?: boolean;
  hasChanged?: boolean;
  render?: (props: { mode: Modes }) => JSX.Element;
  onSubmit?: () => void;
  onClear?: () => void;
  key?: any;
  dropdownStyles?: React.CSSProperties;
  children?: any;
}

export enum Modes {
  Edit,
  View,
}

const DropdownPanel = forwardRef(
  (
    {
      canAdd = true,
      canEdit = true,
      canSave = true,
      label,
      initialExpanded = false,
      children,
      editable,
      hasChanged,
      onSubmit,
      onClear,
      render,
      dropdownStyles,
    }: IDropdownProps,
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(initialExpanded);
    const [mode, setMode] = useState(Modes.View);

    const handleExpand = () => {
      setIsExpanded(true);
    };

    const handleCollapse = () => {
      setIsExpanded(false);
    };

    useImperativeHandle(ref, () => ({
      collapse: handleCollapse,
    }));

    const handleToggle = () => {
      if (!isExpanded) {
        handleExpand();
      } else {
        handleCollapse();
      }
    };

    const arrowClassNames = cs("h-5 w-5 transform duration-200 select-none", {
      "rotate-90": isExpanded,
      "-rotate-90": !isExpanded,
    });

    const listClassNames = cs(
      "bg-white bg-opacity-20 inline-flex px-18 gap-3 w-full",
      {
        hidden: !isExpanded,
      }
    );

    return (
      <div className="w-full">
        <div
          className="flex items-center  py-12 px-18 cursor-pointer text-sm text-black text-opacity-70"
          style={{
            color: "white",
            background: "rgba(2,2,15,0.3)",
            fill: "white",
            stroke: "white",
            // borderLeft: "5px solid #FF9226",
          }}
          onClick={handleToggle}
        >
          <h6 className="flex-1">{label}</h6>
          {isExpanded && (
            <PanelButtons
              canAdd={canAdd}
              canEdit={canEdit}
              canSave={canSave}
              editable={editable}
              setMode={setMode}
              onSubmit={onSubmit}
              onClear={onClear}
              hasChanged={hasChanged}
              selectedMode={mode}
            />
          )}
          <Arrow className={arrowClassNames} />
        </div>
        <div
          className={listClassNames}
          style={{
            background: "rgba(163,158,157,0.05)",
            color: "white",
            display: !isExpanded ? "flex" : "none",
            justifyContent: "center",
            ...dropdownStyles,
          }}
        >
          {render ? render({ mode }) : children}
        </div>
      </div>
    );
  }
);

export default DropdownPanel;
