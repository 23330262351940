import HeaderContainer from "components/common/Containers/HeaderContainer";
import { useAcademyTabsContext } from "components/academy/hook/useAcademyTabsContext";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "styles/confirmAlertStyles.scss"; // Import css
import MerchantDescription from "./Registry/MerchantDescription";
import MerchantData from "./MerchantData";
import { Dispatch, SetStateAction } from "react";
import { ISellerDetail } from "types/seller.interface";
import MerchantConditions from "./Registry/MerchantConditions";
import { boolean } from "yup";

export interface IMerchantRegistryDispatcherProps {
  setMerchantRegistrationDisplay: Dispatch<SetStateAction<boolean>>;
  isSellerActivated?: boolean;
}

interface IMerchntProps extends IMerchantRegistryDispatcherProps {
  seller?: ISellerDetail;
  isSellerActivated?: boolean;
}

const Merchant = ({
  setMerchantRegistrationDisplay,
  seller,
  isSellerActivated,
}: IMerchntProps) => {
  const { data: academy, refresh } = useAcademyTabsContext();

  const contactMail = "support@academiq.pl";
  const mailtoHref = `mailto:${contactMail}?subject=${academy.Name} - Zminana danych Merchant.`;

  const showWarningPopup = () => {
    confirmAlert({
      title: "Edycja danych Merchant",
      message:
        "Zmiana danych sprzedawcy jest rozpatrywana indywidualnie przez platformę Przelewy24. Moze się ona wiązać z ponowną rejestracją konta sprzedawcy oraz utratą funkcjonalności płatności przez 48H. Aby zmienić dane nalezy skontaktować się z obsługą klienta AcademiQ: ",
      buttons: [
        {
          label: "Wyślij wiadomość",
          onClick: () => {
            window.location.href = mailtoHref;
          },
        },
        {
          label: "Rozumiem",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      childrenElement: () => (
        <a href={mailtoHref} className="underline">
          {contactMail}.
        </a>
      ),
    });
  };

  return (
    <HeaderContainer
      title="Informacje Sprzedawca"
      headerContent={
        <Button
          variant={ButtonVariant.Edit}
          onClick={showWarningPopup}
          className="m-2"
        >
          Edytuj
        </Button>
      }
    >
      <div className="flex flex-col w-full 2xl:flex-row gap-4 pt-12">
        <MerchantData />
        <div className="flex w-full 2xl:w-96 gap-4">
          <div className="flex flex-col w-full gap-4 lg:flex-row xl:justify-between xl:flex-row 2xl:flex-col 2xl:justify-start">
            <div className="w-full lg:w-1/2 xl:w-1/3 2xl:w-96">
              <MerchantConditions seller={seller} />
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/3 2xl:w-96">
              <MerchantDescription
                setMerchantRegistrationDisplay={setMerchantRegistrationDisplay}
                isSellerActivated={isSellerActivated}
              />
            </div>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Merchant;
