import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: rgba(156, 176, 196, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(156, 176, 196, 0.3);
`;

const CancelButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.385"
        height="18.385"
        viewBox="1146 447.5 18.385 18.385"
      >
        <path
          d="m1159.132 461.945-3.94-3.94-3.94 3.94-1.312-1.313 3.94-3.94-3.94-3.94 1.313-1.312 3.94 3.94 3.939-3.94 1.313 1.313-3.94 3.94 3.94 3.94-1.313 1.312Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Icon material-add"
        />
      </svg>
    </Button>
  );
};

export default CancelButton;
