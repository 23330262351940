import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  GridItem,
  StyledTableCurrencyInput,
  StyledTableInput,
  StyledTableSelect,
} from 'styles/styles';
import { enumToSelectOptions, getGrossPrice } from 'utils/baseUtils';
import {
  IPassForm,
  PASS_PAYMENT_TYPE,
  PASS_PRICE_TYPE,
  PASS_TYPE,
} from 'types/transfer/pass.interface';
import {
  getFreePassFromPassForm,
  getPaidPassFromPassForm,
  getPassFormInitialValues,
} from 'middleware/pass/getFreePassFromPaid';
import { useDateInputRange } from 'hooks/useDateInputRange';
import passService from 'services/transfer/passServices';
import { toast } from 'react-toastify';
import { showServerErrors } from 'utils/errorsUtils';
import { paymentTypeDisabled, setPassType, setPriceType } from '../utils';

interface IAcademyPassesAddFormProps {
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  refreshPasses: () => Promise<void>;
}

const passTypeOptions = enumToSelectOptions(PASS_TYPE);

const AcademyPassesAddForm = ({
  displayAddForm,
  refreshPasses,
}: IAcademyPassesAddFormProps) => {
  const [pass, setPass] = useState<IPassForm>(getPassFormInitialValues);

  const isSpecifiedPassType = pass.Type === PASS_TYPE['Określony'];

  const { end, start, resetDates, isStartAfterOrEqual } = useDateInputRange({});

  useEffect(() => {
    setPass((prev: any) => ({
      ...prev,
      Period: { StartAt: new Date(start.value), EndAt: new Date(end.value) },
    }));
  }, [end.value, start.value]);

  const submitForm = async () => {
    try {
      if (!pass.Name.length) {
        return toast.error('Wpisz nazwę karnetu');
      }
      if (
        pass.Type === PASS_TYPE['Określony'] &&
        (isStartAfterOrEqual || !pass.Period.EndAt || !pass.Period.StartAt)
      ) {
        return toast.error('Podaj prawidłowy przedział dat');
      }

      const isCreated = pass.Netto === 0
        ? await createFreePass()
        : await createPaidPass();

      if (isCreated) {
        displayAddForm(false);
        await refreshPasses();
        console.log('Created pass:', pass);
      }
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const createFreePass = async () => {
    await passService.createFree(getFreePassFromPassForm(pass));

    toast.success(`Stworzono darmowy karnet ${pass.Name}`);

    return true;
  };

  const createPaidPass = async () => {
    if (pass.Netto < 0) {
      toast.error('Podaj rzeczywistą kwotę karnetu');
      return;
    }

    if (pass.Netto === 0) {
      return await createFreePass();
    }

    await passService.createPaid(getPaidPassFromPassForm(pass));

    toast.success(`Stworzono płatny karnet ${pass.Name}`);

    setPass(prev => ({ ...prev, Netto: 0, NettoString: '', Tax: 0 }));

    return true;
  };

  return (
    <>
      <GridItem>
        <StyledTableInput
          type='text'
          value={pass.Name}
          onChange={e => setPass(prev => ({ ...prev, Name: e.target.value }))}
        />
      </GridItem>

      <GridItem>
        <StyledTableSelect
          value={pass.Type}
          onChange={e => setPassType(e, pass, setPass, resetDates)}>
          {passTypeOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledTableSelect>
      </GridItem>

      <GridItem>
        <StyledTableInput
          type='date'
          disabled={!isSpecifiedPassType}
          min={start.min}
          max={start.max}
          value={start.value}
          onChange={e => start.onChange(e.target.value)}
        />
      </GridItem>

      <GridItem>
        <StyledTableInput
          type='date'
          min={end.min}
          max={end.max}
          value={end.value}
          onChange={e => end.onChange(e.target.value)}
          disabled={!isSpecifiedPassType}
        />
      </GridItem>

      <GridItem>
        <StyledTableSelect
          value={pass.PaymentType}
          onChange={e =>
            setPass(prev => ({
              ...prev,
              PaymentType: parseInt(e.target.value),
            }))
          }>
          <option
            value={
              pass.Type === PASS_TYPE['Nieokreślony']
                ? PASS_PAYMENT_TYPE['Miesięcznie']
                : PASS_PAYMENT_TYPE['Ratalnie']
            }
            disabled={paymentTypeDisabled(
              pass,
              pass.Type === PASS_TYPE['Nieokreślony']
                ? PASS_PAYMENT_TYPE['Miesięcznie']
                : PASS_PAYMENT_TYPE['Ratalnie']
            )}>
            Miesięczne
          </option>
          <option
            value={PASS_PAYMENT_TYPE['Całość']}
            disabled={paymentTypeDisabled(pass, PASS_PAYMENT_TYPE['Całość'])}>
            Całość
          </option>
        </StyledTableSelect>
      </GridItem>

      <GridItem>
        <StyledTableCurrencyInput
          suffix=' zł'
          placeholder='0 zł'
          value={pass.NettoString}
          decimalSeparator='.'
          onValueChange={(value, name, values) =>
            setPass(prev => ({
              ...prev,
              Netto: values?.float ?? 0,
              NettoString: value ?? '',
            }))
          }
        />
      </GridItem>

      <GridItem>
        <StyledTableSelect
          value={pass.Tax}
          onChange={e =>
            setPass(prev => ({ ...prev, Tax: parseInt(e.target.value) }))
          }>
          <option value={0}>0%</option>
          <option value={8}>8%</option>
          <option value={23}>23%</option>
        </StyledTableSelect>
      </GridItem>

      <GridItem>
        <div >
          {`${getGrossPrice(pass.Netto, pass.Tax)} zł`}
        </div>
      </GridItem>

      <GridItem>
        <Button
          onClick={submitForm}
          variant={ButtonVariant.Add}
          // disabled={!name.length || !type.length || (!isFree && netto < 0)}
          className='w-full h-full p-0'>
          Dodaj
        </Button>
      </GridItem>
    </>
  );
};

export default AcademyPassesAddForm;
