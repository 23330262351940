import React, { ChangeEvent, useEffect, useState } from "react";
import { StyledInput, StyledSelect } from "../../styles";
import { GridColumn, StyledTableCurrencyInput } from "styles/styles";
import { IDiscountTemplate } from "types/marketpleace/discount.interface";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { showServerErrors } from "utils/errorsUtils";
import { toast } from "react-toastify";
import discountServices from "services/marketpleace/discountServices";
import { useDateInputRange } from "hooks/useDateInputRange";
import HeaderContainer from "components/common/Containers/HeaderContainer";
import ColumnTableRow from "components/common/FormTable/ColumnTableRow";
import CancelButton from "components/common/buttons/basicButton/CancelButton";
import AddButton from "components/common/buttons/basicButton/AddButton";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";

interface IApplyDiscountProps {
  discountTemplates: IDiscountTemplate[];
  refreshDiscountTemplates: () => Promise<void>;
}

const DiscountTemplate = ({
  discountTemplates,
  refreshDiscountTemplates,
}: IApplyDiscountProps) => {
  const [discount, setDiscount] = useState<IDiscountTemplate | null>(null);

  const { start, end, isStartAfterOrEqual, resetDates } = useDateInputRange({
    minDate: new Date(),
    time: true,
  });

  const [addMode, setAddMode] = useState(false);

  const [name, setName] = useState("");
  const [percent, setPercent] = useState(0);

  const handleCombinationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDiscount(
      discountTemplates.find((d) => d.DiscountTemplateId === e.target.value) ??
        null
    );
  };
  console.log(start.value);

  const resetTemplateForm = () => {
    setName("");
    setPercent(0);
    resetDates();
  };

  const addDiscountTemplate = async () => {
    try {
      if (!name.length) {
        return toast.error("Uzupełnij nazwę");
      }

      if (percent <= 0 || percent > 100) {
        return toast.error("Wpisz poprawną wartość %");
      }

      if (isStartAfterOrEqual) {
        return toast.error("Daty nie mogą być równe");
      }

      await discountServices.save({
        Name: name,
        Percent: percent,
        Start: new Date(start.value),
        End: new Date(end.value),
      });

      toast.success(`Pomyślnie dodano ${name}`);

      await refreshDiscountTemplates();

      setAddMode(false);
      resetTemplateForm();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const removeDiscountTemplate = async (id: string, name: string) => {
    try {
      await discountServices.remove(id);

      toast.success(`Pomyślnie usunięto ${name}`);

      await refreshDiscountTemplates();

      setDiscount(null);
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <HeaderContainer
      title="szablony"
      headerContent={
        <div className="flex h-full p-4 gap-1">
          {!!discount && !addMode && (
            <DeleteButton
              variant={ButtonVariant.Delete}
              onClick={() =>
                removeDiscountTemplate(
                  discount.DiscountTemplateId,
                  discount.Name
                )
              }
              className="flex h-full py-0 px-12 text-xs"
            />
          )}
          {addMode ? (
            <CancelButton
              variant={addMode ? ButtonVariant.Cancel : ButtonVariant.Add}
              onClick={() => {
                setAddMode((prev) => !prev);
                addMode && resetTemplateForm();
              }}
              className="flex h-full py-0 px-12 text-xs"
            />
          ) : (
            <AddButton
              variant={addMode ? ButtonVariant.Cancel : ButtonVariant.Add}
              onClick={() => {
                setAddMode((prev) => !prev);
                addMode && resetTemplateForm();
              }}
              className="flex h-full py-0 px-12 text-xs"
            />
          )}
        </div>
      }
    >
      <GridColumn cols={2}>
        {!addMode && (
          <ColumnTableRow header="szablon promocyjny">
            <StyledSelect
              value={discount?.DiscountTemplateId ?? ""}
              onChange={handleCombinationChange}
            >
              <option disabled value="">
                Wybierz szablon
              </option>
              {discountTemplates.map((discount) => (
                <option value={discount.DiscountTemplateId}>
                  {discount.Name}
                </option>
              ))}
            </StyledSelect>
          </ColumnTableRow>
        )}
        {!!discount && !addMode && (
          <>
            <ColumnTableRow header="Nazwa">{discount.Name}</ColumnTableRow>
            <ColumnTableRow header="Przecena">
              {`${discount.Percent} %`}
            </ColumnTableRow>
            <ColumnTableRow header="Start">
              <div className="flex gap-2">
                <div>
                  {new Date(discount.Start).toLocaleTimeString().slice(0, 5)}
                </div>
                <div>{new Date(discount.Start).toLocaleDateString()}</div>
              </div>
            </ColumnTableRow>
            <ColumnTableRow header="Koniec">
              <div className="flex gap-2">
                <div>
                  {new Date(discount.End).toLocaleTimeString().slice(0, 5)}
                </div>
                <div>{new Date(discount.End).toLocaleDateString()}</div>
              </div>
            </ColumnTableRow>
          </>
        )}
        {addMode && (
          <>
            <ColumnTableRow header="Nazwa">
              <StyledInput
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </ColumnTableRow>
            <ColumnTableRow header="Przecena [ % ]">
              <StyledTableCurrencyInput
                suffix=" %"
                placeholder="0 %"
                max={100}
                onValueChange={(value, name, values) => {
                  setPercent(values?.float ?? 0);
                }}
              />
            </ColumnTableRow>
            <ColumnTableRow header="Start">
              <StyledInput
                type="datetime-local"
                min={start.min}
                max={start.max}
                value={start.value}
                onChange={(e) => start.onChange(e.target.value)}
              />
            </ColumnTableRow>
            <ColumnTableRow header="Koniec">
              <StyledInput
                type="datetime-local"
                min={end.min}
                max={end.max}
                value={end.value}
                onChange={(e) => end.onChange(e.target.value)}
              />
            </ColumnTableRow>
            <ColumnTableRow header="Operacja">
              <Button
                onClick={addDiscountTemplate}
                variant={ButtonVariant.Add}
                disabled={
                  !name.length ||
                  percent <= 0 ||
                  percent > 100 ||
                  isStartAfterOrEqual
                }
                style={{ height: "100%", width: "100%" }}
              >
                Dodaj
              </Button>
            </ColumnTableRow>
          </>
        )}
      </GridColumn>
    </HeaderContainer>
  );
};

export default DiscountTemplate;
