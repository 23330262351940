import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import classNames from "classnames";

import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";

const Container = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
`;

const DropdownContainer = styled.div`
  text-align: right;
  width: 280px;
  position: relative;
  font-size: 15px;
`;

const FormControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;
  padding: 12px 18px;
  padding-right: 35px;
  background: transparent;
  color: white;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  background: rgba(163, 158, 157, 0.11);
`;

const SelectValue = styled.div`
  padding-right: 10px;
`;

interface IconContainerProps {
  isOpen: boolean;
}

const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.1s;
    transform: rotate(-90deg);
    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: rotate(90deg);
      `}
  }
`;

const DropdownOptions = styled.div`
  background: rgb(2, 2, 15);
  color: white;
  border: 1px solid rgba(144, 144, 144, 0.5);
  margin-top: 1px;
  position: absolute;
  z-index: 40;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow-y: auto;
`;

const Option = styled.div`
  padding: 8px 18px;
  font-size: 12px;
  &:hover {
    background: darkblue;
    color: white;
  }
`;

interface Props {
  dropdownOptions: string[];
  selectItem: (value: string) => void;
  isLoading: boolean;
}

const StatusSelector: React.FC<Props> = ({
  dropdownOptions,
  selectItem,
  isLoading,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("Wybierz");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const selectStatus = (status: string) => {
    setSelectedStatus(status);
    setIsButtonDisabled(status === "Wybierz");
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const sendTrainerInvitation = () => {
    selectItem(selectedStatus);
    setSelectedStatus("Wybierz");
    setIsButtonDisabled(true);
  };

  return (
    <Container>
      <DropdownContainer>
        <FormControl onClick={toggleDropdown}>
          <div>
            <label
              htmlFor="state"
              style={{
                position: "absolute",
                top: "7px",
                left: "9px",
                fontSize: "11px",
                color: "rgb(163, 158, 157)",
              }}
            >
              Status
            </label>
          </div>
          <SelectValue>{selectedStatus}</SelectValue>
          <IconContainer isOpen={isDropdownOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="28"
              height="28"
              viewBox="0 0 20 24"
              fill="rgb(163, 158, 157)"
            >
              <div>
                <filter
                  id="Path_2419"
                  x="0"
                  y="0"
                  width="19.763"
                  height="28"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="2" in="SourceAlpha"></feOffset>
                  <feGaussianBlur
                    stdDeviation="2"
                    result="blur"
                  ></feGaussianBlur>
                  <feFlood floodColor="#00003d" floodOpacity="0.478"></feFlood>
                  <feComposite operator="in" in2="blur"></feComposite>
                  <feComposite in="SourceGraphic"></feComposite>
                </filter>
              </div>
              <g>
                <path
                  id="Path_2419-2"
                  data-name="Path 2419"
                  d="M454.974,1339.208a1.06,1.06,0,0,1-.822-.391l-5.647-6.939a1.061,1.061,0,0,1,0-1.337l5.647-6.944a1.059,1.059,0,0,1,1.642,1.337l-5.1,6.276,5.1,6.271a1.058,1.058,0,0,1-.82,1.727Z"
                  transform="translate(-442.27 -1319.21)"
                ></path>
              </g>
            </svg>
          </IconContainer>
        </FormControl>
        {isDropdownOpen && (
          <DropdownOptions>
            {dropdownOptions.map((option, index) => (
              <Option key={index} onClick={() => selectStatus(option)}>
                {option}
              </Option>
            ))}
          </DropdownOptions>
        )}
      </DropdownContainer>
      <Button
        className={classNames("button button--submit", {
          "button--disabled": isButtonDisabled,
        })}
        style={{ alignSelf: "center" }}
        variant={ButtonVariant.Save}
        disabled={isButtonDisabled}
        onClick={sendTrainerInvitation}
      >
        {isLoading ? "Zmieniam..." : "Zapisz"}
      </Button>
    </Container>
  );
};

export default StatusSelector;
