import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import PermissionSection from "./PermissionSection";
import { ICheckboxValue } from "../../../types/globalTypes";
import {
  IPermissions,
  IPermissionTransformDTO,
} from "../../../types/permissionsTypes";
import { useDispatch } from "react-redux";
import permissionsServices from "services/permissionsServices";
import { Permission, UserInterface } from "types/userTypes";
import NotificationSwitchBlock from "components/common/inputs/switch/NotificationSwitchBlock";
import { permissions } from "store/reducers/Permissions";


interface Props {
  user: UserInterface;
}

interface ISwitchActive {
  flag?: number | string;
  disabled?: boolean;
}

const EditNotifications: React.FC<Props> = ({ user }) => {
  const [initState, setInitState] =
    useState<IPermissions<ICheckboxValue<number>> | null>(null);
  const [changed, setChanged] = useState<IPermissionTransformDTO>({});

  const [selectedItem, setSelectedItem] =
    useState<{ value: number; label: string } | null>(null);
  const [profiles, setProfiles] = useState([]);
  const [domainNames, setDomainNames] = useState([]);

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  // const handleChange = (
  //   domain: keyof IPermissions,
  //   action: keyof IPermissionsActionTypes,
  //   value: ICheckboxValue<number>,
  // ) => {
  //   const key = `${domain}_${action}`;
  //   if (!changed[key]) {
  //     setChanged((prev) => ({
  //       ...prev,
  //       [key]: {
  //         permissionDomainName: PermissionsDomainsShort[domain],
  //         permissionFlagName: action,
  //         permissionFlagValue: value.value,
  //         value,
  //       },
  //     }));
  //   } else {
  //     setChanged((prev) => {
  //       const { [key]: deleted, ...rest } = prev;
  //       return rest;
  //     });
  //   }
  // };

  // const handleSubmit = async (values: typeof initValues, bag: any) => {
  //   try {
  //     if (values) {
  //       dispatch(changePermissions(changed, id));
  //       setChanged({});
  //       toast.success(`Zmieniono uprawnienia`);
  //     }
  //   } catch (errors: any) {
  //     toast.error("Błąd: " + errors.join(" | "));
  //   }
  // };

  const getAllProfiles = async () => {
    try {
      const resp: any = await permissionsServices.getAllProfiles();

      const profilesArray: any = [];

      const convertedObiect = Object.values(resp);

      convertedObiect.map((single, index) => {
        return profilesArray.push({
          label: single,
          value: index,
        });
      });
      setProfiles(profilesArray);
    } catch (error) {
      console.log(error);
    }
  };

/*   useEffect(() => {
    getAllProfiles();
  }, []); */

  useEffect(() => {
    const domainNamesArray: any = [];
    user.Permissions.forEach((Permission: Permission) => {
      if (
        domainNamesArray.filter(
          (el: any) => Permission.PermissionDomainName === el,
        ).length > 0
      ) {
        return;
      } else {
        domainNamesArray.push(Permission.PermissionDomainName);
      }
    });

    // @ts-ignore
    // setDomainNames(domainNamesArray);

  }, []);

  const initValues = {
    Auth: {
      CreateUser: 1,
      EditUser: 1,
      DeleteUser: 1,
      ViewManagementList: 1,
      ViewContactList: 1,
      ChangeOwnPassword: 1,
      ManagePermissions: 1,
      ChangeRole: 1,
      ChangePassword: 1,
      RevokePermission: 1,
      GrantPermission: 1,
    },
  };

  const sectionPermissonsElems = {
    Data: [
      {
        header: "Moja Akademia",
        permissions: [
          { NotificationName: "Wyświetl zakładkę akademii ", PermissionAccess: 130 },
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Dostęp do detalu akademii", PermissionAccess: 229 },
            permissions: [
              { NotificationName: "Wyświetl detal podstawowych danych Akademii", PermissionAccess: 123 },
              { NotificationName: "Zaktualizuj podstawowe dane Akademii", PermissionAccess: 118 },
              { NotificationName: "Wyświetl detal Licencji Akademii", PermissionAccess: 124 },
              { NotificationName: "Wyświetl detal sprzedawcy", PermissionAccess: 125 },
              { NotificationName: "Możliwość aktywacji sprzedawcy", PermissionAccess: 20 },
              { NotificationName: "Wyświetl składki Akademii", PermissionAccess: 126 },
              { NotificationName: "Zmiena ceny składki Akademii", PermissionAccess: 13 },
              { NotificationName: "Wyświetl ulubione lokalizacje", PermissionAccess: 127 },
              { NotificationName: "Edytuj ulubione lokalizacje", PermissionAccess: 129 },
              { NotificationName: "Edytuj składkę", PermissionAccess: 12},
            ]
          },
        ]
      },
      {
        header: 'Sekcja Zajęcia',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Zajęcia", PermissionAccess: 131 },
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Wyświetl szczegóły zajęcia", PermissionAccess: 132 },
            permissions: [
              // { NotificationName: "Dostęp do listingu zajęć cyklicznych", PermissionAccess: 209 },
              { NotificationName: "Dostęp to tworzenia zajęcia", PermissionAccess: 172 },
              { NotificationName: "Dostęp do aktualizacji zajęcia", PermissionAccess: 208 },
              { NotificationName: "Dostęp do usunięcia zajęcia", PermissionAccess: 175 },
              { NotificationName: "Dostęp do usunięcia postu", PermissionAccess: 176 },
              { NotificationName: "Dostęp do edycji zajęcia", PermissionAccess: 177 },
              { NotificationName: "Utwórz cykliczne zajęcia", PermissionAccess: 210 },
              // { NotificationName: "Aktualizuj cyklicznego zajęcia", PermissionAccess: 174 },
              { NotificationName: "Przejście do detalu cyklicznego", PermissionAccess: 225 },
              { NotificationName: "Dostęp do detalu uczestnicy", PermissionAccess: 142 },
              { NotificationName: "Dostęp do detalu adres", PermissionAccess: 143 },
              { NotificationName: "Dostęp do detalu Post", PermissionAccess: 144 },
              { NotificationName: "Dostęp do detalu uwagi", PermissionAccess: 145 },


              // { NotificationName: "Dostęp do listingu darfty", PermissionAccess: 173 },
            ]
          },
        ]
      },
      {
        header: 'Sekcja Zawodników',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Zawodnicy", PermissionAccess: 133 }
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Dostęp do detalu zawodnika", PermissionAccess: 134 },
            permissions: [
              { NotificationName: "Wyświetl adres zawodnika", PermissionAccess: 147 },
              { NotificationName: "Wyświetl zadania zawodnika", PermissionAccess: 146 },
              { NotificationName: "Wyświetl kartę zawodnika", PermissionAccess: 148 },
              { NotificationName: "Wyświetl trenera zawodnika", PermissionAccess: 149 },
              { NotificationName: "Wyświetl karierę zawodnika", PermissionAccess: 150 },
              { NotificationName: "Wyświetl statystyki zawodnika", PermissionAccess: 151 },
              { NotificationName: "Wyświetl składki zawodnika", PermissionAccess: 152 },
              {
                NotificationName: "Wyświetl historię statusów zawodnika", PermissionAccess: 153
              },
              {
                NotificationName: "Zmień status zawodnika", PermissionAccess: 181
              },
              {
                NotificationName: "Dodaj składkę do zawodnika", PermissionAccess: 179
              },
              {
                NotificationName: "Edytuj składkę zawodnika", PermissionAccess: 180
              },
            ]
          }
        ]
      },
      {
        header: 'Radar Zawodników',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Radar Zawodników", PermissionAccess: 135 },
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Wyświetl detal zawodnika z pozycji radaru", PermissionAccess: 205 },
            permissions: [
              { NotificationName: "Wyświetl filtrowanie zawodnika", PermissionAccess: 154 }
            ]
          }
        ],
      },
      {
        header: 'Sekcja Trenerów',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Trenerzy", PermissionAccess: 136 },
        ],
        subsections: [
          {
            mainPermission: {
              NotificationName: "Wyświetl detal trenera", PermissionAccess: 137
            },
            permissions: [
              { NotificationName: "Wyświetl biografię trenera", PermissionAccess: 155 },
              { NotificationName: "Wyświetl adres trenera", PermissionAccess: 156 },
              { NotificationName: "Wyświetl płatności trenera", PermissionAccess: 157 },
              { NotificationName: "Wyświetl obserwatorów trenera", PermissionAccess: 158 },
              { NotificationName: "Wyświetl historę statusów trenera", PermissionAccess: 178 },
              { NotificationName: "Wyświetl filtrowanie listy trenerów", PermissionAccess: 228 },
            ]
          }
        ]
      },
      {
        header: 'Radar Trenerów',
        permissions: [
          { NotificationName: "Wyświetl zakłądkę  Radar Trenerów", PermissionAccess: 138 },
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Wyświetl detal trenera z pozycji radaru", PermissionAccess: 182 },
            permissions: [
              { NotificationName: "Wyświetl filtrowanie trenerów", PermissionAccess: 159 }
            ]
          },
        ]
      },
      {
        header: 'Sekcja Grupy',
        permissions: [
          { NotificationName: "Wyświetl zakładkę grup", PermissionAccess: 139 },
        ],
        subsections: [
          {
            mainPermission: { NotificationName: "Wyświetl detal zawodnika", PermissionAccess: 223 },
            permissions: [
              { NotificationName: "Wyświetl w grupach zajęcia zawodnika", PermissionAccess: 183 },
              { NotificationName: "Wyświetl w grupach adres zawodnika", PermissionAccess: 184 },
              { NotificationName: "Wyświetl w grupach kartę zawodnika", PermissionAccess: 185 },
              { NotificationName: "Wyświetl w detalu trenera zawodnika", PermissionAccess: 186 },
              { NotificationName: "Wyświetl karierę zawodnika w grupach", PermissionAccess: 187 },
              { NotificationName: "Wyświetl statystyk zawodnika", PermissionAccess: 188 },
              { NotificationName: "Wyświetl składki zawodnika w grupach", PermissionAccess: 189 },
              { NotificationName: "Wyświetl  historia statusów zawodnika", PermissionAccess: 190 },
              { NotificationName: "Wyświetl przycisk chatu zawodnika", PermissionAccess: 191 },
            ]
          },
          {
            mainPermission: { NotificationName: "Wyświetl w grupach detal trenerów", PermissionAccess: 224 },
            permissions: [
              { NotificationName: "Wyświetl bio trenerów", PermissionAccess: 192 },
              { NotificationName: "Wyświetla adres trenera", PermissionAccess: 193 },
              { NotificationName: "Wyświetl w grupach płatności trenera", PermissionAccess: 194 },
              { NotificationName: "Wyświetl w grupach obserwatorów trenera", PermissionAccess: 195 },
              { NotificationName: "Wyświetl historię statusów trenera", PermissionAccess: 196 },
            ]
          },
          {
            mainPermission: { NotificationName: "Dostęp do detalu folderu", PermissionAccess: 56 },
            permissions: [
              { NotificationName: "Wyświetl w folderze statystyki", PermissionAccess: 197 },
              { NotificationName: "Wyświetl w folderze zajęci", PermissionAccess: 198 },
              { NotificationName: "Wyświetl detal zajęć w  zakładce zajęcia", PermissionAccess: 201 },
              { NotificationName: "Wyświetl w folderze trener", PermissionAccess: 199 },
              { NotificationName: "Wyświetl w folderze składki", PermissionAccess: 200 },
              { NotificationName: "Dodaj trenera do grupy", PermissionAccess: 202 },
              { NotificationName: "Edycja trenera w grupie", PermissionAccess: 203 },
              { NotificationName: "Dostęp do detalu składek zawodnika", PermissionAccess: 204 },
            ]
          }
        ]
      },
      {
        header: 'Sekcja Sklep',
        permissions: [
          { NotificationName: "Wyświetl zakładkę sklep", PermissionAccess: 140 },
        ],
        subsections: [
          {
            mainPermission:
            { NotificationName: "Udostępnij zamównienia", PermissionAccess: 160 },
            permissions: [
              { NotificationName: "Detal zamówienia", PermissionAccess: 239 },
              { NotificationName: "Wyświetl zakładkę produkty zamówienia", PermissionAccess: 166 },
              { NotificationName: "Wyświetl zakładkę dostawa zamówienia", PermissionAccess: 167 },
              { NotificationName: "Wyświetl zakładkę dokumenty zamówienia", PermissionAccess: 168 },
              { NotificationName: "Dodaj dokumenty zamówienia", PermissionAccess: 21 },
              { NotificationName: "Usuń dokumenty zamówienia", PermissionAccess: 22 },
              { NotificationName: "Wyświetl zakładkę historia zamówienia", PermissionAccess: 169 },
              { NotificationName: "Wyświetl zakładkę uwagi zamówienia", PermissionAccess: 170 },
              { NotificationName: "Wyświetl zakładkę zwroty zamówienia", PermissionAccess: 171 },
            ]
          },

          {  mainPermission: { NotificationName: "Wyświetl zakładkę składki", PermissionAccess: 161 },
            permissions: [
              { NotificationName: "Dostęp do detalu zawodnika z poziomu składki", PermissionAccess: 216 },
            ]
          },

          {
            mainPermission: { NotificationName: "Wyświetl produkty sklepowe", PermissionAccess: 162 },
            permissions: [
              { NotificationName: "Wyświetl detal produktu", PermissionAccess: 46 },
              { NotificationName: "Wyświetl zakładkę Dane Uzupełniające", PermissionAccess: 217 },
              { NotificationName: "Wyświetl atrybuty produktu", PermissionAccess: 218 },
              { NotificationName: "Wyświetl warunki produktu", PermissionAccess: 219 },
              { NotificationName: "Wyświetl raport sprzedaźowy produktu", PermissionAccess: 220 },
              { NotificationName: "Wyświetl dokumenty produktu", PermissionAccess: 221 },
              { NotificationName: "Wyświetl możliwość edycji produktu", PermissionAccess: 30 },
              { NotificationName: "Wyświetl możliwość usunięcia produktu", PermissionAccess: 29 },
              { NotificationName: "Utwórz elektroniczną dostawę", PermissionAccess: 26 },
              { NotificationName: "Utwórz fizyczną dostawę", PermissionAccess: 25 },
              { NotificationName: "Edycja dostawy", PermissionAccess: 23 },
            ]
          },

            { mainPermission: { NotificationName: "Wyświetl kategorie sklepowe", PermissionAccess: 163 },
            permissions: [],
            },

            {
            mainPermission: { NotificationName: "Wyświetl sklepowe atrybuty", PermissionAccess: 164 },
            permissions: [],
          },
          {
            mainPermission: { NotificationName: "Wyświetl sklepowe dostawy", PermissionAccess: 165 },
            permissions: [],
          }
        ]
      },
      {
        header: 'Sekcja kontakty',
        permissions: [
          { NotificationName: "Wyświetl zakładkę kontakt", PermissionAccess: 211 }
        ],
        subsections: []
      },
      {
        header: 'Sekcja Pliki Wewnętrzne',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Pliki Wewnętrzne", PermissionAccess: 213 }
        ],
        subsections: []
      },
      {
        header: 'Sekcja Chat',
        permissions: [
          { NotificationName: "Wyświetl zakładkę Czat", PermissionAccess: 215 }
        ],
        subsections: []
      },
    ]
  };

  const [isChecked, setIsChecked] = useState<ISwitchActive[]>([]);
  const [subMenu, setSubmenu] = useState<ISwitchActive[]>([]);

  const applyStyle = (elem: any) => {
    setIsChecked((prev: any) =>
      prev.map((e: any) =>
        e.flag === elem.flagValue
          ? { ...e, disabled: elem.disabled }
          : e
      )
    );

    setSubmenu((prev: any) =>
      prev.map((e: any) =>
        e.flag === elem.flagValue
          ? { ...e, disabled: elem.disabled }
          : e
      )
    );
  };

  useEffect(() => {

    if (user?.Permissions) {
      const newPermissions = sectionPermissonsElems.Data.flatMap((e) => {
        if (!e.permissions) return [];
        return e.permissions.map(permission => ({
          flag: permission.PermissionAccess,
          disabled: user?.Permissions?.some((perm: any) => perm?.Id === permission.PermissionAccess),
        }));
      });

      const checkMainMenuDuplication = user?.Permissions.filter((permission, i) => {
        const flagAsString = String(isChecked[i]?.flag);
        return flagAsString.includes(String(permission?.Id));
      });

      setIsChecked((prev: ISwitchActive[]) => [
        ...prev,
        ...checkMainMenuDuplication.map((permission) => ({
          flag: permission?.Id,
          disabled: false,
        })),
        ...newPermissions.map((perm) => ({
          flag: perm.flag,
          disabled: perm.disabled,
        })),
      ]);

      const checkSubmenuDuplicate = user?.Permissions.filter((permission) =>
        subMenu.some((sub) => sub?.flag === permission.Id)
      );

      const subMenuPermissions = sectionPermissonsElems.Data.flatMap((section) =>
        section.subsections?.map((subsection) => ({
          flag: subsection.mainPermission.PermissionAccess,
          disabled: user?.Permissions?.some((perm: any) => perm?.Id === subsection.mainPermission.PermissionAccess),
        })) || []
      );

      setSubmenu((prev: ISwitchActive[]) => [
        ...prev,
        ...checkSubmenuDuplicate.map((permission: Permission) => ({
          flag: permission?.Id,
          disabled: false,
        })),
        ...subMenuPermissions.map((perm: ISwitchActive) => ({
          flag: perm.flag,
          disabled: perm.disabled,
        })),
      ]);
    }

  }, [user]);

  return (
    <>
      {user?.Position !== 'Admin' && (
        <>
          <header className="p-8 text-lg text-white-dirty">
            <h2>Dostępy</h2>
          </header>
          {sectionPermissonsElems.Data.map((section, sectionIdx) => (
            <div
              key={sectionIdx}
            >
              <PermissionSection label={section.header} initialExpanded>
                <ul
                  className="w-1/4 mb-18 mt-8"
                  style={{
                    background: 'rgb(75 85 99)',
                    padding: '13px 20px 0.1px 20px',
                    borderRadius: '2px',
                  }}
                >
                  {section.permissions.map((permission, permIdx) => (
                    <li key={permIdx}>
                      <NotificationSwitchBlock
                        domainName={permission.NotificationName}
                        flagName={permission.NotificationName}
                        flagValue={permission.PermissionAccess}
                        name={permission.NotificationName}
                        label={permission.NotificationName}
                        applyStyle={applyStyle}
                        checked={user?.Permissions?.some(
                          (perm: any) => perm.Id === permission.PermissionAccess
                        )}
                        style={{ opacity: 1 }}
                      />
                    </li>
                  ))}
                </ul>
                <div
                //  className="grid gap-x-14 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1"
                >
                  {section.subsections.map((subsection, subIdx) => {
                    const mainMenuOpacityStyle = isChecked.map(e => e.disabled ? '1' : '0.3');
                    const mainMenuEventPointerStyle = isChecked.map(e => e.disabled ? 'auto' : 'none');
                    const isSubmenuActive = subMenu.some(e => e.flag === subsection.mainPermission.PermissionAccess && !!e.disabled);
                    return (
                      <div
                        key={subIdx}
                        style={{
                          opacity: mainMenuOpacityStyle[sectionIdx],
                          pointerEvents: mainMenuEventPointerStyle[sectionIdx],
                          marginTop: '10px'
                        }}
                      >
                        <div
                          className="w-1/3 /* pt-12 pb-12 pl-12 */ "
                          style={{
                            background: 'rgb(75 85 90)',
                            padding: '13px 20px 1px 20px',
                            borderRadius: '3px',
                          }}
                        >
                          <NotificationSwitchBlock
                            domainName={subsection.mainPermission.NotificationName}
                            flagName={subsection.mainPermission.NotificationName}
                            flagValue={subsection.mainPermission.PermissionAccess}
                            name={subsection.mainPermission.NotificationName}
                            label={subsection.mainPermission.NotificationName}
                            applyStyle={applyStyle}
                            checked={user?.Permissions?.some(
                              (perm: any) => perm.Id === subsection.mainPermission.PermissionAccess
                            )}
                            style={{ opacity: 1 }}
                          />
                        </div>
                        <ul
                          style={{
                            opacity: isSubmenuActive ? '1' : '0.3',
                            pointerEvents: isSubmenuActive ? 'auto' : 'none',
                          }}
                          className="grid gap-x-14 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8"
                        >
                          {subsection.permissions.map((subPermission, subPermIdx) => {
                            return (
                              <li key={subPermIdx}>
                                <NotificationSwitchBlock
                                  domainName={subPermission.NotificationName}
                                  flagName={subPermission.NotificationName}
                                  flagValue={subPermission.PermissionAccess}
                                  name={subPermission.NotificationName}
                                  label={subPermission.NotificationName}
                                  applyStyle={applyStyle}
                                  checked={user?.Permissions?.some(
                                    (perm: any) => perm.Id === subPermission.PermissionAccess
                                  )}
                                />
                              </li>
                            )
                          })
                          }
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </PermissionSection>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default EditNotifications;
