import DataTable, {
  IDataTableRows,
} from 'components/common/dataTable/DataTable';
import ContentContainer from 'components/layout/ContentContainer';
import { useEffect, useState } from 'react';
import { categoriesHeaders } from './helpers';
import { ICategory } from 'types/marketpleace/category.interface';
import categoriesServices from 'services/marketpleace/categoryServices';
import CategoriesTopbar from './CategoriesTopbar';
import { useDebounce } from 'use-debounce';

const Categories = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [searchQuery, setSearchQuery] = useState('');

  const [debounceSearchInput] = useDebounce(searchQuery.trim(), 500);

  console.log(debounceSearchInput);

  const getCategories = async (value: string) => {
    const categories = await categoriesServices.get(value);

    setCategories(categories);
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (debounceSearchInput.length >= 3 || !debounceSearchInput.length) {
      setIsDataLoading(true);

      getCategories(debounceSearchInput);
    }
  }, [debounceSearchInput]);

  // useEffect(() => {
  //   getCategories('');
  // }, []);

  const categoriesTableRows: IDataTableRows[] = categories.map(category => ({
    cols: [category.Name, category.Description],
  }));

  return (
    <ContentContainer
      title='Kategorie'
      TopBar={
        <CategoriesTopbar
          handleSearch={e => setSearchQuery(e.target.value)}
          searchQueryValue={searchQuery}
        />
      }>
      <div>
        <DataTable
          rows={categoriesTableRows}
          headers={categoriesHeaders}
          isDataLoading={isDataLoading}
        />
      </div>
    </ContentContainer>
  );
};

export default Categories;
