import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "../common/buttons/basicButton/Button";

import { ButtonVariant } from "../common/buttons/buttonTypes";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { useEffect, useRef, useState } from "react";
import BackButton from "components/common/buttons/basicButton/BackButton";
import styled from "styled-components";

const Test = styled.div`
  & > div {
    width: 100%;
  }
`;

export interface IContentContainerProps {
  TopBar?: JSX.Element;
  title: string;
  path?: string;
  noArrow?: boolean;
  fn?: any;
}

interface RouteParams {
  id: string;
}

const ContentContainer: React.FC<IContentContainerProps> = ({
  title,
  children,
  TopBar,
  path = "/",
  noArrow = false,
  fn,
}) => {
  const { goBack, push } = useHistory();
  const topbarRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<RouteParams>();

  const location = useLocation();
  const pathname = location.pathname;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (pathname !== "/structure") {
      setIsVisible(Boolean(id));
    }
  }, [id, pathname]);

  const changeCssProperty = () => {
    const oldValue =
      document.documentElement.style.getPropertyValue("--topbar-height");

    if (
      topbarRef.current &&
      oldValue !== `${topbarRef.current.clientHeight}px`
    ) {
      document.documentElement.style.setProperty(
        "--topbar-height",
        topbarRef.current.clientHeight + "px"
      );
    }
  };

  useEffect(() => {
    changeCssProperty();
    window.addEventListener("resize", changeCssProperty);

    return () => {
      window.removeEventListener("resize", changeCssProperty);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Helmet>
        <title>Just Win | {title || ""}</title>
      </Helmet>
      <div
        ref={topbarRef}
        className="flex flex-col md:flex-row items-start justify-between gap-x-2 gap-y-6  sticky top-0 z-30"
        style={{
          background: "rgba(0,0,0,0.5)",
        }}
      >
        <div className="flex items-center gap-x-4">
          <div
            style={{
              borderLeft: "5px solid rgba(163, 158, 157, 0.7)",
              height: "48px",
            }}
          ></div>
          {noArrow && fn ? (
            <BackButton
              className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
              variant={ButtonVariant.Submit}
              onClick={() => fn()}
            >
              <i>
                <Arrow className="fill-current w-7" />
              </i>
            </BackButton>
          ) : (
            <BackButton
              className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
              variant={ButtonVariant.Submit}
              onClick={() => goBack()}
            >
              <i>
                <Arrow className="fill-current w-7" />
              </i>
            </BackButton>
          )}
          <h2
            className="text-opacity-80 text-lg w-max capitalize-first"
            style={{
              color: "#F8F8F8",
              fontWeight: 400,
              fontSize: "18px",
              letterSpacing: "0.1px",
            }}
          >
            {title}
          </h2>
        </div>
        {TopBar && <div className="w-full h-full">{TopBar}</div>}
      </div>
      <Test
        style={{
          flexGrow: 1,
          display: "flex",
          width: "100%",
          borderTop: "10px solid rgba(0,0,0,0.5)",
        }}
      >
        {children}
      </Test>
    </div>
  );
};

export default ContentContainer;
