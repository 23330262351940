import React, { useEffect, useState } from "react";
import ContentContainer from "components/layout/ContentContainer";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import Tabs from "components/common/tabs/Tabs";
import TabsView from "components/common/tabs/TabsView";
import TabContent from "components/common/tabs/TabContent";
import SelectArrow from "assets/icons/selectArrow.png";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import passService from "services/transfer/passServices";
import {
  GridColumn,
  GridItem,
  GridTextItem,
  OneLinerText,
} from "styles/styles";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import {
  PASS_PAYMENT_METHOD,
  PASS_TRANSACTION_STATUS,
} from "types/transfer/pass.interface";

interface Props {
  selectedPassId?: string;
  closeModalOpen: any;
}

const PassesDetail: React.FC<Props> = ({ selectedPassId, closeModalOpen }) => {
  const [passDetail, setPassDetail] = useState<any>(null);

  const [tokenUser, setTokenUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const match = useRouteMatch();
  const permission = useSelector((state: any) => state.permissions.permissions);

  const getPassDetail = async () => {
    if (selectedPassId) {
      const response: any = await passService.getSinglePassTranstaction(
        selectedPassId,
      );

      setPassDetail(response?.Items);
      console.log("response", response?.Items[0]);
    }
  };

  useEffect(() => {
    getPassDetail();
  }, [selectedPassId]);

  if (!passDetail) {
    return <InfoBoxPlaceholder />;
  }

  const tabs = [
    {
      tab: {
        id: "invoices",
        label: "Faktury",
      },
      content: (
        <TabContent id="invoices">
          <div
            className="flex gap-2 p-24 w-full"
            style={{ flexDirection: "column" }}
          >
            <div className={`flex flex-col w-full `}>
              <div
                title={"Faktury"}
                className={`flex justify-between items-center border-solid border-l-4 border-orange`}
                style={{
                  backgroundColor: "rgba(2, 2, 15, 0.3)",
                  color: "white",
                  minHeight: "64px",
                }}
              >
                <OneLinerText className="flex pl-18 text-sm uppercase text-center">
                  Faktury
                </OneLinerText>
              </div>
            </div>
            <div className="flex gap-4  w-full">
              <GridColumn cols={2}>
                <GridTextItem uppercase isHeader>
                  Nazwa
                </GridTextItem>
                <GridTextItem uppercase isHeader>
                  Akcja
                </GridTextItem>

                {passDetail?.map((single: any) => {
                  return (
                    <>
                      <GridTextItem>-</GridTextItem>

                      <GridItem>
                        <div className="flex gap-2 w-full h-full justify-center">
                          <Button
                            variant={ButtonVariant.Submit}
                            className=" h-full"
                            style={{
                              pointerEvents: single.InvoiceUrl
                                ? "auto"
                                : "none",
                              opacity: single.InvoiceUrl ? "" : "0.6",
                            }}
                          >
                            <a
                              href={
                                single.InvoiceUrl ? single.InvoiceUrl : null
                              }
                              target="_blank"
                              rel="noreferrer"
                              // download={document.Name}
                              aria-disabled={!single.InvoiceUrl}
                              className="flex items-center justify-center h-full w-full"
                            >
                              Pobierz
                            </a>
                          </Button>
                        </div>
                      </GridItem>
                    </>
                  );
                })}
              </GridColumn>
            </div>
          </div>
        </TabContent>
      ),
    },
    {
      tab: {
        id: "payments",
        label: "Płatności",
      },
      content: (
        <TabContent id="payments">
          <div
            className="flex gap-2 p-24 w-full"
            style={{ flexDirection: "column" }}
          >
            <div className={`flex flex-col w-full `}>
              <div
                title={"Faktury"}
                className={`flex justify-between items-center border-solid border-l-4 border-orange`}
                style={{
                  backgroundColor: "rgba(2, 2, 15, 0.3)",
                  color: "white",
                  minHeight: "64px",
                }}
              >
                <OneLinerText className="flex pl-18 text-sm uppercase text-center">
                  Płatności
                </OneLinerText>
              </div>
            </div>
            <div className="flex gap-4  w-full">
              <GridColumn cols={2}>
                <GridTextItem uppercase isHeader>
                  Data
                </GridTextItem>
                <GridTextItem uppercase isHeader>
                  Metoda płatności
                </GridTextItem>

                {passDetail?.map((single: any) => {
                  return (
                    <>
                      <GridTextItem>
                        {single?.CreatedAt?.slice(0, 10)}
                      </GridTextItem>
                      <GridTextItem>
                        {PASS_PAYMENT_METHOD[single?.PaymentMethod]}
                      </GridTextItem>
                    </>
                  );
                })}
              </GridColumn>
            </div>
          </div>
        </TabContent>
      ),
    },
    {
      tab: {
        id: "status",
        label: "Statusy",
      },
      content: (
        <TabContent id="status">
          <div
            className="flex gap-2 p-24 w-full"
            style={{ flexDirection: "column" }}
          >
            <div className={`flex flex-col w-full `}>
              <div
                title={"Faktury"}
                className={`flex justify-between items-center border-solid border-l-4 border-orange`}
                style={{
                  backgroundColor: "rgba(2, 2, 15, 0.3)",
                  color: "white",
                  minHeight: "64px",
                }}
              >
                <OneLinerText className="flex pl-18 text-sm uppercase text-center">
                  Statusy
                </OneLinerText>
              </div>
            </div>
            <div className="flex gap-4  w-full">
              <GridColumn cols={2}>
                <GridTextItem uppercase isHeader>
                  Data
                </GridTextItem>
                <GridTextItem uppercase isHeader>
                  Status
                </GridTextItem>

                {passDetail?.map((single: any) => {
                  return (
                    <>
                      <GridTextItem>
                        {single?.CreatedAt?.slice(0, 10)}
                      </GridTextItem>
                      <GridTextItem>
                        {PASS_TRANSACTION_STATUS[single?.Status]}
                      </GridTextItem>
                    </>
                  );
                })}
              </GridColumn>
            </div>
          </div>
        </TabContent>
      ),
    },
  ];

  return (
    <ContentContainer
      title={passDetail[0]?.Pass?.Name}
      // TopBar={<DigitalReleaseDetailTopbar event={folder} />}
      TopBar={<></>}
      noArrow={true}
      fn={() => closeModalOpen()}
      // path="/players"
    >
      <div>
        <TabsView>
          <Tabs tabs={tabs.map((t: any) => t.tab)} />

          <div style={{ padding: "10px 0 0 0" }}>
            {tabs.map((t: any) => t.content)}
          </div>
        </TabsView>
      </div>
    </ContentContainer>
  );
};

export default PassesDetail;

const StyledSelect = styled.select`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  background: url(${SelectArrow}) calc(100% - 10px) 60% no-repeat;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    height: 48px;
  }
`;
