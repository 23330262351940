import { ButtonHTMLAttributes } from "react";

export enum ButtonVariant {
  Normal,
  Submit,
  Add,
  Save,
  Edit,
  Cancel,
  Delete,
  Abort,
  Remove,
}

export enum Directions {
  Up,
  Down,
  Left,
  Right,
}

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any;
  className?: string;
  size?: number;
  variant?: ButtonVariant;
}

export interface IBookmarkButtonProps extends IButtonProps {
  direction: Directions;
}

export interface ISubmitButtonProps extends IButtonProps {
  isSubmitting: boolean;
  submitText?: string;
}

export const ButtonVariantArgType = {
  name: "Variant",
  control: {
    type: "radio",
  },
  options: [
    "Normal",
    "Submit",
    "Add",
    "Save",
    "Edit",
    "Cancel",
    "Delete",
    "Abort",
  ],
  mapping: {
    Normal: ButtonVariant.Normal,
    Submit: ButtonVariant.Submit,
    Add: ButtonVariant.Add,
    Save: ButtonVariant.Save,
    Edit: ButtonVariant.Edit,
    Cancel: ButtonVariant.Cancel,
    Delete: ButtonVariant.Delete,
    Abort: ButtonVariant.Abort,
  },
};
