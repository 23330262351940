import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import TextInput from "components/common/inputs/textInput/TextInput";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import orderService from "services/marketpleace/orderServices";
import systemServices from "services/systemServices";
import { showServerErrors } from "utils/errorsUtils";
import { handleNewBase64Change } from "utils/fileUtils";
import { useSelector } from "react-redux";
import { getInpostLabel } from "services/inpost";
import { useParams } from "react-router-dom";

interface IOrderDocumentsTopbarProps {
  orderId: string;
  refresh: () => Promise<void>;
  openLabelForm: (value: boolean) => void;
  isBtnDownloadActive: boolean;
}

interface RouteParams {
  id: string;
}

const OrderDocumentsTopbar = ({
  orderId,
  refresh,
  openLabelForm,
  isBtnDownloadActive,
}: IOrderDocumentsTopbarProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newBase64String, setNewBase64String] = useState<string>("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLabelActive, setIsLabelActive] = useState(false);
  const [isLoadingLabel, setIsLoadingLabel] = useState(false);
  const { id } = useParams<RouteParams>();

  const permission = useSelector((state: any) => state.permissions.permissions);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const sendFile = async () => {
    try {
      setIsLoading(true);
      if (!newBase64String) {
        toast.error("Brak pliku");
        return;
      }

      if (!fileName) {
        toast.error("Wpisz nazwę pliku");
        return;
      }

      const fileUrl = await systemServices.uploadFile(
        newBase64String.split(",")[1]
      );

      await orderService.addDocument(orderId, fileName, fileUrl);

      toast.success(`Dodano dokument ${fileName}`);
      resetForm();
      await refresh();
    } catch (error: any) {
      showServerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setNewBase64String("");
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    const file = fileInputRef.current?.files?.[0];
    if (file) {
      setFileName(file.name.split(".")[0]);
    }
  }, [fileInputRef.current?.files]);

  const isDocumentUploadActive =
    permission[0]?.Id === -1 ||
    !!permission.find((perm: any) => perm.Id === 21);

  const handleGenerateLabel = () => {
    setIsLabelActive((prev) => !prev);
    openLabelForm(!isLabelActive);
  };

  const handleDownloadLabel = async () => {
    try {
      setIsLoadingLabel(true);
      toast.success("Rozpoczęto pobranie etykiety");
      const pdfUrl = await getInpostLabel(id);

      window.open(pdfUrl.Path, "_blank");
      setIsLoadingLabel(false);
    } catch (error) {
      toast.error("Wystąpił problem z pobraniem etykiety");
    } finally {
      setIsLoadingLabel(false);
    }
  };

  return (
    <div className="flex p-8 gap-2">
      <input
        type="file"
        onChange={(e) => handleNewBase64Change(e, setNewBase64String)}
        ref={fileInputRef}
        style={{ display: "none" }}
      />

      <div className="w-auto flex gap-2">
        <Button
          variant={ButtonVariant.Save}
          onClick={handleDownloadLabel}
          disabled={isLoadingLabel}
          style={{
            opacity: isBtnDownloadActive ? "" : "0.6",
            pointerEvents: isBtnDownloadActive ? "auto" : "none",
            alignSelf: "center",
          }}
        >
          <span className="font-medium">{"Pobierz etykietę"}</span>
        </Button>

        <Button
          variant={ButtonVariant.Add}
          onClick={handleGenerateLabel}
          disabled={isLoading}
          style={{ alignSelf: "center" }}
        >
          <span className="font-medium ">{"Dodaj etykietę"}</span>
        </Button>
      </div>

      <div
        style={{
          pointerEvents: isDocumentUploadActive ? "auto" : "none",
          opacity: isDocumentUploadActive ? 1 : 0.6,
          alignSelf: "center",
        }}
      >
        <Button
          className="px-8"
          variant={ButtonVariant.Abort}
          onClick={handleButtonClick}
          disabled={isLoading}
          style={{ alignSelf: "center" }}
        >
          <span className="material-icons-outlined text-3xl">upload_file</span>
        </Button>
      </div>

      <TextInput
        type="text"
        name="tesad"
        label="Zmień nazwę"
        placeholder={!newBase64String ? "Wybierz dokument" : "Wpisz nazwę"}
        showErrors={false}
        disabled={!newBase64String || isLoading}
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
      />

      <Button
        variant={ButtonVariant.Add}
        disabled={!newBase64String || !fileName || isLoading}
        onClick={sendFile}
        style={{ alignSelf: "center" }}
      >
        Dodaj
      </Button>
    </div>
  );
};

export default OrderDocumentsTopbar;
