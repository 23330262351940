import cs from "classnames";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as TrueIco } from "assets/icons/true.svg";
import { ReactComponent as FalseIco } from "assets/icons/false.svg";
import { toast } from "react-toastify";
import permissionService from "services/permissionServices";
import permissionsServices from "services/permissionsServices";
import notificationsServices from "services/notificationsServices";

const notificationNames = [
  "PlayerAcceptedTransfer",
  "AcademyAcceptedTransfer",
  "PlayerSentTransferRequest",
  "AcademySentTransferRequest",
  "PlayerDeletedTransfer",
  "AcademyDeletedTransfer",
  "PlayerDeletedTransferRequest",
  "AcademyDeletedTransferRequest",
  "TrainerRelationCreated",
  "TrainerRelationRequestCreated",
  "TrainerRelationDeleted",
  "TrainerRelationRequestDeleted",
  "PlayerPassNotPaid",
  "PlayerPassPaid",
  "ProductVerified",
  "ProductCanceled"
];


const revokePermissionsForUser = (id: any, permissionId: number) => {
  const permissionMap: Record<number, number[]> = {
    13: [ 13, 14],
    46: [46, 241],
    111: [111, 122],
    123: [123, 235],
    125: [110, 125, 227],
    126: [126, 92],
    130: [226, 120, 130],
    131: [131, 206, 209],
    132: [132, 207],
    133: [133, 94],
    134: [134, 96, 231],
    135: [135, 93],
    136: [136, 66, 68],
    137: [137, 111, 68, 122, 236],
    138: [138, 66, 68],
    139: [139, 55, 59, 67],
    147: [147, 230],
    148: [148, 232],
    149: [149, 233],
    152: [152, 245],
    153: [153, 234, 114],
    155: [155, 237],
    156: [156, 238],
    160: [160, 45],
    161: [161, 115],
    162: [162, 101],
    163: [163, 43],
    164: [164, 42],
    165: [165, 109],
    173: [173, 209],
    180: [180, 246, 247, 248],
    182: [182, 111, 68, 122],
    184: [184, 230],
    185: [185, 232],
    186: [186, 233],
    189: [189, 245],
    190: [190, 114, 234],
    192: [192, 237],
    193: [193, 238],
    205: [205, 96],
    208: [208, 174],
    211: [211, 212],
    213: [213, 214],
    216: [216, 96, 240],
    223: [223, 96, 231],
    224: [224, 111, 68, 122],
    239: [239, 141, 44]
  };

  if (permissionMap[permissionId]) {
    return permissionsServices.revokePermissionsForUser(id, permissionMap[permissionId]);
  }
  return permissionService.revokePermissionToUser(id, permissionId);
};

const grantPermissionsForUser = (id: any, permissionId: number) => {
  const permissionMap: Record<number, number[]> = {
    13: [ 13, 14],
    46: [46, 241],
    111: [111, 122],
    123: [123, 235],
    125: [110, 125, 227],
    126: [126, 92],
    130: [226, 120, 130],
    131: [131, 206, 209],
    132: [132, 207],
    133: [133, 94],
    134: [134, 96, 231],
    135: [135, 93],
    136: [136, 66, 68],
    137: [137, 111, 68, 122, 236],
    138: [138, 66, 68],
    139: [139, 55, 59, 67],
    147: [147, 230],
    148: [148, 232],
    149: [149, 233],
    152: [152, 245],
    153: [153, 234, 114],
    155: [155, 237],
    156: [156, 238],
    160: [160, 45],
    161: [161, 115],
    162: [162, 101],
    163: [163, 43],
    164: [164, 42],
    165: [165, 109],
    180: [180, 246, 247, 248],
    182: [182, 111, 68, 122],
    184: [184, 230],
    185: [185, 232],
    186: [186, 233],
    189: [189, 245],
    190: [190, 114, 234],
    192: [192, 237],
    193: [193, 238],
    205: [205, 96],
    208: [208, 174],
    209: [173, 209],
    211: [211, 212],
    213: [213, 214],
    216: [216, 96, 240],
    223: [223, 96, 231],
    224: [224, 111, 68, 122],
    239: [239, 141, 44]
  };

  if (permissionMap[permissionId]) {
    return permissionsServices.grantPermissionsForUser(id, permissionMap[permissionId]);
  }
  return permissionService.grantPermissionToUser(id, permissionId);
}

interface NotificationSwitchProps {
  className?: string;
  label?: string;
  name: string;
  checked: boolean;
  flagValue?: number;
  domainName?: string;
  flagName?: string;
  applyStyle?: (value: any) => void;
}

const NotificationSwitch: React.FC<NotificationSwitchProps> = ({
  className = '',
  label,
  name,
  checked,
  flagValue,
  applyStyle
}) => {
  const [isChecked, setChecked] = useState(checked);
  const { id } = useParams<{ id: string }>();

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(!isChecked);

    if (flagValue === undefined) {
      return;
    }

    const isNotificationHandle = notificationNames.some(e => e === name);

    if (!isNotificationHandle) {
      try {
        if (isChecked) {
          await revokePermissionsForUser(id!, flagValue);
          toast.error("Dostęp został ograniczony");
        } else {
          await grantPermissionsForUser(id!, flagValue);
          toast.success("Nadano dostęp");
        }
      } catch (error) {
        toast.error("Wystąpił błąd podczas aktualizacji dostępu");
        console.error("Error updating permission:", error);
      }
    } else {
      try {
        if (!isChecked) {
          await notificationsServices.grandNotification(flagValue)
          toast.success("Dodano powiadomnienie");
        } else {
          await notificationsServices.revokeNotification(flagValue);
          toast.error("Usunięto powiadomienie");
        }
      } catch (error) {
        toast.error("Wystąpił błąd podczas aktualizacji powiadomień");
        console.error("Error updating notifications:", error);
      }
    }

    if (applyStyle) {
      applyStyle({ flagValue: flagValue, disabled: !isChecked })
    }
  };


  return (
    <div
    // className={className}
    >
      <div
      // className="flex gap-4 items-center h-10 justify-between"
      >
        {label && (
          <label className="text-sm capitalize-first" htmlFor={name}>
            {label}
          </label>
        )}
        <label
          htmlFor={name}
          data-testid="switchContainer"
          className="cursor-pointer w-max"
        >
          <input
            data-testid="switchInput"
            className="hidden"
            type="checkbox"
            name={name}
            id={name}
            checked={isChecked}
            onChange={handleChange}
          />
          <div className="flex relative items-center">
            <div className="relative w-8 h-4 bg-black border border-gray-light rounded user-select-none" />
            <div
              className={cs(
                "absolute left-0 flex justify-center items-center w-5 h-5 rounded border border-gray-light border-opacity-70 transition-all duration-150 transform",
                {
                  "bg-green translate-x-3": isChecked,
                  "bg-red": !isChecked,
                },
              )}
              style={{ boxShadow: "0 1px 4px rgba(0,0,0,0.5)" }}
            >
              {isChecked ? (
                <TrueIco className="w-3 h-3" />
              ) : (
                <FalseIco className="w-2 h-2" />
              )}
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default NotificationSwitch;
