import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ISortingProps } from "types/sortType";
import HomeSearchIco from "assets/icons/home-search-ico.png";
import { ISelectOption, LIST_SORT } from "types/globalTypes";
import { BlurBox, SearchBox, SearchInput, SideMenu } from "styles/styles";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import { LIST_VIEW } from "types/transfer/player.interface";
import { LIST_VIEW as a } from "types/transfer/trainer.interface";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import Button from "components/common/buttons/basicButton/Button";
import ScannerSideMenu from "./ScannerSideMenu";
import { IFilterPlayerValue } from "types/filterPassData";
import ScannerSideMenuTrainer from "components/trainers/detail/ScannerSideMenuTrainer";
import FilterButton from "components/common/buttons/basicButton/FilterButton";

interface IViewLabel {
  label: string;
  value: number;
}
interface IScannerTopbarProps extends ISortingProps<LIST_SORT> {
  handleQueryChange: (value: string) => void;
  setListView: Dispatch<SetStateAction<ISelectOption<LIST_VIEW> | null>>;
  listView: ISelectOption<LIST_VIEW> | null;
  listViews: ISelectOption<string>[];
  refreshScanner: () => Promise<void>;
  refreshFilters: () => Promise<void>;
  filter: ISelectOption<string> | null;
  setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  filters: ISelectOption<string>[];
  isQueryEmpty: boolean;
  viewLabels: IViewLabel[];
  handleFilterData?: (obj: IFilterPlayerValue) => void;
  isFilterPlayersActive?: boolean;
}

const ScannerTopbar = ({
  defaultSort,
  handleQueryChange,
  setSortBy,
  sortBy,
  sorts,
  setListView,
  listView,
  listViews,
  refreshScanner,
  refreshFilters,
  filter,
  filters,
  setFilter,
  isQueryEmpty,
  viewLabels,
  handleFilterData,
  isFilterPlayersActive,
}: IScannerTopbarProps) => {
  const [isScannerMenuOpen, toggleScannerMenuOpen] = useState(false);
  const [avaliablePlayerFilter, setAvaliablePlayerFilter] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [query, setQuery] = useState("");

  const [visibility, setVisibility] = useState(viewLabels[0]?.label || "");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
    handleQueryChange(newValue);
  };

  useEffect(() => {
    setQuery("");
  }, [isQueryEmpty]);

  const changeVisibility = () => {
    const nextView = isChecked ? viewLabels[0] : viewLabels[1];
    setIsChecked(!isChecked);
    setListView({
      label: nextView.label,
      value: nextView.value,
    });
    setVisibility(nextView.label);
  };

  console.log(
    isFilterPlayersActive,
    "isFilterPlayersActiveisFilterPlayersActive"
  );

  const filterValues = [
    {
      id: 1,
      type: "select",
      name: "Gender",
      values: [
        { backend: 0, pl: "Mężczyzna" },
        { backend: 1, pl: "Kobieta" },
      ],
      pl: "Płeć",
    },
    {
      id: 2,
      type: "rangeOfAmount",
      name: "YearOfBirth",
      pl: "Rok urodzenia",
    },

    {
      id: 3,
      type: "rangeOfAmount",
      name: "Area",
      pl: "Promień",
    },
  ];

  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2 pr-24"
      style={{ gap: "3px" }}
    >
      <BlurBox
        isMenuOpen={
          isScannerMenuOpen && listView?.value === viewLabels[1].value
        }
        onClick={() => toggleScannerMenuOpen((prev) => !prev)}
      />

      <SideMenu isMenuOpen={isScannerMenuOpen}>
        <ScannerSideMenu
          refreshScanner={refreshScanner}
          refreshFilters={refreshFilters}
          filter={filter}
          setFilter={setFilter}
          filters={filters}
          handleFilterData={handleFilterData}
        />
      </SideMenu>

      <BlurBox
        isMenuOpen={avaliablePlayerFilter}
        onClick={() => setAvaliablePlayerFilter((prev) => !prev)}
      />

      <SideMenu isMenuOpen={avaliablePlayerFilter}>
        <ScannerSideMenuTrainer filterArray={filterValues} />
      </SideMenu>
      {/* <div
        className='flex mr-0 xl:mr-4 '
        style={{
          alignContent: 'center',
          border: '2px solid #323a44',
          borderRadius: '2px',
        }}
      >
        <div
          style={{
            backgroundColor: '#222c38',
            transition: 'background-color 0.3s ease',
            padding: '3px',
          }}
        >
          <button
            onClick={changeVisibility}
            style={{
              color: 'white',
              height: '38px',
              padding: '7px 2px',
              width: '188px',
              backgroundColor: isChecked ? '#222c38' : 'rgba(163, 158, 157, 0.38)',
              pointerEvents: !isChecked ? 'none' : 'auto',
              transition: 'background-color 0.3s ease',
              borderRadius: isChecked ? '0px' : '2px',
            }}
            disabled={!isChecked}
          >
            {viewLabels[0]?.label}
          </button>

          <button
            onClick={changeVisibility}
            style={{
              color: 'white',
              height: '38px',
              padding: '0px 18px',
              backgroundColor: !isChecked ? '#222c38' : 'rgba(163, 158, 157, 0.38)',
              pointerEvents: isChecked ? 'none' : 'auto',
              transition: 'background-color 0.3s ease',
              borderRadius: isChecked ? '2px' : '0px',
            }}
            disabled={isChecked}
          >
            {viewLabels[1]?.label}
          </button>
        </div>
      </div> */}

      <div className="w-full">
        <div
          style={{
            height: "48px",
            width: "100%",
          }}
        ></div>
        <div className="w-full flex gap-1 items-center justify-end">
          {sorts.length ? (
            <NewSelectStatystics<LIST_SORT>
              name="Sort"
              items={sorts}
              label="Sortowanie"
              selectedItem={sortBy}
              setSelectedItem={setSortBy}
              defaultValue={defaultSort}
            />
          ) : null}
          <SearchBox>
            <SearchInput
              className="w-full md:w-36 xl:w-72 bg-red"
              placeholder="Szukaj..."
              autoComplete="off"
              name="search"
              onChange={handleSearch}
              value={query}
            />
            <div>
              <img src={HomeSearchIco} alt="ico" />
            </div>
          </SearchBox>
          {listView?.value === viewLabels[1].value ? (
            <div
              style={{
                pointerEvents: isFilterPlayersActive ? "auto" : "none",
              }}
            >
              <FilterButton
                variant={ButtonVariant.Submit}
                onClick={() =>
                  listView?.value === viewLabels[1].value &&
                  toggleScannerMenuOpen(true)
                }
                className={`transition delay-150 duration-700 ease-in-out ${
                  isFilterPlayersActive ? "opacity-100" : "opacity-40 "
                }`}
              />
            </div>
          ) : (
            <div
              style={{
                pointerEvents: isFilterPlayersActive ? "auto" : "none",
              }}
            >
              <FilterButton
                variant={ButtonVariant.Abort}
                onClick={() =>
                  // listView?.value === viewLabels[1].value &&
                  setAvaliablePlayerFilter(true)
                }
                className={`transition delay-150 duration-700 ease-in-out ${
                  isFilterPlayersActive ? "opacity-100" : "opacity-40 "
                }`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScannerTopbar;
