import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "store/store";

import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";
import EditDigitalRelease from "../form/EditDigitalRelease";
import axios from "axios";
import UserStatusInfo from "components/users/status/UserStatusInfo";
import { getEventsPathWithDate } from "components/navbar/navbarUtils";
import chatServices from "services/chatServices";
import { AllConversationsContext } from "contexts/allConversationsContext";
import transferService from "services/transfer/transferService";
import { getTrainerDetail } from "services/transferServices";

interface IDigitalReleaseDetailTopbarProps {
  event: any;
  isStructure?: boolean;
  trainerStatus?: number;
  trainerId?: string;
}

const TrainersDetailTopbar: React.FC<IDigitalReleaseDetailTopbarProps> = ({
  event,
  isStructure,
  trainerStatus,
  trainerId,
}) => {
  const { push } = useHistory();
  const [trainersStatus, setTrainersStatus] = useState(false);
  //TODO: const permissions = useSelector((state: RootState) => state.userPermissions?.DigitalReleases);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist
  );

  // if (!permissions) {
  //   return null;
  // }

  const [
    allConversations,
    setAllConversations,
    filter,
    setFilter,
    searchQuery,
    setSearchQuery,
    conversation,
    setConversation,
    conversationMembers,
    setConversationMembers,
    activeConversationImageUrl,
    setActiveConversationImageUrl,
    activeConversationName,
    setActiveConversationName,
    onlineProfiles,
    setOnlineProfiles,
    activeConversationId,
    setActiveConversationId,
    activeConversationType,
    setActiveConversationType,
    tempMemberId,
    setTempMemberId,
    unreadMessagesCounter,
    setUnreadMessagesCounter,
    allInterlocutors,
    setAllInterlocutors,
    auxFolderName,
    setAuxFolderName,
    activeMemberType,
    setActiveMemberType,
  ] = useContext(AllConversationsContext);

  const handleEdit = (id: string) => {
    push(`/shop/products/edit/${id}`);
  };

  const { id } = useParams<{ id: string }>();

  const createConversationFn = async () => {
    const resp2: any = await chatServices.getPrivateConversationById(
      trainerId ? trainerId : id,
      2
    );
    console.log("RESP2>>>", resp2);
    const detail = await getTrainerDetail(trainerId ? trainerId : id);

    console.log("Playerdeatails", detail);

    if (resp2 === null) {
      console.log("XAXAAX", detail);
      // await chatServices.createNewConversation(trainerId? trainerId :id, 1);

      setActiveConversationId("");
      setActiveConversationImageUrl(detail?.Data?.PhotoFilePath);
      setActiveConversationName(detail?.Data?.Name);
      setActiveConversationType(1);
      setConversation([]);
      setTempMemberId(trainerId ? trainerId : id);
      setActiveMemberType(2);

      // await toast.success("Stworzono konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(resp2?.ConversationId);

      await push("/chat");
    }
  };

  console.log("eventxaxaxa", event);

  useEffect(() => {
    setTrainersStatus(trainerStatus === 1);
  }, [trainerStatus]);

  return (
    <div className="flex justify-between text-sm">
      {/* <div className="flex" style={{ gap: "4px" }}> */}
      {/* <BlurBox
        isEditMenuOpen={isEditMenuOpen}
        onClick={() => toggleEditMenuOpen(false)}
      />
      <SideEditMenu isEditMenuOpen={isEditMenuOpen}>
        <div></div>
      </SideEditMenu> */}

      <div></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "48px",
          // width: "200px",
          gap: "20px",
        }}
      >
        {!isStructure && <UserStatusInfo isActive={trainersStatus} />}
        <Button
          variant={ButtonVariant.Add}
          onClick={() => createConversationFn()}
        >
          Wyślij wiadomość
        </Button>
      </div>
    </div>
  );
};

export default TrainersDetailTopbar;
