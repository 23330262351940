import React from "react";
import styled from "styled-components";
import placeholder from "../../assets/images/new-placeholder.png";

interface Props {
  isActive: boolean;
  name: string;
  message: string;
  date: string;
  id: string;
  type: number;
  handleClick: any;
  setActiveConversationName: any;
  setActiveConversationId: any;
  setActiveConversationType: any;
  setActiveConversationImageUrl: any;
  image: string;
  activeConversationId: string;
  toggleAddNewConversation: any;
  readConversation: any;
  setActiveMemberType: any;
}

const SingleUser: React.FC<Props> = ({
  isActive,
  date,
  message,
  name,
  id,
  type,
  handleClick,
  setActiveConversationImageUrl,
  setActiveConversationName,
  setActiveConversationId,
  activeConversationId,
  setActiveConversationType,
  image,
  toggleAddNewConversation,
  readConversation,
  setActiveMemberType,
}) => {
  return (
    <Container
      activeConversationId={activeConversationId}
      conversationId={id}
      isActive={isActive}
      onClick={() => {
        toggleAddNewConversation(false);
        handleClick(id);
        setActiveConversationImageUrl(image);
        setActiveConversationName(name);
        setActiveConversationId(id);
        setActiveConversationType(type);
        readConversation(id);
        setActiveMemberType(type);
      }}
    >
      <div>
        <img src={image ? image : placeholder} alt="" />
      </div>
      <div>
        <p>{date}</p>
        <p>{name}</p>
        <div>
          <p>{message}</p>
        </div>
        <div />
      </div>
    </Container>
  );
};

export default SingleUser;

const Container = styled.div<{
  isActive: boolean;
  activeConversationId: string;
  conversationId: string;
}>`
  width: 100%;
  cursor: pointer;
  min-height: 48px;
  border-radius: 7px;
  height: 48px;
  max-height: 48px;
  background: rgba(163, 158, 157, 0.09);
  display: flex;
  /* padding: 0 5px; */

  /* filter: ${(props) =>
    props.activeConversationId === props.conversationId
      ? "brightness(1.5)"
      : "brightness(1)"}; */
  border: ${(props) =>
    props.activeConversationId === props.conversationId
      ? "1px solid #0091FF"
      : "none"};
  /* border-right: ${(props) =>
    props.isActive ? "5px solid #0BFA42" : "5px solid #f8000a"}; */

  & > div:nth-of-type(1) {
    height: 100%;
    width: 47px;
    display: flex;
    background: rgba(2, 2, 15, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: auto;
      height: 100%;
      border-radius: 7px;
      -webkit-box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
      box-shadow: 5px 0px 22px -1px rgba(0, 0, 0, 0.5);
    }
  }

  & > div:nth-of-type(2) {
    height: 100%;
    width: calc(100% - 47px);
    display: flex;
    position: relative;
    flex-direction: column;

    & > p:nth-of-type(1) {
      font-size: 12px;
      font-weight: 300;
      color: rgba(156, 176, 196, 0.3);
      letter-spacing: 0.175px;
      position: absolute;
      top: 3px;
      right: 25px;
    }
    & > p:nth-of-type(2) {
      font-size: 11px;
      font-weight: 400;
      font-size: 12px;
      font-weight: 300;
      color: white;
      letter-spacing: 0.275px;
      letter-spacing: 0.175px;
      margin-top: 3px;
      padding-left: 10px;
    }

    & > div {
      display: flex;
      width: 100%;
      height: 100%;

      & > p {
        font-size: 11px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        letter-spacing: 0.275px;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75%;
        display: flex;
        flex-wrap: nowrap;
        max-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & > div:last-of-type {
      height: 45px;
      background: ${(props) => (props.isActive ? "#46F03A" : " #DC2121")};
      width: 5px;
      position: absolute;
      top: 1px;
      right: 10px;
    }
  }

  /* & > div:nth-of-type(3) {
    position: relative;
    height: 100%;
    width: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    & > div {
      position: absolute;
      border-radius: 100%;
      border: 1px solid #707070;
     
      height: 20px;
      width: 20px;
      top: 9px;
      right: 0px;
    }
    & > p {
      font-size: 14px;
      font-weight: 400;
      color: #0091ff;
      text-align: -0.21px;
      margin-bottom: 12px;
    }
  } */
`;
