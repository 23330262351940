import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import {
  GENDER,
  IPlayerDetail,
  PLAYER_STATE,
  TRANSFER_LEVEL,
} from 'types/transfer/player.interface';
import logo from '../../../assets/images/logo.svg';
import { getFormatedDate } from 'utils/dateUtils';

interface IPlayerDetailInfoProps {
    player: IPlayerDetail;
}

const PlayerDetailInfo = ({ player }: IPlayerDetailInfoProps) => {
  const appliedLength = player?.AppliedPasses.length;
  const filePath = player?.PhotoFilePath;

  return (
    <div className='flex flex-col'>
      <InfoBox className='p-18'>
        <InfoBox.Image src={filePath?.length ? filePath : logo} />

        <InfoBox.Items>
          <InfoBox.InfoItem
            label={'Imię i nazwisko'}
            value={`${player?.Name}`}
          />
          <InfoBox.InfoItem />

          <InfoBox.InfoItem
            label={'Relacja'}
            value={
              player?.Level !== null ? TRANSFER_LEVEL[player.Level] : 'Brak'
            }
          />

          <InfoBox.InfoItem
            label={'Data urodzenia'}
            value={getFormatedDate(player?.Birthday, 'dd.MM.yyyy')}
          />
          <InfoBox.InfoItem />

          <InfoBox.InfoItem
            label={'Status'}
            value={ player?.State !== null ? PLAYER_STATE[player?.State] : 'Brak'}
          />

          <InfoBox.InfoItem label={'Płeć'} value={GENDER[player.Gender]} />
          <InfoBox.InfoItem />
          <InfoBox.InfoItem
            label={'Karnet'}
            value={appliedLength > 0 ? 'Tak' : 'Nie'}
          />
        </InfoBox.Items>
      </InfoBox>
    </div>
  );
};

export default PlayerDetailInfo;
