import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { Chart as ChartJS, defaults, registerables } from "chart.js";
import FakeLane from "assets/images/fake-lane.png";
import { Rnd } from "react-rnd";
import LeftArrowIco from "assets/icons/left-arrow.png";
import RightArrowIco from "assets/icons/right-arrow.png";
import {
  Line,
  Bar,
  Bubble,
  Chart,
  PolarArea,
  Radar,
  Scatter,
  ChartProps,
  Doughnut,
  Pie,
} from "react-chartjs-2";
import { utcToLocalDate } from "../EventsDetail";

interface Props {
  event: any;
  allAttendances: any;
  selectedPlayerId: any;
  setSelectedPlayerId: any;
  selectedParam: any;
  setSelectedParam: any;
  selectedParamValueName: any;
  setSelectedParamValueName: any;
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
}

const EventStatistics: React.FC<Props> = ({
  event,
  allAttendances,
  selectedPlayerId,
  setSelectedPlayerId,
  selectedParam,
  setSelectedParam,
  selectedParamValueName,
  setSelectedParamValueName,
  endDate,
  startDate,
  setEndDate,
  setStartDate,
}) => {
  // const [labels, setLabels] = useState<any>();
  const [allDatasets, setAllDatasets] = useState<any>([]);
  const MIN_DURATION = 10 * 60 * 1000;
  const { id } = useParams<{ id: string }>();
  ChartJS.register(...registerables);

  const containerRef = useRef<HTMLDivElement>(null);
  const minDate = new Date(event?.OccurenceDate);
  const maxDate = new Date(event?.EndDate);
  const totalDuration = maxDate.getTime() - minDate.getTime();

  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(100);

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    setWidth(containerWidth);
  }, []);

  useEffect(() => {
    if (selectedParam) {
      let minDate = Infinity;
      let maxDate = -Infinity;

      allAttendances.forEach((attendance: any) => {
        const records = attendance?.StatisticData[selectedParam] || [];

        for (let i = 0; i < records.length; i++) {
          if (records[i][selectedParamValueName] > 0) {
            const recordDate = new Date(records[i].RecordDate).getTime();
            if (recordDate < minDate) {
              minDate = recordDate;
            }
            break;
          }
        }

        for (let i = records.length - 1; i >= 0; i--) {
          if (records[i][selectedParamValueName] > 0) {
            const recordDate = new Date(records[i].RecordDate).getTime();
            if (recordDate > maxDate) {
              maxDate = recordDate;
            }
            break;
          }
        }
      });

      if (minDate !== Infinity && maxDate !== -Infinity) {
        setStartDate(new Date(minDate));
        setEndDate(new Date(maxDate));
      }
    }
  }, [allAttendances, selectedParamValueName, setStartDate, setEndDate]);

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const totalDuration = maxDate.getTime() - minDate.getTime();
    const newLeft =
      ((new Date(startDate).getTime() - minDate.getTime()) / totalDuration) *
      containerWidth;
    const newWidth =
      ((new Date(endDate).getTime() - new Date(startDate).getTime()) /
        totalDuration) *
      containerWidth;

    setLeft(newLeft);
    setWidth(newWidth);
  }, [startDate, endDate]);

  const getDifferenceInMinutes = (start: any, end: any) => {
    const diffInMs = end.getTime() - start.getTime();
    const diffInMinutes = diffInMs / (1000 * 60);
    return diffInMinutes;
  };

  const handleDragStop = (e: any, d: any) => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const newLeft = Math.max(0, Math.min(d.x, containerWidth - width));
    setLeft(newLeft);

    let newStartDate = new Date(
      minDate.getTime() + (newLeft / containerWidth) * totalDuration
    );
    let newEndDate = new Date(
      newStartDate.getTime() + (width / containerWidth) * totalDuration
    );

    const differenceInMinutes = getDifferenceInMinutes(
      newStartDate,
      newEndDate
    );
    // if (differenceInMinutes < 10) {
    //   newEndDate = new Date(newStartDate.getTime() + MIN_DURATION);
    // }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    console.log(
      "Różnica minut:",
      getDifferenceInMinutes(newStartDate, newEndDate)
    );
  };

  const handleResizeStop = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: any
  ) => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    let newLeft = left;
    let newWidth = width;

    if (direction === "left") {
      newLeft = Math.max(0, Math.min(position.x, left + width - 10));
      newWidth = width + left - newLeft;
    } else {
      newWidth = Math.max(
        0,
        Math.min(parseFloat(ref.style.width), containerWidth - position.x)
      );
    }

    let newStartDate = new Date(
      minDate.getTime() + (newLeft / containerWidth) * totalDuration
    );
    let newEndDate = new Date(
      newStartDate.getTime() + (newWidth / containerWidth) * totalDuration
    );

    const differenceInMinutes = getDifferenceInMinutes(
      newStartDate,
      newEndDate
    );
    // if (differenceInMinutes < 10) {
    //   newWidth = (MIN_DURATION / totalDuration) * containerWidth;
    //   if (direction === "left") {
    //     newLeft = left + width - newWidth;
    //   } else {
    //     newEndDate = new Date(newStartDate.getTime() + MIN_DURATION);
    //   }
    // }

    setLeft(newLeft);
    setWidth(newWidth);

    newStartDate = new Date(
      minDate.getTime() + (newLeft / containerWidth) * totalDuration
    );
    newEndDate = new Date(
      newStartDate.getTime() + (newWidth / containerWidth) * totalDuration
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    console.log(
      "Różnica minut:",
      getDifferenceInMinutes(newStartDate, newEndDate)
    );
  };

  const handleDrag = (e: any, d: any) => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const newLeft = Math.max(0, Math.min(d.x, containerWidth - width));

    let newStartDate = new Date(
      minDate.getTime() + (newLeft / containerWidth) * totalDuration
    );
    let newEndDate = new Date(
      newStartDate.getTime() + (width / containerWidth) * totalDuration
    );

    const differenceInMinutes = getDifferenceInMinutes(
      newStartDate,
      newEndDate
    );
    // if (differenceInMinutes < 10) {
    //   newEndDate = new Date(newStartDate.getTime() + MIN_DURATION);
    // }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setLeft(newLeft);
  };

  const handleResize = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: any
  ) => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    let newLeft = left;
    let newWidth = width;

    if (direction === "left") {
      newLeft = Math.max(0, Math.min(position.x, left + width - 10));
      newWidth = width + left - newLeft;
    } else {
      newWidth = Math.max(
        0,
        Math.min(parseFloat(ref.style.width), containerWidth - position.x)
      );
    }

    let newStartDate = new Date(
      minDate.getTime() + (newLeft / containerWidth) * totalDuration
    );
    let newEndDate = new Date(
      newStartDate.getTime() + (newWidth / containerWidth) * totalDuration
    );

    const differenceInMinutes = getDifferenceInMinutes(
      newStartDate,
      newEndDate
    );
    // if (differenceInMinutes < 10) {
    //   newWidth = (MIN_DURATION / totalDuration) * containerWidth;
    //   if (direction === "left") {
    //     newLeft = left + width - newWidth;
    //   } else {
    //     newEndDate = new Date(newStartDate.getTime() + MIN_DURATION);
    //   }
    // }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setLeft(newLeft);
    setWidth(newWidth);
  };

  function roundToTwo(num: number): number {
    return +(Math.round(num * 100) / 100).toFixed(2);
  }

  const options = {
    aspectRatio: 5,
    plugins: {
      tooltip: {
        interaction: {
          mode: "nearest",
        },

        filter: function (tooltipItem: any) {
          return tooltipItem?.raw?.y !== 0;
        },
      },
      legend: {
        display: false,
        position: "bottom",
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: true,
    },
    stacked: false,
    layout: {
      padding: {
        bottom: -10,
        left: 15,
      },
    },
    scales: {
      x: {
        offset: false,
        title: {
          align: "start",
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          padding: 20,
          beginAtZero: true,
          display: true,
          color: "#a39e9d78",
          // @ts-ignore
          callback: function (value, index, values) {
            const totalLabels = values.length;
            const stepSize = Math.ceil(totalLabels / 10);
            if (index % stepSize === 0) {
              // @ts-ignore
              return this.getLabelForValue(value);
            }
            return "";
          },
        },
        grid: {
          tickColor: "#a39e9d78",
          color: "#a39e9d78",
          drawTicks: true,
        },
      },
      y: {
        grid: {
          tickColor: "rgba(163,158,157,0.3)",
          color: "#a39e9d78",
        },
        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          color: "#FF9226",
          beginAtZero: true,
          callback: function (value: any) {
            return roundToTwo(value);
          },
        },
      },
    },
    elements: {
      point: {
        radius: (context: any) => (context?.raw?.y === 0 ? 0 : 1),
        hoverRadius: (context: any) => (context?.raw?.y === 0 ? 0 : 3),
      },
      line: {
        borderWidth: 2,
      },
    },
  };

  const colorSwitch = (index: number) => {
    switch (index) {
      case 0:
        return "#C41EC4";
      case 1:
        return "#F97D00";
      case 2:
        return "#1060FF";
      case 3:
        return "#88E03C";
      case 4:
        return "#00FFFF";
      case 5:
        return "#FF74D6";
      case 6:
        return "#FFFFFF";
      case 7:
        return "#FF0000";
      case 8:
        return "#EEFF00";
      case 9:
        return "#00B3FF";
      case 10:
        return "#3B9E45";

      default:
        return "#8F8F8F";
    }
  };

  const getDataSet = (profileId: string, index: number) => {
    const attendance = allAttendances.find(
      (att: any) => att?.ProfileId === profileId
    );

    if (!attendance) {
      return [];
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const records = attendance.StatisticData[selectedParam];

    if (!records || records.length === 0) {
      return [];
    }

    const firstRecordDate = new Date(records[0].RecordDate);
    const lastRecordDate = new Date(records[records.length - 1].RecordDate);

    const dataMap: Record<string, number> = {};

    for (
      let time = new Date(start);
      time < firstRecordDate;
      time.setSeconds(time.getSeconds() + 60)
    ) {
      const timeString = formatTime(time);
      dataMap[timeString] = 0;
    }
    if (selectedParam === "HeartRate") {
      records.forEach((curr: any) => {
        const recordDate = new Date(curr.RecordDate);
        if (recordDate >= start && recordDate <= end) {
          const newRecordDate = new Date(utcToLocalDate(curr.RecordDate));
          const roundedSeconds = Math.floor(newRecordDate.getSeconds() / 1) * 1;
          newRecordDate.setSeconds(roundedSeconds);
          const timeString = formatTime(newRecordDate);
          dataMap[timeString] = curr[selectedParamValueName];
        }
      });

      for (
        let time = new Date(lastRecordDate);
        time <= end;
        time.setSeconds(time.getSeconds() + 1)
      ) {
        const timeString = formatTime(time);
        if (!(timeString in dataMap)) {
          dataMap[timeString] = 0;
        }
      }

      const data = Object.keys(dataMap).map((timeString) => ({
        x: timeString,
        y: dataMap[timeString],
      }));

      return {
        label: selectedParam,
        data: data,
        borderColor: colorSwitch(index),
        backgroundColor: colorSwitch(index),
        tension: 0.1,
      };
    } else {
      records.forEach((curr: any) => {
        const recordDate = new Date(curr.RecordDate);
        if (recordDate >= start && recordDate <= end) {
          const newRecordDate = new Date(utcToLocalDate(curr.RecordDate));
          const roundedSeconds = Math.floor(newRecordDate.getSeconds() / 5) * 5;
          newRecordDate.setSeconds(roundedSeconds);
          const timeString = formatTime(newRecordDate);
          dataMap[timeString] = curr[selectedParamValueName];
        }
      });

      for (
        let time = new Date(lastRecordDate);
        time <= end;
        time.setSeconds(time.getSeconds() + 5)
      ) {
        const timeString = formatTime(time);
        if (!(timeString in dataMap)) {
          dataMap[timeString] = 0;
        }
      }

      const data = Object.keys(dataMap).map((timeString) => ({
        x: timeString,
        y: dataMap[timeString],
      }));

      return {
        label: selectedParam,
        data: data,
        borderColor: colorSwitch(index),
        backgroundColor: colorSwitch(index),
        tension: 0.1,
      };
    }
  };

  function formatTime(date: Date): string {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    setAllDatasets([]);
    if (selectedPlayerId.length > 0) {
      selectedPlayerId.map((single: any, index: number) => {
        const singleDataSet = getDataSet(single, index);
        setAllDatasets((prevDataSets: any) => [...prevDataSets, singleDataSet]);
      });
    }
  }, [selectedPlayerId, selectedParam, startDate, endDate]);

  const getUniqueSortedLabels = (datasets: any[]) => {
    const allLabels = datasets?.flatMap((dataset) =>
      dataset?.data?.map((point: any) => point.x)
    );

    const uniqueLabels = Array.from(new Set(allLabels));
    uniqueLabels?.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

    return uniqueLabels;
  };

  const labels = getUniqueSortedLabels(allDatasets);

  const data = {
    labels: labels,
    // datasets: [],
    datasets: allDatasets.length > 0 ? allDatasets : [],
  };

  return (
    <Container>
      <ChartBox>
        <Line
          // @ts-ignore
          options={options}
          data={data}
          // ref={chartLineRef}
          // style={{
          //   display: chartType?.value === "line" ? "unset" : "none",
          //  // background: zoomLevel <= '70' ? 'red' : 'yellow',
          // }}
        />
      </ChartBox>

      {/* <div>
        <ScaleContainer ref={containerRef}>
          <Rnd
            size={{ width, height: "100%" }}
            position={{ x: left, y: 0 }}
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
            onDrag={handleDrag}
            onResize={handleResize}
            enableResizing={{
              left: true,
              right: true,
              top: false,
              bottom: false,
              topRight: false,
              topLeft: false,
              bottomRight: false,
              bottomLeft: false,
            }}
            // minWidth={
            //   (MIN_DURATION / totalDuration) *
            //     // @ts-ignore
            //     containerRef.current?.offsetWidth || 0
            // }
            bounds="parent"
          >
            <Slider />
          </Rnd>
        </ScaleContainer>
        <DatesContainer>
          <span>{startDate.toLocaleString()?.slice(11, 20)}</span>
          <span>{endDate.toLocaleString()?.slice(11, 20)}</span>
        </DatesContainer>
      </div> */}

      <div style={{ position: "relative", paddingBottom: "30px" }}>
        <ScaleContainer ref={containerRef}>
          <Rnd
            size={{ width, height: "100%" }}
            position={{ x: left, y: 0 }}
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
            onDrag={handleDrag}
            onResize={handleResize}
            enableResizing={{
              left: true,
              right: true,
              top: false,
              bottom: false,
              topRight: false,
              topLeft: false,
              bottomRight: false,
              bottomLeft: false,
            }}
            bounds="parent"
          >
            <Slider />
          </Rnd>
        </ScaleContainer>

        <span
          style={{
            position: "absolute",
            left: `${left}px`,
            top: "75%",
            transform: "translateX(-50%)",

            marginLeft: "40px",
            color: "#ff9226",
          }}
        >
          {startDate.toLocaleString()?.slice(11, 20)}
        </span>
        <span
          style={{
            position: "absolute",
            left: `${left + width}px`,
            top: "75%",
            transform: "translateX(-50%)",

            marginLeft: "50px",
            color: "#ff9226",
          }}
        >
          {endDate.toLocaleString()?.slice(11, 20)}
        </span>
      </div>
    </Container>
  );
};

export default EventStatistics;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChartBox = styled.div`
  width: 100%;
`;

const ScaleContainer = styled.div`
  position: relative;
  width: calc(100% - 100px);
  /* width: calc(100% - 20px); */
  height: 60px;
  background-color: rgb(2, 2, 15);
  /* background: url(${FakeLane}); */
  margin-left: 50px;

  & > div:nth-of-type(1) {
    & > div:nth-of-type(2) {
      & > div:nth-of-type(1) {
        background: url(${LeftArrowIco});
        left: -35px !important;
        width: 25px !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      & > div:nth-of-type(2) {
        background: url(${RightArrowIco});
        right: -35px !important;
        width: 25px !important;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
`;

const Slider = styled.div`
  height: 100%;
  border: 1px solid #ff9200;
  background: rgba(42, 55, 67, 1);
`;

const DatesContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 14px;
  padding: 0 50px;
`;
