import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #9cb0c4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="12.202"
        viewBox="1040 450.95 13 12.202"
      >
        <g data-name="Group 1925">
          <path
            d="M1052.354 453.071h-6.844a.646.646 0 0 1 0-1.292h6.844a.646.646 0 1 1 0 1.292Z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Path 1651"
          />
          <path
            d="M1052.354 457.767h-6.844a.646.646 0 0 1 0-1.293h6.844a.646.646 0 1 1 0 1.293Z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Path 1652"
          />
          <path
            d="M1052.354 462.238h-6.844a.646.646 0 0 1 0-1.293h6.844a.646.646 0 1 1 0 1.293Z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Path 1653"
          />
          <path
            d="M1040 461.581a1.52 1.52 0 1 1 0 .1z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Ellipse 11"
          />
          <path
            d="M1040 457.01a1.52 1.52 0 1 1 0 .1z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Ellipse 12"
          />
          <path
            d="M1040 452.421a1.52 1.52 0 1 1 0 .1z"
            fill="#02020f"
            fill-rule="evenodd"
            data-name="Ellipse 13"
          />
        </g>
      </svg>
    </Button>
  );
};

export default FilterButton;
