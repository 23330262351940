import React, { useState, useEffect } from "react";
import { Container } from "./style";
import SingleFile from "./SingleFile";
import useInfiniteScroll from "hooks/useInfiniteScroll";

import ContentContainer from "../layout/ContentContainer";
import FilesTopBar from "./FilesTopBar";
import {
  createDocument,
  createFolder,
  getAllFolders,
  getFolderDetail,
  updateDocumentName,
  updateFolderName,
} from "services/documentServices";
import SingleFolder from "./SingleFolder";
// @ts-ignore
import Modal from "react-modal";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import systemServices from "services/systemServices";
import { toast } from "react-toastify";

const Files: React.FC = () => {
  const [queryString, setQueryString] = useState("");
  const [fileCategories, setFileCategories] = useState([]);
  const [folders, setFolders] = useState([]);
  const [activeFolderId, setActiveFolderId] = useState("");
  const [editedFolderId, setEditedFolderId] = useState("");
  const [editedDocumentId, setEditedDocumentId] = useState("");
  const [activeFolder, setActiveFolder] = useState<any>({});
  const [showOptions, setShowOptions] = useState(false);
  const [isFolderAdded, setIsFolderAdded] = useState(false);
  const [isFolderEdited, setIsFolderEdited] = useState(false);
  const [isDocumentAdded, setIsDocumentAdded] = useState(false);
  const [isDocumentEdited, setIsDocumentEdited] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [editedFolderName, setEditedFolderName] = useState("");
  const [newDocumentName, setNewDocumentName] = useState("");
  const [editedDocumentName, setEditedDocumentName] = useState("");
  const [base64Data, setBase64Data] = useState("");

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const base64String: any = e.target.result;
        setBase64Data(base64String.split(",")[1]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleNewFolderNameChange = (e: any) => {
    setNewFolderName(e.target.value);
  };

  const handleEditedFolderNameChange = (e: any) => {
    setEditedFolderName(e.target.value);
  };

  const handleEditedDocumentNameChange = (e: any) => {
    setEditedDocumentName(e.target.value);
  };

  const handleNewDocumentNameChange = (e: any) => {
    setNewDocumentName(e.target.value);
  };

  const getAllFoldersFn = () => {
    getAllFolders()
      .then((data) => {
        setFolders(data.Data.Items);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllFoldersFn();
  }, []);

  const getFolderDetailFn = (activeFolderId: string) => {
    if (activeFolderId) {
      getFolderDetail(activeFolderId)
        .then((data) => {
          setActiveFolder(data.Data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const createFolderFn = (folderName: string) => {
    if (folderName) {
      createFolder(folderName)
        .then((data) => {
          getAllFoldersFn();
          setNewFolderName("");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const createDocumentFn = async (documentName: string, folderId: string) => {
    if (documentName && base64Data) {
      try {
        const fileUrl = await systemServices.uploadFile(base64Data);
        await createDocument(documentName, fileUrl, folderId);
        toast.success("Dodano dokument!");
        await getAllFoldersFn();
        getFolderDetailFn(activeFolderId);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateFolderNameFn = async (
    editedFolderName: string,
    folderId: string
  ) => {
    if (editedFolderName) {
      try {
        await updateFolderName(editedFolderName, folderId);
        getAllFoldersFn();

        setEditedFolderName("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const updateDocumentNameFn = async (
    editedDocumentName: string,
    folderId: string,
    documentId: string
  ) => {
    if (editedDocumentName) {
      try {
        await updateDocumentName(editedDocumentName, documentId, folderId);
        await getAllFoldersFn();
        getFolderDetailFn(activeFolderId);
        setEditedDocumentName("");
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (activeFolderId) {
      getFolderDetailFn(activeFolderId);
    }
  }, [activeFolderId]);

  const closeFolderModal = () => {
    setIsFolderAdded(false);
  };

  const closeEditedFolderModal = () => {
    setIsFolderEdited(false);
  };

  const closeEditedDocumentModal = () => {
    setIsDocumentEdited(false);
  };

  const closeDocumentModal = () => {
    setIsDocumentAdded(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      background: "rgba(0, 0, 0, 0.3)",
    },
  };
  return (
    <>
      <ContentContainer
        title="Pliki wewnętrzne"
        TopBar={
          <FilesTopBar
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            setIsFolderAdded={setIsFolderAdded}
            setIsDocumentAdded={setIsDocumentAdded}
            folderName={activeFolder?.Name}
            activeFolderId={activeFolderId}
          />
        }
      />

      <Container>
        <Modal
          isOpen={isFolderAdded}
          onRequestClose={closeFolderModal}
          style={customStyles}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <p style={{ color: "white" }}>Dodaj folder</p>
            <input
              type="text"
              placeholder="Nazwa folderu"
              value={newFolderName}
              onChange={handleNewFolderNameChange}
            />
            <Button
              onClick={() => createFolderFn(newFolderName)}
              className="px-24 xl:order-2"
              variant={ButtonVariant.Save}
              style={{
                width: "100%",
              }}
            >
              Zapisz
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={isFolderEdited}
          onRequestClose={closeEditedFolderModal}
          style={customStyles}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <p style={{ color: "white" }}>Edytuj folder</p>
            <input
              type="text"
              placeholder="Nazwa folderu"
              value={editedFolderName}
              onChange={handleEditedFolderNameChange}
            />
            <Button
              onClick={() =>
                updateFolderNameFn(editedFolderName, editedFolderId)
              }
              className="px-24 xl:order-2"
              variant={ButtonVariant.Save}
              style={{
                width: "100%",
              }}
            >
              Zapisz
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={isDocumentEdited}
          onRequestClose={closeEditedDocumentModal}
          style={customStyles}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <p style={{ color: "white" }}>Edytuj dokument</p>
            <input
              type="text"
              placeholder="Nazwa dokumentu"
              value={editedDocumentName}
              onChange={handleEditedDocumentNameChange}
            />
            <Button
              onClick={() =>
                updateDocumentNameFn(
                  editedDocumentName,
                  activeFolderId,
                  editedDocumentId
                )
              }
              className="px-24 xl:order-2"
              variant={ButtonVariant.Save}
              style={{
                width: "100%",
              }}
            >
              Zapisz
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={isDocumentAdded}
          onRequestClose={closeDocumentModal}
          style={customStyles}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              background: "transparent",
            }}
          >
            <p style={{ color: "white" }}>Dodaj dokument</p>
            <input
              type="file"
              onChange={handleFileChange}
              placeholder="dokument"
            />
            <input
              type="text"
              placeholder="Nazwa dokumentu"
              value={newDocumentName}
              onChange={handleNewDocumentNameChange}
            />
            <Button
              onClick={() => createDocumentFn(newDocumentName, activeFolderId)}
              className="px-24 xl:order-2"
              variant={ButtonVariant.Save}
              style={{
                width: "100%",
              }}
            >
              Zapisz
            </Button>
          </div>
        </Modal>
        <table>
          {/* <thead>
            <tr>
              <th>Nazwa</th>
              <th>Nazwa pliku</th>
              <th>Kategoria</th>
              <th></th>
              <th></th>
            </tr>
          </thead> */}
          <tbody>
            {folders.length > 0 &&
              folders.map((folder: any) => {
                const { FolderId, Name } = folder;
                return (
                  <SingleFolder
                    name={Name}
                    id={FolderId}
                    setActiveFolderId={setActiveFolderId}
                    activeFolderId={activeFolderId}
                    setActiveFolder={setActiveFolder}
                    getAllFoldersFn={getAllFoldersFn}
                    setEditedFolderId={setEditedFolderId}
                    setIsFolderEdited={setIsFolderEdited}
                    showOptions={showOptions}
                  />
                );
              })}

            {activeFolder &&
              activeFolder?.Documents?.map((document: any) => {
                const { DocumentFilePath, DocumentId, Name } = document;
                return (
                  <SingleFile
                    name={Name}
                    id={DocumentId}
                    filePath={DocumentFilePath}
                    getAllFoldersFn={getAllFoldersFn}
                    activeFolderId={activeFolderId}
                    getFolderDetailFn={getFolderDetailFn}
                    setEditedDocumentId={setEditedDocumentId}
                    setIsDocumentEdited={setIsDocumentEdited}
                    showOptions={showOptions}
                  />
                );
              })}

            {/* {files &&
              files.map((file) => {
                //@ts-ignore
                const { FileName, Name, FtpFilePath, FileCategoryId, Id } =
                  file;

                const filtered =
                  fileCategories &&
                  fileCategories.filter(
                    //@ts-ignore
                    (category) => category.Id === FileCategoryId,
                  );

                return (
                  <SingleFile
                    name={Name}
                    fileName={FileName}
                    //@ts-ignore
                    category={filtered[0] && filtered[0].Name}
                    ftpFilePath={FtpFilePath}
                    id={Id}
                  />
                );
              })} */}
          </tbody>
        </table>
      </Container>
    </>
  );
};

export default Files;
