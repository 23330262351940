import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import { productDetailTabs } from '../utils/helpers';
import {
  ProductDocuments,
  ProductSupplementaryData,
  ProductAttributes,
  ProductConditions,
  ProductStatisticts,
} from './tabs';
import { useSelector } from 'react-redux';

const SHOP_DETAIL_TABS: Record<string, string> = {
  "217": "Dane uzupełniające",
  "218": "Atrybuty",
  "219": "Warunki",
  "220": "Raport",
  "221": "Dokumenty",
}

const ProductDetailTabs = () => {

  const permission = useSelector((state: any) => state.permissions.permissions);

  const academyTabsWithPermissions = productDetailTabs.map((tab) => {
    return {
      ...tab,
      disabled: !permission.find(
        (permission: any) => SHOP_DETAIL_TABS[permission.Id] === tab.label
      ),
    };
  });

  const tabsWithPermissions = permission[0]?.Id === -1 ? productDetailTabs : academyTabsWithPermissions;

  return (
    <TabsView>
      <Tabs tabs={tabsWithPermissions} />
      <ProductSupplementaryData />
      <ProductDocuments />
      <ProductAttributes />
      <ProductConditions />
      <ProductStatisticts />
    </TabsView>
  );
};

export default ProductDetailTabs;
