import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "assets/images/login-logo.png";
import PoweredBy from "assets/images/poweredby-justwin.png";
import SubmitButton from "../common/buttons/submitButton/SubmitButton";
import TextField from "../common/inputs/textInput/TextField";
import { useState } from "react";
import { signIn } from "../../store/actions/auth";
import { loginInitValues, loginValidationSchema } from "./authHelpers";
import axios from "axios";
import styled from "styled-components";
import Visible from "../../assets/icons/visible-login.png";
import NotVisible from "../../assets/icons/not-visible-login.png";
import TextFieldLogin from "components/common/inputs/textInput/TextFieldLogin";
import { ButtonVariant } from "components/common/buttons/buttonTypes";

export type Values = {
  Email: string;
  Password: string;
};

const AuthForm: React.FC = () => {
  const dispatch = useDispatch();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const handleSubmit = async (values: Values) => {
    try {
      await dispatch(signIn(values));
      toast.success("Poprawnie zalogowano");
    } catch (errors: any) {
      toast.error("Nieprawidłowe dane logowania!");
      // toast.error(errors);
      // console.log(errors);
      toast.error(errors);
    }
  };

  localStorage.removeItem("benchmarkDateFrom");
  localStorage.removeItem("benchmarkDateTo");
  localStorage.removeItem("benchmarkTimeFrom");
  localStorage.removeItem("benchmarkTimeTo");
  localStorage.removeItem("dateTypeData");
  localStorage.removeItem("dateTypes");
  localStorage.removeItem("defaultSort");

  localStorage.removeItem("benchmarkTimeFromPlayer");
  localStorage.removeItem("benchmarkTimeToPlayer");
  localStorage.removeItem("benchmarkDateFromPlayer");
  localStorage.removeItem("benchmarkDateToPlayer");
  localStorage.removeItem("dateTypesPlayer");
  localStorage.removeItem("dateTypePlayer");

  return (
    <Container className="flex flex-1 xl:flex-2 flex-col md:justify-center lg:h-96 mt-8 text-center px-24">
      <img src={Logo} alt="logo" />
      <div
        className="text-xxl text-gray  mt-4 mb-8"
        style={{ color: "white", fontSize: "15px", textTransform: "uppercase" }}
      >
        Logowanie
      </div>
      <Formik
        initialValues={loginInitValues}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <fieldset
              className="flex flex-col gap-2 mx-auto max-w-sm"
              // disabled={isSubmitting}
            >
              <TextFieldLogin
                name="Email"
                label="Login"
                placeholder="Wprowadź"
                autoComplete="off"
              />

              <InputContainer>
                <TextFieldLogin
                  name="Password"
                  autoComplete="off"
                  label="Hasło"
                  placeholder="Wprowadź"
                  type={isPasswordShown ? "text" : "password"}
                />
                <img
                  onClick={() => setIsPasswordShown((prev) => !prev)}
                  src={isPasswordShown ? Visible : NotVisible}
                />
                {/* <div onClick={() => setIsPasswordShown((prev) => !prev)}>

                </div> */}
              </InputContainer>
            </fieldset>
            <SubmitButton
              className="mx-auto mt-6"
              isSubmitting={false}
              variant={ButtonVariant.Add}
            >
              Zaloguj
            </SubmitButton>
          </Form>
        )}
      </Formik>

      <div
        className="text-sm  mx-auto my-3 underline"
        style={{ color: "white" }}
      >
        <Link to="/remindPassword">Przypomnienie hasła</Link>
      </div>
      <img src={PoweredBy} alt="logo" />
    </Container>
  );
};

export default AuthForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img:nth-of-type(1) {
    width: 149px;
    height: 36px;
    margin-bottom: 20px;
  }

  & > img:nth-of-type(2) {
    width: 150px;
    height: 49px;
    margin-top: 20px;
  }

  & > form {
    & > button {
      width: 280px;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 280px;
  position: relative;

  & > div:nth-of-type(1) {
    width: calc(100%);
  }
  & > img {
    user-select: none;
    /* width: 30px; */
    /* height: 25px; */
    cursor: pointer;
    position: absolute;
    left: 50px;
    top: 9px;
  }
`;
