import { BlurBox, SideMenu } from "styles/styles";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IProductDetail,
  IUpdateProduct,
  PRODUCT_ACTIVATION_STATUS,
} from "types/marketpleace/product.interface";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { toast } from "react-toastify";
import { productServices } from "services/marketpleace";
import EditProduct from "../form/EditProduct";
import Switch from "react-switch";
import ProductStatus from "../components/ProductStatus";
import { useSelector } from "react-redux";

interface IProductDetailTopbarProps {
  product: IProductDetail | null;
  reload: () => Promise<void>;
}

const ProductDetailTopbar = ({
  product,
  reload,
}: IProductDetailTopbarProps) => {
  const { push } = useHistory();
  const [isEditMenuOpen, toggleEditMenuOpen] = useState(false);

  const [isStateChangeLoading, setStateChangeLoading] = useState(false);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAdmin = permission[0]?.Id === -1;

  const isEditActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 30);

  const isDeleteBtnActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 29);

  const removeProduct = async (productId: string) => {
    if (product) {
      try {
        await productServices.remove(productId);

        toast.success(`Pomyślnie usunięto ${product.Name}`);
        push("/shop/products");
      } catch (error) {
        console.error(error);
        toast.error(
          "Wystąpił nieoczekiwany problem. Spróbuj ponownie później.",
        );
      }
    }
  };

  const changeProductActive = async (id: string, checked: boolean) => {
    setStateChangeLoading(true);
    try {
      await productServices.changeState(
        id,
        checked
          ? PRODUCT_ACTIVATION_STATUS["Nieaktywny"]
          : PRODUCT_ACTIVATION_STATUS["Aktywny"],
      );

      await reload();
      setStateChangeLoading(false);
    } catch (error) {
      setStateChangeLoading(false);
    }
  };

  const productInitialEditValues: any | null = product
    ? {
        ProductId: product.ProductId,
        CategoryId: product.Category.CategoryId,
        BannerPhotoFilePath: product.BannerPhotoFilePath,
        Description: product.Description,
        Name: product.Name,
        ShortDescription: product.ShortDescription,
        Tag: product.Tag,
        Visibility: product?.Visibility,
      }
    : null;

  return (
    <div
      className="flex justify-between px-8 text-sm items-center"
      style={{ marginTop: "8px" }}
    >
      <BlurBox
        isMenuOpen={isEditMenuOpen}
        onClick={() => toggleEditMenuOpen(false)}
      />
      {productInitialEditValues && product ? (
        <SideMenu isMenuOpen={isEditMenuOpen}>
          <div>
            <EditProduct
              reload={reload}
              toggleEditMenuOpen={toggleEditMenuOpen}
              productInitialValues={productInitialEditValues}
              isEditMenuOpen={isEditMenuOpen}
              updatedProductType={product.Type}
              productId={product.ProductId}
            />
          </div>
        </SideMenu>
      ) : null}

      <div>
        {product ? <ProductStatus status={product.SystemStatus} /> : null}
      </div>

      <div className="flex gap-1">
        {product && (
          <div className="flex gap-2 mr-2 items-center text-white text-opacity-50 font-light">
            {PRODUCT_ACTIVATION_STATUS[product.Status]}
            <Switch
              checked={product.Status === PRODUCT_ACTIVATION_STATUS["Aktywny"]}
              onChange={(checked) =>
                changeProductActive(product.ProductId, !checked)
              }
              disabled={isStateChangeLoading}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
        )}

        <div
          onClick={() => {
            if (!isEditActive) {
              toast.error(
                "Nie masz uprawniń. Skontaktuj się z Administratorem",
              );
            }
          }}
        >
          <div
            style={{
              pointerEvents: isEditActive ? "auto" : "none",
              opacity: isEditActive ? "" : "0.4",
            }}
          >
            <Button
              disabled={!product}
              onClick={() => {
                toggleEditMenuOpen((prev) => !prev);
              }}
              variant={ButtonVariant.Edit}
              // className="flex-1 md:flex-grow-0 py-12 px-36 text-base"
            >
              Edytuj
            </Button>
          </div>
        </div>
        <div
          onClick={() => {
            if (!isDeleteBtnActive) {
              toast.error(
                "Nie masz uprawniń. Skontaktuj się z Administratorem",
              );
            }
          }}
        >
          <div
            style={{
              pointerEvents: isDeleteBtnActive ? "auto" : "none",
              opacity: isDeleteBtnActive ? "" : "0.4",
            }}
          >
            <Button
              disabled={!product}
              variant={ButtonVariant.Delete}
              // className="flex-1 md:flex-grow-0 py-12 px-36 text-base"
              onClick={() => product && removeProduct(product.ProductId)}
            >
              Usuń
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailTopbar;
