import { ISelectOption } from 'components/common/inputs/inputTypes';
import React from 'react';
import ScannerTrainerForm from '../../trainers/outside/ScannerTrainerForm';
import { IFilterPlayerValue } from 'types/filterPassData';
import NewFilterPanel from "components/filters/NewFilterPanel";

import styled from "styled-components";

interface IScannerSideMenuProps {
  // refreshScanner: () => Promise<void>;
  // refreshFilters: () => Promise<void>;
  // filter: ISelectOption<string> | null;
  // setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  // filters: ISelectOption<string>[];
  // handleFilterData?: (obj: IFilterPlayerValue) => void;
  // isOpen: boolean;
  filterArray: any;
}

const ScannerSideMenuTrainer = ({
  // refreshScanner,
  // refreshFilters,
  // filter,
  // filters,
  // setFilter,
  // handleFilterData,
  // isOpen,
  filterArray
}: any) => {

  // const filterst = [

  //   {
  //     id: 1,
  //     type: "select",
  //     name: "LicenseType",
  //     values: [
  //       { backend: 0, pl: "UEFA" },
  //       { backend: 1, pl: "UEFA_PRO" },
  //     ],
  //     pl: "Typ licencji",
  //   },
  //   {
  //     id: 2,
  //     type: "select",
  //     name: "TrainerType",
  //     values: [
  //       { backend: 0, pl: "Trener" },
  //       { backend: 1, pl: "Scout" },
  //       { backend: 2, pl: "Manager" },
  //       { backend: 3, pl: "Analityk" },

  //     ],
  //     pl: "Typ profilu",
  //   },

  //   {
  //     id: 3,
  //     type: "rangeOfAmount",
  //     name: "Area",
  //     pl: "Promień",
  //   },
  // ];


  return (
    <div /* className='flex  flex-col h-full gap-6 items-center' */>


      <SideMenu isMenuOpen={true}>
        <NewFilterPanel filters={filterArray} />
      </SideMenu>
      {/* <ScannerTrainerForm
         refreshScanner={refreshScanner}
         refreshFilters={refreshFilters}
         filter={filter}
         setFilter={setFilter}
         filters={filters}
         handleFilterData={handleFilterData}
      /> */}
    </div>
  );
};

export default ScannerSideMenuTrainer;


const SideMenu = styled.div<{ isMenuOpen: boolean }>`

  position: relative;
   height: calc(100vh - 78px);
  // top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: rgba(2, 2, 15, 1);
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  overflow-y: hidden,

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;
