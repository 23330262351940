import ContentContainer from 'components/layout/ContentContainer';
import React, { useContext, useState } from 'react';
import OrdersTopbar from './OrdersTopbar';
import OrdersTable from './OrdersTable';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useDebounce } from 'use-debounce';
import { ISelectOption } from 'types/globalTypes';
import { getOrderSorts } from './utils/helpers';
import { IOrder, ORDER_SORT_LIST } from 'types/marketpleace/order.interface';
import { DefaultSortContext } from 'contexts/defaultSortContext';
import orderServices from 'services/marketpleace/orderServices';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Orders = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const [sortBy, setSortBy] =
    useState<ISelectOption<ORDER_SORT_LIST> | null>(null);
  const [queryString, setQueryString] = useState('');
  const [debounceSearchInput] = useDebounce(queryString.trim(), 500);

  const {
    items: sellerOrders,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<IOrder>(orderServices.getAll, debounceSearchInput);

  const handleQueryChange = (query: string) => {
    if (!query.length || query.length > 2) {
      setQueryString(query);
    }
  };

  const isAdmin = permission[0]?.Id === -1;

  const isPermissionActive = permission.some((tab: any) => tab.Id === 141);

  const isDeliveryDetailActive = permission.find((tab: any) => tab.Id === 239);

  const detailPassPermission = isAdmin ? true : isDeliveryDetailActive;

  return (
    <ContentContainer
      title={'Zamówienia'}
      TopBar={
        <OrdersTopbar
          sortBy={sortBy}
          setSortBy={setSortBy}
          defaultSort={defaultSort}
          sorts={getOrderSorts}
          handleQueryChange={handleQueryChange}
        />
      }>
      <div
        onClick={() => {
          if (!isDeliveryDetailActive && !isAdmin) {
            toast.error("Nie masz uprawnień. Skontaktuj się z Administratorem")
          }
        }}
      >
        <OrdersTable
          containerRef={containerRef}
          data={sellerOrders}
          isDataLoading={loading}
          lastItemRef={lastItemRef}
          sortBy={sortBy}
          detailPassPermission={detailPassPermission}
        />
      </div>
    </ContentContainer>
  );
};

export default Orders;
