import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #00e5ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.138"
        height="15"
        viewBox="821.5 448.999 15.138 15"
      >
        <path
          d="m835.894 455.82-6.256.136.11-6.204c0-.375-.305-.753-.679-.753-.374 0-.678.378-.678.753l.109 6.204-6.256-.135c-.374 0-.744.303-.744.678 0 .374.37.678.744.678l6.256-.134-.109 6.203c0 .375.304.753.678.753.374 0 .678-.378.678-.753l-.109-6.203 6.256.134c.374 0 .744-.304.744-.678 0-.375-.37-.678-.744-.678Z"
          fill="#02020f"
          fill-rule="evenodd"
          data-name="Path 3683"
        />
      </svg>
    </Button>
  );
};

export default AddButton;
