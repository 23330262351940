import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ContentContainer from "components/layout/ContentContainer";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import TrainersList from "./tiles/DigitalReleaseList";
import TrainersTable from "./TrainersTable";
import PlayersTopbar from "./TrainersTopbar";
import { useTranslation } from "react-i18next";
import { DefaultSortContext } from "contexts/defaultSortContext";
import eventsServices from "services/eventsServices";
import {
  getAllAcademyTrainers,
  getAllAcademyTrainersRef,
} from "services/transferServices";
import { toast } from "react-toastify";

const Trainers: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [viewType, setViewType] = useState("list");
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);
  const { t } = useTranslation();
  const viewMode = useSelector(
    (state: RootState) => state.ui.dataViewModes.digitalRelease,
  );

  const [queryString, setQueryString] = useState("");
  // const {
  //   items: events,
  //   loading,
  //   containerRef,
  //   lastItemRef,
  // } = useInfiniteScroll<any>(
  //   // @ts-ignore
  //   eventsServices.getAllEvents,
  //   queryString,
  // );

  const {
    items: players,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    getAllAcademyTrainersRef,
    queryString,
  );

  const getAllSorts = async () => {
    try {
      setSorts([
        {
          label: "Najnowsze",
          value: 0,
        },
        {
          label: "Najstarsze",
          value: 1,
        },
        {
          label: "Nazwa (A-Z)",
          value: 2,
        },
        {
          label: "Nazwa (Z-A)",
          value: 3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSorts();
  }, []);

  useEffect(() => {
    if (sortBy) {
      // @ts-ignore
      setDefaultSort(sortBy.value);
    }
  }, [sortBy]);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
  };

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isTrainerDetailPermission = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 137);

  const isTrainerListFilerActive = permission[0]?.Id === -1 ? true : !!permission.find((permission: any) => permission.Id === 228);

  return (
    <ContentContainer
      title={"Trenerzy"}
      TopBar={
        <PlayersTopbar
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          handleQueryChange={handleQueryChange}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
          isTrainerListFilerActive={isTrainerListFilerActive}
        />
      }
    >
      {viewType === "list" ? (
        <div
          onClick={() => {
            if (!isTrainerDetailPermission) {
              toast.error("Nie masz dostępu. Skontaktuj sie z Administratorem");
            }
          }}
        >
          <div
            style={{
              pointerEvents: isTrainerDetailPermission ? "auto" : "none",
            }}
          >
            <TrainersTable
              players={players}
              containerRef={containerRef}
              lastItemRef={lastItemRef}
              isDataLoading={loading}
              sortBy={sortBy}
            />
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            if (!isTrainerDetailPermission) {
              toast.error("Nie masz dostępu. Skontaktuj sie z Administratorem");
            }
          }}
        >
          <div
            style={{
              pointerEvents: isTrainerDetailPermission ? "auto" : "none",
            }}
          >
            <TrainersList
              sortBy={sortBy}
              players={players}
              containerRef={containerRef}
              lastItemRef={lastItemRef}
              isDataLoading={loading}
            />
          </div>
        </div>
      )}
    </ContentContainer>
  );
};

export default Trainers;
