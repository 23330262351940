import { ChangeEvent } from 'react';
import { SearchBox, SearchInput } from 'styles/styles';
import HomeSearchIco from '../../../assets/icons/home-search-ico.png';

interface ICategoriesTopbarProps {
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchQueryValue: string;
}

const CategoriesTopbar = ({
  handleSearch,
  searchQueryValue,
}: ICategoriesTopbarProps) => {
  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2 pr-24"
      style={{ gap: '3px' }}
    >
      <div>
        <div
          style={{ height: '48px' }}
          className="flex justify-end items-center"
        ></div>
        <div>
          <SearchBox>
            <SearchInput
              className="w-full md:w-36 xl:w-72"
              placeholder={'Szukaj...'}
              autoComplete="off"
              name="search"
              onChange={handleSearch}
              value={searchQueryValue}
            />
            <div>
              <img src={HomeSearchIco} alt="ico" />
            </div>
          </SearchBox>
        </div>
      </div>
    </div>
  );
};

export default CategoriesTopbar;
