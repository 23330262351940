import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #9cb0c4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.626"
        height="10.286"
        viewBox="4661 2119.213 11.626 10.286"
      >
        <g data-name="Multiple Layers (2)">
          <path
            d="M4667.22 2129.498h5.407"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="#02020f"
            fill="transparent"
            data-name="Path 21508"
          />
          <path
            d="M4669.11 2119.586a1.274 1.274 0 0 1 1.802 1.802l-7.509 7.51-2.403.6.6-2.403 7.51-7.51Z"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="#02020f"
            fill="transparent"
            data-name="Path 21509"
          />
        </g>
      </svg>
    </Button>
  );
};

export default EditButton;
