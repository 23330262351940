import Tabs from "components/common/tabs/Tabs";
import TabsView from "components/common/tabs/TabsView";
import { getTabsByStrictLevel, getDetailTabsByEnum } from "utils/objectUtils";
import PlayerAddress from "./Address/PlayerAddress";
import PlayerPasses from "./Passes/PlayerPasses";
import { IPlayerDetail, TRANSFER_LEVEL } from "types/transfer/player.interface";
import PlayerCard from "./Card/PlayerCard";
import PlayerEvents from "./Events/PlayerEvents";
import PlayerManager from "./Manager/PlayerManager";
import PlayerStatistics from "./Statistics/PlayerStatistics";
import PlayerCareer from "./Career/PlayerCareer";
import { playerDetailStrictTabs } from "./PlayerDetailStrictTabs";
import PlayerHistoryState from "./HistoryState/PlayerHistoryState";
import StatisticsTab from "components/shop/Orders/detail/Tabs/PressMaterials/StatisticsTab";
import playerService from 'services/transfer/playerServices';
import { useState, useEffect } from "react";
import {
  IPlayerEvent,
} from 'types/events/event.interface';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  IPlayerCard,
} from 'types/transfer/player.interface';
import { IAddress, IHistoryStatus } from 'types/address.interface';

interface IPlayerDetailTabsProps {
  player: IPlayerDetail;
  refresh: () => Promise<void>;
  playerAddress: IAddress | null;
  playerCard: IPlayerCard,
  historyStatus: any | null;
  trainerTab: any | null;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor: string[];
    borderColor: string;
  }[];
  height: string;
  weight: string;
  condition: string;
  distance: number;
  dynamics: number;
  energy?: string | null | undefined;
  form: number;
  heartRate: number;
  hmld: number;
  hsr: number;
  speed: number;
}

export enum PLAYER_TABS {
  address = "Adres",
  playerCard = "Karta zawodnika",
  trainner = "Trener",
  career = "Kariera",
  statistics = "Statystyki",
  passes = "Składki",
  events = "Zajęcia",
  historyState = "Historia statusów",
}

const PLAYER_PERMISSIONS: Record<string, string> = {
  "147": "Adres",
  "148": "Karta zawodnika",
  "149": "Trener",
  "150": "Kariera",
  "151": "Statystyki",
  "152": "Składki",
  "146": "Zajęcia",
  "153": "Historia statusów",
};

const GROUP_PERMISSIONS: Record<string, string> = {
  "183": "Zajęcia",
  "184": "Adres",
  "185": "Karta zawodnika",
  "186": "Trener",
  "187": "Kariera",
  "188": "Statystyki",
  "189": "Składki",
  "190": "Historia statusów",
}

const PlayerDetailTabs = ({ player, refresh, playerAddress, playerCard,historyStatus, trainerTab }: IPlayerDetailTabsProps) => {
  const tabs = getTabsByStrictLevel<TRANSFER_LEVEL | null>(
    playerDetailStrictTabs,
    player.Level,
  );
  const [lineData, setLineData] = useState<ChartData | null>(null);
  const [traingsDate, setTrainingsDate] = useState<string[]>([]);
  const [eventsData, setEventsData] = useState<IPlayerEvent[]>([]);
  const [isLoading, setLoading] = useState(true);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const location = useLocation();

  const getTrainingsData = (value: string[]) => {
    setTrainingsDate(value);
  }

  const getEvents = async () => {
    const { Items } = await playerService.getEvents(player.PlayerId);

    setEventsData(Items);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const playerTabs = getDetailTabsByEnum(PLAYER_TABS);

  const isPlayerOrGroupeActive = (id: number) => permission?.some((trainer: any) => trainer.Id === id);

  const isPlayerDetailInStructure = isPlayerOrGroupeActive(223) && location.pathname === '/structure'

  const playerTabsWithPermissions = playerTabs.map((tab) => {
    const permissionFound = permission.find((perm: { Id: number }) => {
      if (isPlayerDetailInStructure) {
        return GROUP_PERMISSIONS[perm.Id] === tab.label;
      } else {
         return PLAYER_PERMISSIONS[perm.Id] === tab.label;
      }
    });

    return {
      ...tab,
      disabled: !permissionFound,
    };
  });

  const tabsPlayer = permission[0]?.Id === -1 ? playerTabs : playerTabsWithPermissions;

  return (
    <TabsView>
      <Tabs tabs={tabsPlayer} />
      <div className=" gap-4 p-4 md:p-24 text-white">
        <PlayerAddress address={playerAddress} />
        <PlayerPasses player={player} refresh={refresh} />
        <PlayerCard card={playerCard} />
        <PlayerEvents
          eventsData={eventsData}
          isLoading={isLoading}
        />
        <PlayerManager
          designateTrainers={trainerTab?.DesignateTrainers}
          refresh={refresh}
        />
        {/* <PlayerStatistics userStats={lineData}/> */}
        <StatisticsTab
          playerProfile={lineData}
          ownerId={player.PlayerId}
          getTrainingsData={getTrainingsData}
        />
        <PlayerCareer
          PlayerId={player?.PlayerId}
          PlayerCardId={playerCard?.PlayerCardId}
        />
        <PlayerHistoryState player={player} refresh={refresh} historyStatus={historyStatus}/>
      </div>
    </TabsView>
  );
};

export default PlayerDetailTabs;
