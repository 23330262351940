 export const getLocalStorageValue = (key: string, defaultValue: any) => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Błąd z localStorage: ${key}: `, error);
      return defaultValue;
    }
  };

  export const setLocalStorageValue = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };