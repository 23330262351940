import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import UserStatusInfo from "components/users/status/UserStatusInfo";
import { AllConversationsContext } from "contexts/allConversationsContext";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import chatServices from "services/chatServices";
import { IAppliedPass } from "types/transfer/player.interface";
import { useHistory, useParams, useLocation } from "react-router-dom";
import playerService from "services/transfer/playerServices";
import { useSelector } from "react-redux";
interface IPlayerDetailTopbarProps {
  appliedPasses: IAppliedPass[];
  playerId?: string;
}

const PlayerDetailTopbar = ({
  appliedPasses,
  playerId,
}: IPlayerDetailTopbarProps) => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const pathname = location.pathname;

  const permission = useSelector((state: any) => state.permissions.permissions);

  const [
    allConversations,
    setAllConversations,
    filter,
    setFilter,
    searchQuery,
    setSearchQuery,
    conversation,
    setConversation,
    conversationMembers,
    setConversationMembers,
    activeConversationImageUrl,
    setActiveConversationImageUrl,
    activeConversationName,
    setActiveConversationName,
    onlineProfiles,
    setOnlineProfiles,
    activeConversationId,
    setActiveConversationId,
    activeConversationType,
    setActiveConversationType,
    tempMemberId,
    setTempMemberId,
    unreadMessagesCounter,
    setUnreadMessagesCounter,
    allInterlocutors,
    setAllInterlocutors,
    auxFolderName,
    setAuxFolderName,
    activeMemberType,
    setActiveMemberType,
  ] = useContext(AllConversationsContext);

  const createConversationFn = async () => {
    const resp2: any = await chatServices.getPrivateConversationById(
      playerId ? playerId : id,
      1,
    );
    console.log("RESP2>>>", resp2);
    const detail = await playerService.getDetail(playerId ? playerId : id);

    console.log("Playerdeatails", detail);

    if (resp2 === null) {
      console.log("XAXAAX", detail);
      // await chatServices.createNewConversation(playerId? playerId :id, 1);

      setActiveConversationId("");
      setActiveConversationImageUrl(detail.PhotoFilePath);
      setActiveConversationName(detail.Name);
      setActiveConversationType(1);
      setActiveMemberType(1);

      setConversation([]);
      setTempMemberId(playerId ? playerId : id);

      // await toast.success("Stworzono konwersacje!");
      await push("/chat");
    } else {
      await setActiveConversationId(resp2?.ConversationId);

      await push("/chat");
    }
  };

  const isElemHasPermission =
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === 191) &&
        pathname.includes("/structure");

  return (
    <div className="flex justify-end text-sm   w-full items-center pr-24">
      <div className="flex flex-col items-center ">
        <div
          style={{
            height: "48px",
          }}
          className="flex items-center"
          onClick={() => {
            if (!isElemHasPermission) {
              toast.error(
                "Nie masz uprawnień. Skontaktuj się z Administratorem",
              );
            }
          }}
        >
          <div
            onClick={() => {
              if (!isElemHasPermission) {
                toast.error(
                  "Nie masz uprawnień. Skontaktuj się z Administratorem",
                );
              }
            }}
          >
            <div
              style={{
                pointerEvents: isElemHasPermission ? "auto" : "none",
                opacity: isElemHasPermission ? "" : "0.6",
                width: "100%",
              }}
            >
              <Button
                variant={ButtonVariant.Add}
                onClick={() => createConversationFn()}
              >
                Wyślij wiadomość
              </Button>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            height: "48px",
          }}
        ></div> */}
      </div>
    </div>
  );
};

export default PlayerDetailTopbar;
