import { useState } from "react";
import NavbarItem from "./NavbarItem";
import NavbarMobileList from "./NavbarMobileList";
import { INavbarItemListProps } from "./navbarTypes";
import { navLinks } from "./navbarUtils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "store/store";

const navlinksPermissionsHelper = [
  { label: "Moja akademia", Id: 130, permission: false },
  { label: "Home", Id: 999, permission: true },
  { label: "Zajęcia", Id: 131, permission: false },
  { label: "Zawodnicy", Id: 133, permission: false },
  { label: "Radar zawodników", Id: 135, permission: false },
  { label: "Trenerzy", Id: 136, permission: false },
  { label: "Radar trenerów", Id: 138, permission: false },
  { label: "Grupy", Id: 139, permission: false },
  { label: "Sklep", Id: 140, permission: false },
  { label: "Kontakt", Id: 211, permission: false },
  { label: "Pliki wewnętrzne", Id: 213, permission: false },
  { label: "Zarządzanie", Id: -999, permission: true },
];

const NavbarItemList: React.FC<INavbarItemListProps> = ({ isExpanded }) => {
  const permissions = useSelector(
    (state: any) => state.permissions.permissions,
  );
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);

  const handleExpand = (id: number) => {
    setExpandedItemId((prev) => (prev === id ? null : id));
  };
  const academyMerchantStatus = useSelector(
    (state: RootState) => state?.academyMerchantStatusReducer.value,
  );

  const navItemsWithPermissions = navLinks.map((item: any) => {
    const permissionEntry = navlinksPermissionsHelper.find(
      (nav) => nav.label === item.label,
    );

    if (item.label === "Sklep" && !academyMerchantStatus) {
      return {
        ...item,
        permission: academyMerchantStatus
          ? permissions.some((perm: any) => perm.Id === 140)
          : false,
      };
    }

    const hasPermission =
      item.label === "Home" ||
      item.label === "Zarządzanie" ||
      (permissionEntry
        ? permissions.some((perm: any) => perm.Id === permissionEntry.Id)
        : false);

    return {
      ...item,
      permission: hasPermission,
    };
  });

  const handleUserInfo = () => {
    toast.error("Nie masz uprawnień. Skontakutuj się z Administratorem");
  };

  const updatedNavLinks = navLinks.map((item: any) => {
    if (item.label === "Sklep") {
      return {
        ...item,
        permission: academyMerchantStatus,
      };
    }
    const permissionEntry = navlinksPermissionsHelper.find(
      (nav) => nav.label === item.label,
    );

    const hasPermission = permissionEntry
      ? permissions.some((perm: any) => perm.Id !== permissionEntry.Id)
      : true;

    return {
      ...item,
      permission: hasPermission,
    };
  });

  const navItemPermission =
    permissions[0]?.Id === -1 ? updatedNavLinks : navItemsWithPermissions;

  return (
    <div>
      <div
        className="hidden md:block relative overflow-y-auto"
        style={{
          maxHeight: "70vh",
          // borderBottom: "1px solid white",
          padding: "0 8px 10px 8px",

          boxSizing: "border-box",
          width: "calc(100% + 16px)",
          marginLeft: "-8px",
          borderBottom: "1px solid",
          borderImage: "linear-gradient(to right, #b6b4b4, rgb(15, 15, 14)) 1%",
        }}
      >
        {navItemPermission.map((item, index) => {
          return (
            <div
              onClick={() => {
                if (item.permission !== undefined && !item.permission) {
                  handleUserInfo();
                }
              }}
              style={{
                // opacity: !item.permission ? '0.4' : '',
                opacity:
                  item.permission !== undefined
                    ? !item.permission
                      ? "0.3"
                      : ""
                    : "1",
              }}
              key={item.label}
            >
              <div
                style={{
                  // pointerEvents: !item.permission ? 'none' : 'auto'
                  pointerEvents:
                    item.permission !== undefined
                      ? !item.permission
                        ? "none"
                        : "auto"
                      : "auto",
                }}
              >
                <NavbarItem
                  {...item}
                  key={index}
                  isExpanded={expandedItemId === index}
                  onExpand={() => handleExpand(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <NavbarMobileList isExpanded={isExpanded} />
      </div>
    </div>
  );
};

export default NavbarItemList;
