import HeaderContainer from "components/common/Containers/HeaderContainer";
import NewImageInput from "components/common/inputs/imageInput/NewImageInput";
import React, { useEffect, useRef, useState } from "react";
import logo from "assets/images/logo.svg";
import systemServices from "services/systemServices";
import { showServerErrors } from "utils/errorsUtils";
import { usePrimaryDataFormContext } from "../usePrimaryDataFormContext";
import { convertImageToOptimizedBase64 } from "utils/fileUtils";
import { toast } from "react-toastify";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";

const AcademyPhoto = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();
  const [previewSrc, setPreviewSrc] = useState("");
  const [base64, setBase64] = useState<string>("");
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const filePath = form.primaryData.PhotoFilePath && form.primaryData.PhotoFilePath.length
  ? form.primaryData.PhotoFilePath
  : logo;


  const onBaseChange = async () => {
    try {
      if (!base64) {
        return;
      }

      const fileUrl = await systemServices.uploadFile(base64);

      setForm((prev) => ({
        ...prev,
        primaryData: { ...prev.primaryData, PhotoFilePath: fileUrl },
      }));
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  useEffect(() => {
    onBaseChange();
  }, [base64]);

  const handleFileChange = async (e: any) => {
    const { files, name } = e.target;

    if (files?.length) {
      try {
        const file = files[0];
        const { base64String } = await convertImageToOptimizedBase64(file);
        const imgPath = URL.createObjectURL(file);
        setBase64(base64String);
        setPreviewSrc(imgPath);
      } catch (error: any) {
        toast.error(`Image upload error: ${error}`);
      }
    }
  };

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <HeaderContainer
      title="Logo"
      withContainer
      headerContent={
        <div>
          {editEnable && (
            <label
              htmlFor={"academyPhoto"}
              ref={labelRef}
              className=" relative overflow-hidden w-full h-full"
            >
              <Button variant={ButtonVariant.Add} onClick={handleButtonClick}>
                Zmień logo
              </Button>
              <input
                type="file"
                name={"academyPhoto"}
                ref={inputFileRef}
                id={"academyPhoto"}
                style={{ cursor: "pointer", pointerEvents: "none" }}
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={handleFileChange}
              />
            </label>
          )}
        </div>
      }
    >
      <div className="flex w-full justify-center" style={{ height: "155px" }}>
        <img
          className="object-cover w-36 h-36"
          src={filePath}
          alt=""
          loading="lazy"
        />
      </div>
    </HeaderContainer>
  );
};

export default AcademyPhoto;
