import { conn } from 'api/BaseConnection';
import { IInpostPayload } from 'types/inpost.interface';
import api from "./axiosConfig";

const endpoint = conn.endpoints.inpost;

  export const generateInpostLabel = async (
   order: IInpostPayload
  ) => {

    return await conn.postJSON(endpoint.addLabel, 'json',
      order
    );
  };

  export const getInpostLabel = async (id: string) => {
    try {
      const response = await api.get(`/api/InPost/get-label/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
