import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";

const Button = styled.button`
  width: 33px;
   height: 33px;
  /* background: rgba(0, 145, 255, 0.7); */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
border: 1px solid #9CB0C4;
  & > i {
    & > svg {
      fill: #9CB0C4;
    }
  }
`;

const BackButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {

  return (
    <Button
      {...props}
       className='rounded-sm'
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      {children}
    </Button>
  );
};

export default BackButton;
