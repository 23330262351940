import { useState, useEffect } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { RootState } from "store/store";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/logo.svg";
import SubmitButton from "components/common/buttons/submitButton/SubmitButton";
import TextField from "components/common/inputs/textInput/TextField";
import Map from "../Map";
import { validationMessage } from "utils/validation";
import { ISelectOption } from "components/common/inputs/inputTypes";
// @ts-ignore
import Modal from "react-modal";
import TextInput from "components/common/inputs/textInput/TextInput";
import styled from "styled-components";
import NewImageField from "components/common/inputs/imageInput/NewImageField";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import MaskedField from "components/common/inputs/maskedInput/MaskedField";
import { Mask } from "utils/constants/constants";
import eventsServices from "services/eventsServices";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { PlayerProfileInterface } from "types/userTypes";
import FolderIco from "assets/images/column-folder.png";
import axios from "axios";
import {
  getAllAcademyPlayers,
  getAllAcademyPlayersQuery,
} from "services/transferServices";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import ProductTextAreaField from "components/common/inputs/textArea/ProductTextAreaField";
import TextAreaField from "components/common/inputs/textArea/TextAreaField";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import {
  GetOwnFolders,
  getFolderItemsByFolderId,
} from "services/folderServices";
import cyclicalEventServices from "services/cyclicalEventServices";
import favoriteLocationServices from "services/favoriteLocationServices";
import BlockedDateTextInput from "components/common/inputs/textInput/BlockedDateTextInput";
import BackButton from "components/common/buttons/basicButton/BackButton";

const SingleColumn = styled.div`
  width: 33%;
  display: flex;
  /* height: 100%; */
  flex-direction: column;

  & > p {
    /* text-align: center; */
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;

// const renderFolder = (folder: any, depth = 0) => (
//   <div
//     style={{
//       display: "flex",
//       flexDirection: "column",
//       gap: "5px",
//       marginLeft: `${depth * 20}px`,
//     }}
//   >
//     <SingleFolder>
//       <p id={folder.FolderId}>{folder.Name}</p>
//     </SingleFolder>
//     {folder.subFolder &&
//       folder.subFolder.map((subFolder: any) =>
//         renderFolder(subFolder, depth + 1),
//       )}
//   </div>
// );

const Folder = ({ folder }: any) => {
  return (
    <div
      style={{
        width: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        background: "rgba(255,0,0,0.5)",
        cursor: "pointer",
        padding: "10px",
        marginBottom: "5px",
      }}
    >
      <p>{folder.Name}</p>
    </div>
  );
};

const FolderTree = ({ folder }: any) => {
  return (
    <div style={{ paddingLeft: "20px" }}>
      {folder.Children &&
        Object.values(folder.Children).map((child: any) => (
          <Folder key={child.FolderId} folder={child} />
        ))}
    </div>
  );
};
const SingleRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 280px);
  column-gap: 5px;
`;

interface IEditDigitalReleaseProps {
  product: any;
  isEdit: boolean;
  onSubmit: any;
  onCyclicalSubmit: any;
  isAddMenuOpen: any;
  isStructure?: boolean;
  clickedFolderPlayers?: any;
  toggleAddMenuOpen?: any;
}

function MyComponent({ location, setLatitude, setLongitude }: any) {
  const { values, setFieldValue } = useFormikContext<any>();

  const getTest = async () => {
    try {
      // const resp = await axios.get(
      //   `https://adminapi.justwin.pl/Localization?PostCode=${postCode}`,
      // );

      // {location && (
      //   <div>
      //     <h2>Selected Location:</h2>
      //     {/* @ts-ignore */}
      //     <p>Postal Code: {location.postalCode}</p>
      //     {/* @ts-ignore */}
      //     <p>City: {location.city}</p>
      //     {/* @ts-ignore */}
      //     <p>Street: {location.street}</p>
      //     {/* @ts-ignore */}
      //     <p>Latitude: {location.position.lat}</p>
      //     {/* @ts-ignore */}
      //     <p>Longitude: {location.position.lng}</p>
      //   </div>
      // )}

      if (location) {
        // setFieldValue("PostCode", location.postalCode);
        setFieldValue("City", location.city);
        setFieldValue("country", location.country);
        setFieldValue("PostCode", location.postalCode);
        setFieldValue(
          "Localization",
          location?.streetNumber
            ? `${location.street} ${location.street} ${location.streetNumber}`
            : location.street,
        );

        setLatitude(location?.position?.lat);
        setLongitude(location?.position?.lng);
        // if (location.street) {
        //   setFieldValue(
        //     "Address",
        //     `${location.street} ${location.streetNumber}`,
        //   );
        // } else {
        //   setFieldValue("Address", "");
        // }
      } else {
        // setFieldValue("PostCode", "");
        setFieldValue("City", "");
        setFieldValue("country", "");
        setFieldValue("PostCode", "");
        setFieldValue("Localization", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location) {
      getTest();
    } else {
      setFieldValue("City", "");
      setFieldValue("Address", "");
    }
  }, [location]);

  return <></>;
}

// @ts-ignore
const DigitalReleaseForm: React.FC<IEditDigitalReleaseProps> = ({
  product,
  isEdit,
  onSubmit,
  onCyclicalSubmit,
  isAddMenuOpen,
  isStructure,
  clickedFolderPlayers,
  toggleAddMenuOpen,
}) => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const { replace } = useHistory();
  const [selectedType, setSelectedType] = useState<ISelectOption | null>(null);
  const [selectedVat, setSelectedVat] = useState<ISelectOption | null>(null);
  const [availableFrom, setAvailableFrom] = useState("");
  const [availableTo, setAvailableTo] = useState("");
  const [localizationType, setLocalizationType] = useState(1);
  const [playersInFolder, setPlayersInFolder] = useState([]);
  const [startEventRange, setStartEventRange] = useState();
  const [endEventRange, setEndEventRange] = useState();
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [timeFrom, setTimeFrom] = useState<any>();
  const [timeTo, setTimeTo] = useState<any>();
  const [base64, setBase64] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [gross, setGross] = useState(0);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [allPlayerProfiles, setAllPlayerProfiles] = useState([]);

  const [visibility, setVisibility] =
    useState<{ value: number; label: string } | null>(null);
  const [visibilities, setVisibilities] = useState<
    { value: number; label: string }[]
  >([]);

  const [organizerType, setOrganizerType] =
    useState<{ value: number; label: string } | null>(null);
  const [organizerTypes, setOrganizerTypes] = useState<
    { value: number; label: string }[]
  >([]);
  const [favoriteLocationData, setFavoriteLocationData] = useState<any>({});
  const closeGroupModalOpen = () => {
    setIsGroupModalOpen(false);
  };
  const closeMapModalOpen = () => {
    setIsMapModalOpen(false);
  };

  const closeAddressModalOpen = () => {
    setIsAddressModalOpen(false);
  };
  const [newLocalizationName, setNewLocalizationName] = useState("");
  const [repetitationInterval, setRepetitationInterval] =
    useState<{ value: number; label: string } | null>(null);
  const [repetitationIntervals, setRepetitationIntervals] = useState<
    { value: number; label: string }[]
  >([]);

  const [academyFolder, setAcademyFolder] =
    useState<{ value: number; label: string } | null>(null);
  const [academyFolders, setAcademyFolders] = useState<
    { value: number; label: string }[]
  >([]);
  const [ownFolders, setOwnFolders] = useState<any>([]);
  const [folderTrees, setFolderTrees] = useState([]);
  const handleNewLocalizationNameChange = (e: any) => {
    setNewLocalizationName(e.target.value);
  };

  const [type, setType] =
    useState<{ value: number; label: string } | null>(null);
  const [types, setTypes] = useState<{ value: number; label: string }[]>([]);

  const [eventType, setEventType] =
    useState<{ value: number; label: string } | null>(null);

  const [isCyclical, setIsCyclical] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [eventTypes, setEventTypes] = useState<
    { value: number; label: string }[]
  >([]);
  const [selectedFolders, setSelectedFolders] = useState<any>([]);
  const [selectedTopLevelFolderIds, setSelectedTopLevelFolderIds] =
    useState<any>([]);

  const findTopLevelFolderId = (folder: any, currentFolderId: any): string => {
    if (!folder.ParentFolderId) {
      return folder.FolderId;
    }

    const parentFolder = findFolderById(folder, folder.ParentFolderId);
    return findTopLevelFolderId(parentFolder, currentFolderId);
  };

  const findFolderById = (folder: any, folderId: any) => {
    if (folder.FolderId === folderId) {
      return folder;
    }
    if (folder.subFolder) {
      for (let i = 0; i < folder.subFolder.length; i++) {
        const foundFolder: any = findFolderById(folder.subFolder[i], folderId);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };
  // const findFolderById = (folder: any, folderId: any) => {
  //   if (folder.FolderId === folderId) {
  //     return folder;
  //   }
  //   if (folder.subFolder) {
  //     for (let i = 0; i < folder.subFolder.length; i++) {
  //       const foundFolder: any = findFolderById(folder.subFolder[i], folderId);
  //       if (foundFolder) {
  //         return foundFolder;
  //       }
  //     }
  //   }
  //   return null;
  // };

  const handleFolderClick = (folderId: any, folder: any) => {
    const folderIndex = selectedFolders.indexOf(folderId);
    let newSelectedFolders;
    const topLevelFolderId = findTopLevelFolderId(folderId, folder);

    if (!selectedTopLevelFolderIds.includes(topLevelFolderId)) {
      setSelectedTopLevelFolderIds([
        ...selectedTopLevelFolderIds,
        topLevelFolderId,
      ]);
    } else {
      setSelectedTopLevelFolderIds(
        selectedTopLevelFolderIds.filter((id: any) => id !== topLevelFolderId),
      );
    }
    if (folderIndex === -1) {
      newSelectedFolders = [
        ...selectedFolders,
        folderId,
        ...getAllSubFolderIds(folder),
      ];
    } else {
      const subFolderIds = getAllSubFolderIds(folder);
      newSelectedFolders = selectedFolders.filter(
        (id: any) => ![...subFolderIds, folderId].includes(id),
      );

      const allParentFoldersSelected = selectedFolders.some((parentId: any) => {
        const parentFolder = findFolderById(parentId, folder.ParentFolderId);
        if (parentFolder) {
          const parentSubFolderIds = getAllSubFolderIds(parentFolder);
          return parentSubFolderIds.every((subFolderId: any) =>
            selectedFolders.includes(subFolderId),
          );
        }
        return false;
      });

      if (!allParentFoldersSelected) {
        newSelectedFolders = newSelectedFolders.filter(
          (id: any) => !folder.ParentFolderId || id !== folder.ParentFolderId,
        );
      }
    }

    setSelectedFolders(newSelectedFolders);
  };

  const isFolderSelected = (folderId: any) =>
    selectedFolders.includes(folderId);

  const getAllSubFolderIds = (folder: any) => {
    const subFolderIds = folder.subFolder
      ? folder.subFolder.map((subFolder: any) => subFolder.FolderId)
      : [];
    folder.subFolder &&
      folder.subFolder.forEach((subFolder: any) => {
        subFolderIds.push(...getAllSubFolderIds(subFolder));
      });
    return subFolderIds;
  };

  const renderFolder = (folder: any, depth = 0) => {
    const folderColor = isFolderSelected(folder.FolderId)
      ? "rgba(0,145,255,0.21)"
      : "rgba(163,168,157,0.11)";

    const folderFontColor = isFolderSelected(folder.FolderId)
      ? "white"
      : "rgb(112,112,112)";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          marginLeft: `${depth * 20}px`,
        }}
      >
        <div
          style={{
            background: folderColor,
            height: "48px",
            display: "flex",
            gap: "18px",
            alignItems: "center",
            padding: "0px 16px",
            fontSize: "15px",
            color: folderFontColor,
            cursor: "pointer",
          }}
          onClick={() => handleFolderClick(folder.FolderId, folder)}
        >
          <img src={FolderIco} alt="folder" />
          <p id={folder.FolderId}>{folder.Name}</p>
        </div>
        {folder.subFolder &&
          folder.subFolder.map((subFolder: any) =>
            renderFolder(subFolder, depth + 1),
          )}
      </div>
    );
  };

  const [level, setLevel] =
    useState<{ value: number; label: string } | null>(null);
  const [levels, setLevels] = useState<{ value: number; label: string }[]>([]);

  const [daysBeforeEvent, setDaysBeforeEvent] =
    useState<{ value: number; label: string } | null>(null);
  const [allDaysBeforeEvent, setAllDaysBeforeEvent] = useState<
    { value: number; label: string }[]
  >([]);

  const [favLocation, setFavLocation] =
    useState<{ value: string; label: string } | null>(null);
  const [favLocations, setFavLocations] = useState<
    { value: number; label: string }[]
  >([]);

  const [limit, setLimit] =
    useState<{ value: boolean; label: string } | null>(null);
  const [limits, setLimits] = useState<{ value: boolean; label: string }[]>([]);

  const [isLimitChecked, setLimitChecked] = useState(false);
  const [isCalendarChecked, setCalendarChecked] = useState(false);
  const [isPostChecked, setPostChecked] = useState(false);

  const handleLimitCheckboxChange = () => {
    setLimitChecked((prev) => !prev);
  };

  const handleCalendarCheckboxChange = () => {
    setCalendarChecked((prev) => !prev);
  };

  const handlePostCheckboxChange = () => {
    setPostChecked((prev) => !prev);
  };

  const [surface, setSurface] =
    useState<{ value: number; label: string } | null>(null);
  const [surfaces, setSurfaces] = useState<{ value: number; label: string }[]>(
    [],
  );

  const [surfaceDimesion, setSurfaceDimesion] =
    useState<{ value: number; label: string } | null>(null);
  const [surfaceDimesions, setSurfaceDimesions] = useState<
    { value: number; label: string }[]
  >([]);

  const today = new Date().toISOString().split("T")[0];
  const [maxDate, setMaxDate] = useState("");

  const minAvailableTo = availableFrom ? availableFrom : today;

  const handleAvailableFromChange = (newDate: any) => {
    setAvailableFrom(newDate);

    if (availableTo && newDate > availableTo) {
      setAvailableTo(newDate);
    }
  };

  useEffect(() => {
    if (availableFrom) {
      const maxEndDate = new Date(availableFrom);

      maxEndDate.setMonth(maxEndDate.getMonth() + 10);

      setMaxDate(maxEndDate.toISOString().split("T")[0]);
    }
  }, [availableFrom]);

  const [location, setLocation] = useState(null);

  const handleLocationChange = (newLocation: any) => {
    setLocation(newLocation);
  };
  // if (!permissions) {
  //   return null;
  // }
  const customAddressStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      background: "rgba(0, 0, 0, 0.8)",
    },
  };
  const getAllVisibility = async () => {
    try {
      setVisibilities([
        {
          label: "Zawodnicy akademi",
          value: 3,
        },
        {
          label: "Tylko zaproszeni",
          value: 1,
        },
        {
          label: "Grupa",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVisibility();
  }, []);

  const getAllOrganizerTypes = async () => {
    try {
      setOrganizerTypes([
        {
          label: "Justwin",
          value: 0,
        },
        {
          label: "Trener",
          value: 1,
        },
        {
          label: "Zawodnik",
          value: 2,
        },
        {
          label: "Klub",
          value: 3,
        },
        {
          label: "Reprezentacja",
          value: 4,
        },
        {
          label: "Nieznany",
          value: 5,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrganizerTypes();
  }, []);

  const getAllRepetitationIntervals = async () => {
    try {
      setRepetitationIntervals([
        {
          label: "Każdy poniedziałek",
          value: 1,
        },
        {
          label: "Każdy wtorek",
          value: 2,
        },
        {
          label: "Każda środa",
          value: 3,
        },
        {
          label: "Każdy czwartek",
          value: 4,
        },
        {
          label: "Każdy piątek",
          value: 5,
        },
        {
          label: "Każda sobota",
          value: 6,
        },
        {
          label: "Każda niedziela",
          value: 7,
        },
        {
          label: "Codziennie",
          value: 8,
        },
        {
          label: "Co miesiąc",
          value: 9,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRepetitationIntervals();
  }, []);

  const getAllAcademyFolders = async () => {
    try {
      const resp = await GetOwnFolders();
      console.log("respFOLDEROWN", resp.Data);

      const mappedFolders = resp.Data.map((folder: any) => ({
        label: folder.Name,
        value: folder.FolderId,
      }));

      setAcademyFolders(mappedFolders);
      setOwnFolders(resp.Data);

      const buildFolderTree = (folders: any, parentId: any) => {
        const subFolders = folders.filter(
          (folder: any) => folder.ParentFolderId === parentId,
        );
        return subFolders.map((folder: any) => ({
          ...folder,
          subFolder: buildFolderTree(folders, folder.FolderId),
        }));
      };

      const topLevelFolders = resp.Data.filter(
        (folder: any) =>
          folder.ParentFolderId === "00000000-0000-0000-0000-000000000000",
      );
      const folderTrees = topLevelFolders.map((folder: any) => ({
        ...folder,
        subFolder: buildFolderTree(resp.Data, folder.FolderId),
      }));

      console.log("folderTrees", folderTrees);
      setFolderTrees(folderTrees);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAcademyFolders();
  }, []);

  useEffect(() => {}, []);

  const getFavoriteLocationFn = async (id: string) => {
    const resp: any = await favoriteLocationServices.getFavoriteLocation(id);
    console.log("favlocation", resp);
    setFavoriteLocationData(resp);
  };

  useEffect(() => {
    if (favLocation?.value) {
      setLocalizationType(3);
      getFavoriteLocationFn(favLocation?.value);
    }
  }, [favLocation]);

  const getAllTypes = async () => {
    try {
      setTypes([
        {
          label: "Trening",
          value: 0,
        },
        {
          label: "Trening aerobowy",
          value: 1,
        },
        {
          label: "Trening personalny",
          value: 2,
        },
        {
          label: "Badania okresowe",
          value: 3,
        },
        {
          label: "Mecz piłkarski",
          value: 4,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  const [isFocused, toggleFocused] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [folderReceiver, setFolderReceiver] = useState("");
  const [folderReceiverId, setFolderReceiverId] = useState("");
  const [playerImage, setPlayerImage] = useState("");
  const [playerType, setPlayerType] = useState("");
  const [allPlayers, setAllPlayers] = useState<any>([]);
  const [allFolders, setAllFolders] = useState<any>([]);

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const deletePlayer = (id: string) => {
    setAllPlayers((prevPlayers: any) =>
      prevPlayers.filter((player: any) => player.Id !== id),
    );
  };

  const deleteFolder = (id: string) => {
    setAllFolders((prevFolders: any) =>
      prevFolders.filter((folder: any) => folder.Id !== id),
    );
  };

  useEffect(() => {
    if (receiverId) {
      setAllPlayers((prev: any) => [
        ...prev,
        {
          Name: receiver,
          Id: receiverId,
          Type: playerType,
          Image: playerImage,
        },
      ]);
      setReceiverId("");
      setReceiver("");
      setPlayerType("");
      setPlayerImage("");
    }
  }, [receiverId]);

  // useEffect(() => {
  //   if (visibility?.value === 2) {
  //     const mappedPlayers = allPlayerProfiles.map((profile: any) => ({
  //       Id: profile.PlayerId,
  //       Type: 1,
  //     }));

  //     setAllPlayers(mappedPlayers);
  //   }
  // }, [visibility]);

  useEffect(() => {
    if (visibility?.value !== 1) {
      setAllPlayers([]);
    }
  }, [visibility]);

  useEffect(() => {
    if (visibility?.value !== 2) {
      setAllFolders([]);
    }
  }, [visibility]);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReceiver(e.target.value);

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  useEffect(() => {
    getAllTypes();
  }, []);

  const getAllLimits = async () => {
    try {
      setLimits([
        {
          label: "Tak",
          value: true,
        },
        {
          label: "Nie",
          value: false,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLimits();
  }, []);

  const getAllLevels = async () => {
    try {
      setLevels([
        {
          label: "Klub",
          value: 0,
        },
        {
          label: "Reprezentacja",
          value: 1,
        },
        {
          label: "Własne",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLevels();
  }, []);

  const getAllDaysBeforeEvent = async () => {
    try {
      setAllDaysBeforeEvent([
        {
          label: "7 dni",
          value: 0,
        },
        {
          label: "14 dni",
          value: 1,
        },
        {
          label: "30 dni",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDaysBeforeEvent();
  }, []);

  const getAllFavLocations = async () => {
    try {
      const resp: any =
        await favoriteLocationServices.getAllFavoriteLocations();
      console.log("alllocations", resp);
      const mappedLocations: any = resp.map((location: any) => ({
        label: location.Name,
        value: location.FavoriteLocationId,
      }));

      setFavLocations(mappedLocations);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllFavLocations();
  }, []);

  // const getAllEventTypes = async () => {
  //   try {
  //     setEventTypes([
  //       {
  //         label: "Zwykłe",
  //         value: 1,
  //       },
  //       {
  //         label: "Cykliczne",
  //         value: 2,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllEventTypes();
  // }, []);

  const digitalReleaseValidation = Yup.object().shape({
    Name: Yup.string().required(validationMessage.isRequired),
    Content: Yup.string().when("IsPostChecked", {
      is: true,
      then: Yup.string().required(validationMessage.isRequired),
      otherwise: Yup.string(),
    }),
    // Address: Yup.string().required(validationMessage.isRequired),
    // PostCode: Yup.string().required(validationMessage.isRequired),
    // City: Yup.string().required(validationMessage.isRequired),
  });

  const handleSubmit = async (values: any) => {
    // if (isPostChecked && !base64) return toast.error("Dodaj zdjęcie!");
    // if (!type) return toast.error("Wybierz typ zajęcia!");
    // if (!level) return toast.error("Wybierz poziom zajęcia!");
    // if (!availableFrom) return toast.error("Wybierz daty!");
    // if (!timeFrom) return toast.error("Wybierz godziny!");
    // if (!timeTo) return toast.error("Wybierz godziny!");
    // if (!availableTo) return toast.error("Wybierz daty!");
    // if (!surface) return toast.error("Wybierz surface!");
    // if (!surfaceDimesion) return toast.error("Wybierz fieldValue!");
    // if (!favoriteLocationData?.Latitude && !latitude)
    //   return toast.error("Wybierz lokalizacje z mapy!");

    // if (!isStructure) {
    //   if (!visibility) return toast.error("Wybierz widoczność zajęcia!");

    // }

    // if (!organizerType) return toast.error("Wybierz organizatora!");
    if (!type) return toast.error("Wybierz typ zajęcia!");
    if (!values.Name) return toast.error("Uzupełnij nazwe!");
    if (!values?.Content && isPostChecked)
      return toast.error("Uzupełnij opis!");
    if (!level) return toast.error("Wybierz poziom zajęcia!");
    if (!availableFrom) return toast.error("Wybierz daty!");
    if (!timeFrom) return toast.error("Wybierz godziny!");
    if (!timeTo) return toast.error("Wybierz godziny!");
    if (!availableTo) return toast.error("Wybierz daty!");
    // if (!startEventRange) return toast.error("Wybierz daty!");
    // if (!endEventRange) return toast.error("Wybierz daty!");
    if (!surface && type?.value === 4)
      return toast.error("Wybierz nawierzchnie!");
    if (!surfaceDimesion && type?.value === 4)
      return toast.error("Wybierz rozmiar nawierzchni!");
    // if (isCalendarChecked && !daysBeforeEvent)
    //   return toast.error("Wybierz wpis do kalendarza!");
    if (isPostChecked && !base64) return toast.error("Dodaj zdjęcie!");
    if (!favoriteLocationData?.Latitude && !latitude)
      return toast.error("Wybierz lokalizacje z mapy!");
    if (!values?.PostCode && localizationType === 2)
      return toast.error("Uzupełnij kod pocztowy!");
    if (!values?.Localization && localizationType === 2)
      return toast.error("Uzupełnij adres!");

    if (!isStructure) {
      if (!visibility) return toast.error("Wybierz widoczność zajęcia!");
      if (visibility?.value === 2 && selectedFolders.length === 0)
        return toast.error("Wybierz grupy!");
    }
    const foldersIds = allFolders.map((folder: any) => folder.Id);
    await onSubmit(
      values,
      `${availableFrom}T${timeFrom}:00`,
      `${availableTo}T${timeTo}:00`,
      visibility?.value,
      level?.value,
      type?.value,
      surface?.value,
      surfaceDimesion?.value,
      isLimitChecked,
      allPlayers,
      isCalendarChecked,
      isCalendarChecked ? daysBeforeEvent?.value : 0,
      isPostChecked,
      favoriteLocationData?.Latitude ? favoriteLocationData.Latitude : latitude,
      favoriteLocationData?.Longitude
        ? favoriteLocationData.Longitude
        : longitude,
      // foldersIds,
      selectedFolders,
      favoriteLocationData
        ? favoriteLocationData?.Address?.split(",")[1]?.slice(0, 7)
        : "",
      favoriteLocationData ? favoriteLocationData?.Address?.split(",")[0] : "",
    );
  };

  useEffect(() => {
    if (academyFolder?.value) {
      const idAlreadyExists = allFolders.some(
        (folder: any) => folder.Id === academyFolder?.value,
      );

      if (!idAlreadyExists) {
        setAllFolders((prev: any) => [
          ...prev,
          {
            Name: academyFolder?.label,
            Id: academyFolder?.value,
          },
        ]);
        setAcademyFolder(null);
      } else {
        toast.error("Grupa została już dodana!");
        setAcademyFolder(null);
      }

      // setAcademyFolders((prevFolders: any) =>
      //   prevFolders.filter(
      //     (folder: any) => folder.value !== academyFolder?.value,
      //   ),
      // );
    }
  }, [academyFolder]);

  const getFolderItemsByFolderIdFn = async (id: any) => {
    const resp = await getFolderItemsByFolderId(id);
    console.log("ZAWODNICYWFOLDERZE", resp.Data);

    if (resp.Data && resp.Data.length > 0) {
      const mappedPlayers = resp.Data.map((player: any) => ({
        Id: player.PlayerId,
        Type: 1,
      }));

      setPlayersInFolder(mappedPlayers);
    } else {
      setPlayersInFolder([]);
    }
  };

  useEffect(() => {
    setPlayersInFolder([]);
    if (academyFolder?.value) {
      getFolderItemsByFolderIdFn(academyFolder?.value);
    }
  }, [academyFolder?.value]);

  const handleDraftSubmit = async (values: any) => {
    if (!values?.Name) return toast.error("Wprowadź nazwe!");
    const foldersIds = allFolders.map((folder: any) => folder.Id);

    await onCyclicalSubmit(
      values,
      availableFrom
        ? timeFrom
          ? `${availableFrom}T${timeFrom}:00`
          : `${availableFrom}T00:00:00`
        : null,
      availableTo
        ? timeTo
          ? `${availableTo}T${timeTo}:00`
          : `${availableTo}T00:00:00`
        : null,
      visibility?.value ?? 3,
      level?.value ?? null,
      type?.value ?? null,
      surface?.value ?? null,
      surfaceDimesion?.value ?? null,
      isLimitChecked,
      allPlayers,

      0,
      repetitationInterval?.value ?? null,
      favoriteLocationData?.Latitude ? favoriteLocationData.Latitude : latitude,
      favoriteLocationData?.Longitude
        ? favoriteLocationData.Longitude
        : longitude,
      isCalendarChecked,
      isCalendarChecked ? daysBeforeEvent?.value : null,
      isPostChecked,
      true,
      // foldersIds,
      selectedFolders,
      favoriteLocationData
        ? favoriteLocationData?.Address?.split(",")[1]?.slice(0, 7)
        : "",
      favoriteLocationData ? favoriteLocationData?.Address?.split(",")[0] : "",
    );
  };

  const handleCyclicalSubmit = async (values: any) => {
    // if (!organizerType) return toast.error("Wybierz organizatora!");
    if (!type) return toast.error("Wybierz typ zajęcia!");
    if (!level) return toast.error("Wybierz poziom zajęcia!");
    if (!availableFrom) return toast.error("Wybierz daty!");
    if (!timeFrom) return toast.error("Wybierz godziny!");
    if (!timeTo) return toast.error("Wybierz godziny!");
    if (!availableTo) return toast.error("Wybierz daty!");
    // if (!startEventRange) return toast.error("Wybierz daty!");
    // if (!endEventRange) return toast.error("Wybierz daty!");
    if (!repetitationInterval) return toast.error("Wybierz powtarzanie!");
    if (!surface) return toast.error("Wybierz nawierzchnie!");
    if (!surfaceDimesion) return toast.error("Wybierz opis nawierzchni!");
    // if (isCalendarChecked && !daysBeforeEvent)
    //   return toast.error("Wybierz wpis do kalendarza!");
    if (isPostChecked && !base64) return toast.error("Dodaj zdjęcie!");
    if (!favoriteLocationData?.Latitude && !latitude)
      return toast.error("Wybierz lokalizacje z mapy!");
    if (!values?.PostCode && localizationType === 2)
      return toast.error("Uzupełnij kod pocztowy!");
    if (!values?.Localization && localizationType === 2)
      return toast.error("Uzupełnij adres!");
    if (!isStructure) {
      if (!visibility) return toast.error("Wybierz widoczność zajęcia!");
      if (visibility?.value === 2 && selectedFolders.length === 0)
        return toast.error("Wybierz grupy!");
    }
    if (timeFrom && timeTo) {
      if (timeFrom > timeTo) return toast.error("Błędne godziny!");
    }
    const foldersIds = allFolders.map((folder: any) => folder.Id);
    await onCyclicalSubmit(
      values,
      `${availableFrom}T${timeFrom}:00`,
      `${availableTo}T${timeTo}:00`,
      visibility?.value,
      level?.value,
      type?.value,
      surface?.value,
      surfaceDimesion?.value,
      isLimitChecked,
      allPlayers,
      0,
      repetitationInterval?.value,
      favoriteLocationData?.Latitude ? favoriteLocationData.Latitude : latitude,
      favoriteLocationData?.Longitude
        ? favoriteLocationData.Longitude
        : longitude,
      isCalendarChecked,
      isCalendarChecked ? daysBeforeEvent?.value : 0,
      isPostChecked,
      false,
      // foldersIds,
      selectedFolders,
      favoriteLocationData
        ? favoriteLocationData?.Address?.split(",")[1]?.slice(0, 7)
        : "",
      favoriteLocationData ? favoriteLocationData?.Address?.split(",")[0] : "",
    );
  };

  const addFavoriteLocationFn = async (name: string, note: string) => {
    // if (!type?.label) return toast.error("Wybierz typ zadania!");
    // if (!surface?.label) return toast.error("Wybierz nawierzchnie!");
    // if (!surfaceDimesion?.label)
    //   return toast.error("Wybierz opis nawierzchni!");
    if (!latitude && !longitude)
      return toast.error("Wybierz lokalizacje z mapy!");
    if (!name) return toast.error("Wpisz nazwe miejsca!");

    await favoriteLocationServices.addFavoriteLocation(
      name,
      note,
      latitude,
      longitude,
      // surface?.value,
      // surfaceDimesion?.value,
      "",
      "",
    );
    toast.success("Dodano ulubioną lokalizacje!");
  };

  useEffect(() => {
    console.log("ALLPLAYERS>>", allPlayers);
  }, [allPlayers]);

  const getEventFields = async (type: number) => {
    try {
      // const resp: any = await eventsServices.getEventFields(type);
      const resp: any = await eventsServices.getSurfacesWithDimensions();
      console.log("DIMENSIONS>>>", resp);
      const surfacesArray: any = [];
      const surfaceDimensionsArray: any = [];

      resp?.Surface.map((single: any) => {
        return surfacesArray.push({
          value: single.Id,
          label: single.Name,
        });
      });

      resp?.SurfaceDimensions.map((single: any) => {
        return surfaceDimensionsArray.push({
          value: single.Id,
          label: `${single.SurfaceLengthMeters} x ${single.SurfaceWidthMeters}`,
        });
      });

      setSurfaces(surfacesArray);
      setSurfaceDimesions(surfaceDimensionsArray);
      // console.log("fieldsy", resp);
      // setAllFields(resp);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getAllAcademyPlayersQuery(receiver)
  //     .then((data) => {
  //       setPlayerProfiles(data.Data.Items);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [receiver]);

  useEffect(() => {
    getAllAcademyPlayers()
      .then((data) => {
        setAllPlayerProfiles(data.Data.Items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (type?.label) {
      getEventFields(type.value);
    }
  }, [type]);

  // useEffect(() => {
  //   if (surface?.label && allFields.length > 0) {
  //     const categoriesArray: any = [];
  //     const foundObj: any = allFields.find(
  //       (obj: any) => obj.FieldId === surface.value,
  //     );
  //     if (foundObj) {
  //       foundObj.Values.map((single: any) => {
  //         return categoriesArray.push({
  //           value: single.ValueId,
  //           label: single.ValueName,
  //         });
  //       });

  //       setSurfaceDimesions(categoriesArray);
  //     }
  //   }
  // }, [surface]);

  useEffect(() => {
    console.log("localization", location);
  }, [location]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };
  const customGroupStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  return (
    <Formik
      initialValues={product}
      validationSchema={digitalReleaseValidation}
      onSubmit={isCyclical ? handleCyclicalSubmit : handleSubmit}
      validateOnMount
    >
      {({ errors, isSubmitting, values, initialValues, setFieldValue }) => (
        <Form
          className="flex"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Container
            style={{
              display: "flex",
              width: "100%",
              height: "calc(100% - 70px)",
              overflowY: "auto",
            }}
          >
            <Modal
              isOpen={isMapModalOpen}
              onRequestClose={closeMapModalOpen}
              style={customStyles}
            >
              <Map onLocationChange={handleLocationChange} />
            </Modal>
            {/* todo */}
            <Modal
              isOpen={isGroupModalOpen}
              onRequestClose={closeGroupModalOpen}
              style={customGroupStyles}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "0 10px",
                  }}
                >
                  <Button
                    variant={ButtonVariant.Save}
                    onClick={() => setIsGroupModalOpen(false)}
                  >
                    Zapisz
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    {folderTrees.length > 0 &&
                      folderTrees.map((foldertree: any) =>
                        renderFolder(foldertree),
                      )}
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              isOpen={isAddressModalOpen}
              onRequestClose={closeAddressModalOpen}
              style={customAddressStyles}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                  background: "transparent",
                }}
              >
                <p style={{ color: "white" }}>Dodaj do ulubionych</p>
                <input
                  type="text"
                  placeholder="Nazwa lokalizacji"
                  value={newLocalizationName}
                  onChange={handleNewLocalizationNameChange}
                />
                <Button
                  // onClick={() => createFolderFn(newFolderName)}
                  className="px-24 xl:order-2"
                  variant={ButtonVariant.Submit}
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "white",
                    background: "rgb(0, 160, 255)",
                    width: "100%",
                  }}
                >
                  Zapisz
                </Button>
              </div>
            </Modal>
            <MyComponent
              location={location}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />
            <NameBox>
              {!isStructure && (
                <BackButton
                  className="w-11 h-11 z-10 hover:opacity-100 shadow p-0 flex-shrink-0"
                  variant={ButtonVariant.Submit}
                  style={{ width: "48px", height: "48px" }}
                  onClick={() => toggleAddMenuOpen(false)}
                >
                  <i>{<Arrow className="fill-current w-7" />}</i>
                </BackButton>
              )}

              <TextField
                name="Name"
                label={"Nazwa"}
                isError={false}
                style={{
                  paddingBottom: "9px",
                  width: "calc(100% - 48px - 35px)",
                  textAlign: "left",
                }}
              />
            </NameBox>

            <ColumnsContainer>
              <SingleColumn
                style={{ alignItems: "center", paddingLeft: "15px" }}
              >
                <p>DANE PODSTAWOWE</p>
                {/*
              <NewSelectStatystics
                name="EventType"
                items={eventTypes}
                label="Typ zajęcia"
                style={{ width: "280px", paddingBottom: "9px" }}
                // @ts-ignore
                selectedItem={eventType}
                setSelectedItem={setEventType}
              /> */}

                <NewSelectStatystics
                  name="ActivityType"
                  items={types}
                  label="Typ zadania"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={type}
                  setSelectedItem={setType}
                />

                {type?.label && type?.value === 4 && (
                  <NewSelectStatystics
                    name="Field"
                    items={surfaces}
                    label="Nawierzchnia"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={surface}
                    setSelectedItem={setSurface}
                  />
                )}

                {surface?.label && type?.value === 4 && (
                  <NewSelectStatystics
                    name="FieldValue"
                    items={surfaceDimesions}
                    label="Rozmiar nawierzchni"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    selectedItem={surfaceDimesion}
                    setSelectedItem={setSurfaceDimesion}
                  />
                )}
                <NewSelectStatystics
                  name="ActivityLevel"
                  items={levels}
                  label="Poziom"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={level}
                  setSelectedItem={setLevel}
                />

                <div
                  style={{
                    width: "280px",
                    paddingBottom: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "9px",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "15px",
                        fontWeight: 400,
                      }}
                    >
                      MIEJSCE
                    </p>
                    {localizationType === 2 && (
                      <p
                        onClick={() => setLocalizationType(1)}
                        style={{
                          color: "#0091FF",
                          fontSize: "11px",
                          cursor: "pointer",
                        }}
                      >
                        Cofnij
                      </p>
                    )}
                  </div>

                  {localizationType === 1 && (
                    <div
                      onClick={() => setLocalizationType(2)}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          id="Icon_material-add-box"
                          data-name="Icon material-add-box"
                          d="M15.167,4.5H5.833A1.333,1.333,0,0,0,4.5,5.833v9.333A1.333,1.333,0,0,0,5.833,16.5h9.333A1.337,1.337,0,0,0,16.5,15.167V5.833A1.337,1.337,0,0,0,15.167,4.5Zm-1.333,6.667H11.167v2.667H9.833V11.167H7.167V9.833H9.833V7.167h1.333V9.833h2.667Z"
                          transform="translate(-4.5 -4.5)"
                          fill="#0091ff"
                        />
                      </svg>

                      <p style={{ color: "#0091FF", fontSize: "11px" }}>
                        DODAJ
                      </p>
                    </div>
                  )}

                  {localizationType === 2 && (
                    <div
                      onClick={() => setIsMapModalOpen(true)}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                      >
                        <path
                          id="Icon_awesome-map-marked-alt"
                          data-name="Icon awesome-map-marked-alt"
                          d="M9,0A3.937,3.937,0,0,0,5.063,3.938c0,1.758,2.573,4.963,3.559,6.126a.493.493,0,0,0,.756,0C10.364,8.9,12.938,5.7,12.938,3.938A3.937,3.937,0,0,0,9,0ZM9,5.25a1.313,1.313,0,1,1,1.313-1.312A1.312,1.312,0,0,1,9,5.25ZM.629,6.748A1,1,0,0,0,0,7.677V15.5a.5.5,0,0,0,.686.464L5,14V6.716a9.464,9.464,0,0,1-.664-1.451ZM9,11.24a1.493,1.493,0,0,1-1.141-.53C7.245,9.985,6.591,9.159,6,8.312V14l6,2V8.313c-.591.847-1.244,1.672-1.859,2.4A1.494,1.494,0,0,1,9,11.24Zm8.314-6.2L13,7v9l4.371-1.748A1,1,0,0,0,18,13.323V5.5A.5.5,0,0,0,17.314,5.036Z"
                          fill="#0091ff"
                        />
                      </svg>

                      <p style={{ color: "#0091FF", fontSize: "11px" }}>Mapa</p>
                    </div>
                  )}

                  {localizationType === 3 && (
                    <div
                      onClick={() => {
                        setFavoriteLocationData({});
                        setFavLocation(null);
                        setLocalizationType(1);
                      }}
                      style={{
                        display: "flex",
                        gap: "9px",
                        cursor: "pointer",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12.857"
                        viewBox="0 0 10 12.857"
                      >
                        <path
                          id="Icon_material-delete"
                          data-name="Icon material-delete"
                          d="M8.214,15.929a1.433,1.433,0,0,0,1.429,1.429h5.714a1.433,1.433,0,0,0,1.429-1.429V7.357H8.214ZM17.5,5.214H15L14.286,4.5H10.714L10,5.214H7.5V6.643h10Z"
                          transform="translate(-7.5 -4.5)"
                          fill="#dc2121"
                        />
                      </svg>

                      <p style={{ color: "#DC2121", fontSize: "11px" }}>USUŃ</p>
                    </div>
                  )}
                </div>

                {localizationType === 1 && (
                  <>
                    <NewSelectStatystics
                      name="FavLocation"
                      items={favLocations}
                      label="Lokalizacja"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={favLocation}
                      setSelectedItem={setFavLocation}
                    />
                  </>
                )}

                {localizationType === 2 && (
                  <>
                    <TextField
                      name="LocationName"
                      label={"Nazwa miejsca"}
                      placeholder=""
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="LocationNote"
                      label={"Opis miejsca"}
                      placeholder=""
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    {/* <MaskedField
                    mask={Mask.Postcode}
                    name="PostCode"
                    label="Kod pocztowy"
                    placeholder="Uzupełnij"
                    style={{ paddingBottom: "9px" }}
                    autoComplete="off"
                  /> */}
                    <TextField
                      name="country"
                      label={"Kraj"}
                      disabled
                      placeholder="-"
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="City"
                      label={"Miasto"}
                      isError={false}
                      placeholder="-"
                      disabled
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="PostCode"
                      label={"Kod pocztowy"}
                      isError={false}
                      placeholder="-"
                      style={{ paddingBottom: "9px" }}
                    />
                    <TextField
                      name="Localization"
                      label={"Adres"}
                      isError={false}
                      placeholder="-"
                      style={{ paddingBottom: "9px" }}
                    />
                    <Button
                      onClick={() => {
                        addFavoriteLocationFn(
                          values.LocationName,
                          values.LocationNote,
                        );
                      }}
                      className="px-24 xl:order-2"
                      variant={ButtonVariant.Submit}
                      style={{
                        padding: "15px 41.4064px",
                        background: "rgb(0, 160, 255)",
                        width: "280px",
                        fontSize: "15px",
                        fontWeight: 400,
                        order: 0,
                        marginBottom: "9px",
                      }}
                    >
                      Dodaj do ulubionych
                    </Button>
                  </>
                )}
                {localizationType === 3 && (
                  <>
                    <NewSelectStatystics
                      name="FavLocation"
                      items={favLocations}
                      label="Lokalizacja"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={favLocation}
                      setSelectedItem={setFavLocation}
                      defaultValue={favLocation?.value}
                    />
                    <div
                      style={{
                        paddingBottom: "19px",
                        color: "#0091FF",
                        fontSize: "15px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        {favoriteLocationData?.Address &&
                          favoriteLocationData?.Address?.split(",")[0]}
                      </p>
                      <p>
                        {favoriteLocationData?.Address &&
                          favoriteLocationData?.Address?.split(",")[1]}
                      </p>
                    </div>
                  </>
                )}
                {/* {isCyclical && (
                <>
                  <TextInput
                    isError={false}
                    style={{ paddingBottom: "9px" }}
                    name="StartEventRange"
                    label={"Początek zakresu"}
                    type="date"
                    min={today}
                    value={startEventRange}
                    onChange={(e) => setStartEventRange(e.target.value)}
                  />
                  <TextInput
                    isError={false}
                    style={{ paddingBottom: "9px" }}
                    name="EndEventRange"
                    label={"Koniec zakresu"}
                    min={today}
                    type="date"
                    value={endEventRange}
                    onChange={(e) => setEndEventRange(e.target.value)}
                  />
                </>
              )} */}
                <p >CZAS</p>

                <EventTypeBox isCyclical={isCyclical} style={{borderRadius:'8px'}}>
                  <div onClick={() => setIsCyclical(false)}>
                    <p>Pojedyncze</p>
                  </div>
                  <div onClick={() => setIsCyclical(true)}>
                    <p>Cykliczne</p>
                  </div>
                </EventTypeBox>
                {!isCyclical && (
                  <>
                    <p style={{paddingBottom:'4px'}}>OD</p>
                    <DateBox>
                      <BlockedDateTextInput
                        isError={false}
                        style={{
                          paddingBottom: "9px",
                          // background: 'rgba(163,158,157,0.11)',
                          borderRadius:'8px',
                          flex: "unset",
                        }}
                        name="OccurenceDate"
                        label={"Data"}
                        type="date"
                        min={today}
                        value={availableFrom}
                        onChange={(e) =>
                          handleAvailableFromChange(e.target.value)
                        }
                      />
                      <TextInput
                        isError={false}
                        style={{ paddingBottom: "9px", flex: "unset", borderRadius:'8px' }}
                        name="timeFrom"
                        label={"Godzina"}
                        type="time"
                        value={timeFrom}
                        onChange={(e) => setTimeFrom(e.target.value)}
                      />
                    </DateBox>
                    <p style={{paddingBottom:'4px'}}>DO</p>
                    <DateBox>
                      <BlockedDateTextInput
                        isError={false}
                        style={{ paddingBottom: "9px" }}
                        name="EndDate"
                        label={"Data"}
                        min={minAvailableTo}
                        type="date"
                        value={availableTo}
                        max={maxDate}
                        onChange={(e) => setAvailableTo(e.target.value)}
                      />

                      <TextInput
                        isError={false}
                        style={{ paddingBottom: "9px" }}
                        name="timeTo"
                        label={"Godzina"}
                        type="time"
                        value={timeTo}
                        onChange={(e) => setTimeTo(e.target.value)}
                      />
                    </DateBox>
                  </>
                )}
                {isCyclical && (
                  <>
                    <BlockedDateTextInput
                      isError={false}
                      style={{
                        paddingBottom: "9px",

                        flex: "unset",
                      }}
                      name="OccurenceDate"
                      label={"Data"}
                      type="date"
                      min={today}
                      value={availableFrom}
                      onChange={(e) =>
                        handleAvailableFromChange(e.target.value)
                      }
                    />
                    <div
                      style={{
                        width: "280px",
                        paddingBottom: "9px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                      }}
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontWeight: 400,
                          width: "140px",
                        }}
                      >
                        OD
                      </p>
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontWeight: 400,
                          width: "140px",
                        }}
                      >
                        DO
                      </p>
                    </div>
                    <DateBox>
                      <TextInput
                        isError={false}
                        style={{ paddingBottom: "9px", flex: "unset" }}
                        name="timeFrom"
                        label={"Godzina"}
                        type="time"
                        value={timeFrom}
                        onChange={(e) => setTimeFrom(e.target.value)}
                      />
                      <TextInput
                        isError={false}
                        style={{ paddingBottom: "9px" }}
                        name="timeTo"
                        label={"Godzina"}
                        type="time"
                        value={timeTo}
                        onChange={(e) => setTimeTo(e.target.value)}
                      />
                    </DateBox>
                    <p style={{ marginTop: "5px" }}>DATA KOŃCA OKRESU</p>
                    <BlockedDateTextInput
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                      name="EndDate"
                      label={"Data"}
                      min={minAvailableTo}
                      type="date"
                      value={availableTo}
                      max={maxDate}
                      onChange={(e) => setAvailableTo(e.target.value)}
                    />
                    <SelectWithoutFilter
                      name="RepetitationInterval"
                      items={repetitationIntervals}
                      label="Powtarzaj"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={repetitationInterval}
                      // @ts-ignore
                      setSelectedItem={setRepetitationInterval}
                    />
                  </>
                )}
              </SingleColumn>

              <SingleColumn style={{ alignItems: "center" }}>
                {!isStructure && (
                  <>
                    <p>ZAPROSZENIA</p>
                    {/* {isCyclical && (
                <NewSelectStatystics
                  name="OrganizerType"
                  items={organizerTypes}
                  label="Organizator"
                  style={{ width: "280px", paddingBottom: "9px" }}
                  // @ts-ignore
                  selectedItem={organizerType}
                  setSelectedItem={setOrganizerType}
                />
              )} */}

                    <NewSelectStatystics
                      name="Visibility"
                      items={visibilities}
                      label="Widoczność"
                      style={{ width: "280px", paddingBottom: "9px" }}
                      // @ts-ignore
                      selectedItem={visibility}
                      setSelectedItem={setVisibility}
                    />

                    {visibility?.value === 2 && (
                      <>
                        <Button
                          onClick={() => setIsGroupModalOpen(true)}
                          variant={ButtonVariant.Submit}
                          style={{
                            // borderRadius: 0,
                            width: "280px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            marginTop: "13px",
                            color: "white",
                            background: "rgb(0, 160, 255)",
                            marginBottom: "8px",
                          }}
                        >
                          Wybierz grupy
                        </Button>
                        {/* <NewSelectStatystics
                        name="Grupa"
                        items={academyFolders}
                        label="Grupa"
                        style={{ width: "280px", paddingBottom: "9px" }}
                        // @ts-ignore
                        selectedItem={academyFolder}
                        setSelectedItem={setAcademyFolder}
                      />

                      <PlayersBox>
                        {allFolders.map((folder: any) => (
                          <PlayerBox>
                            <div>
                              <p>{folder.Name}</p>
                            </div>
                            <div onClick={() => deleteFolder(folder.Id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="20.571"
                                viewBox="0 0 16 20.571"
                              >
                                <path
                                  id="Icon_material-delete"
                                  data-name="Icon material-delete"
                                  d="M8.643,22.786a2.292,2.292,0,0,0,2.286,2.286h9.143a2.292,2.292,0,0,0,2.286-2.286V9.071H8.643ZM23.5,5.643h-4L18.357,4.5H12.643L11.5,5.643h-4V7.929h16Z"
                                  transform="translate(-7.5 -4.5)"
                                  fill="#dc2121"
                                />
                              </svg>
                            </div>
                          </PlayerBox>
                        ))}
                      </PlayersBox>
                    </> */}
                      </>
                    )}

                    {visibility?.value === 1 && (
                      <>
                        <InputWithSearchResultsWrapper
                          onBlur={onBlur}
                          // style={{ paddingBottom: "10px" }}
                        >
                          <RightSectionAddressAndTitleWriteMode>
                            {/* <img src={logo} alt={logo} /> */}
                            <p>Wyszukaj</p>

                            <input
                              type="text"
                              placeholder="Nazwa"
                              autoComplete="off"
                              value={receiver}
                              onFocus={() => handleOnFocusChange(true)}
                              onChange={handleReceiverChange}
                            />
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.75"
                                height="18"
                                viewBox="0 0 24.75 18"
                              >
                                <path
                                  id="Icon_material-person-add"
                                  data-name="Icon material-person-add"
                                  d="M17.25,15a4.5,4.5,0,1,0-4.5-4.5A4.5,4.5,0,0,0,17.25,15ZM7.125,12.75V9.375H4.875V12.75H1.5V15H4.875v3.375h2.25V15H10.5V12.75Zm10.125,4.5c-3,0-9,1.508-9,4.5V24h18V21.75C26.25,18.757,20.254,17.25,17.25,17.25Z"
                                  transform="translate(-1.5 -6)"
                                  fill="#fff"
                                />
                              </svg>
                            </div>
                          </RightSectionAddressAndTitleWriteMode>
                          <SearchResultWrapper
                            isFocused={isFocused}
                            autoCompleted={receiver.length > 1}
                            tabIndex={0}
                          >
                            {playerProfiles.length === 0 &&
                              receiver.length > 1 && (
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Brak wyników
                                </p>
                              )}

                            {playerProfiles.map((playerProfile: any) => {
                              const { PlayerId, Name, Type, FtpPhotoFilePath } =
                                playerProfile;

                              return (
                                <SingleSearchResultWrapper
                                  key={PlayerId}
                                  onClick={() => {
                                    handleReceiverIdChange(PlayerId);
                                    setReceiver(`${Name}`);
                                    setPlayerImage("");
                                    setPlayerType("1");
                                    toggleFocused(false);
                                    // createNewConversation(Id, 1);
                                    // toggleAddNewConversation(false);
                                  }}
                                >
                                  <p>{`${Name}`}</p>
                                </SingleSearchResultWrapper>
                              );
                            })}
                          </SearchResultWrapper>
                        </InputWithSearchResultsWrapper>

                        <PlayersBox>
                          {allPlayers.map((player: any) => (
                            <PlayerBox>
                              {/* <img
                              src={player?.Image ? player.Image : logo}
                              alt=""
                              style={{ width: "48px", height: "48px" }}
                            /> */}
                              <div>
                                <p>{player.Name}</p>
                              </div>
                              <div onClick={() => deletePlayer(player.Id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="20.571"
                                  viewBox="0 0 16 20.571"
                                >
                                  <path
                                    id="Icon_material-delete"
                                    data-name="Icon material-delete"
                                    d="M8.643,22.786a2.292,2.292,0,0,0,2.286,2.286h9.143a2.292,2.292,0,0,0,2.286-2.286V9.071H8.643ZM23.5,5.643h-4L18.357,4.5H12.643L11.5,5.643h-4V7.929h16Z"
                                    transform="translate(-7.5 -4.5)"
                                    fill="#dc2121"
                                  />
                                </svg>
                              </div>
                            </PlayerBox>
                          ))}
                        </PlayersBox>
                      </>
                    )}
                  </>
                )}
                {!isStructure && (
                  <>
                    <p>LICZBA ZAPROSZEŃ</p>
                    <CheckBoxDiv>
                      <div>
                        <p>Limit miejsc</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="limit"
                          id="limit"
                          checked={isLimitChecked}
                          onChange={handleLimitCheckboxChange}
                        />
                      </div>
                    </CheckBoxDiv>

                    {/* <NewSelectStatystics
                    name="Limit"
                    items={limits}
                    label="Limit"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={limit}
                    // @ts-ignore
                    setSelectedItem={setLimit}
                  /> */}
                    {isLimitChecked && (
                      <TextField name="CountLimit" label={"Liczba miejsc"} />
                    )}
                  </>
                )}

                <p>KALENDARZ</p>
                <CheckBoxDiv>
                  <div>
                    <p>Wpis do kalendarza</p>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="calendar"
                      id="calendar"
                      checked={isCalendarChecked}
                      onChange={handleCalendarCheckboxChange}
                    />
                  </div>
                </CheckBoxDiv>
                {isCalendarChecked && (
                  <SelectWithoutFilter
                    name="DaysBeforeEvent"
                    items={allDaysBeforeEvent}
                    label="Na ile dni przed eventem"
                    style={{ width: "280px", paddingBottom: "9px" }}
                    // @ts-ignore
                    selectedItem={daysBeforeEvent}
                    setSelectedItem={setDaysBeforeEvent}
                  />
                )}
              </SingleColumn>

              <SingleColumn>
                <div
                  style={{
                    width: "280px",
                    paddingBottom: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "15px",
                      fontWeight: 400,
                      width: "calc(280px - 22.5px)",
                    }}
                  >
                    POST
                  </p>

                  <input
                    style={{ width: "22.5px", height: "22.5px" }}
                    type="checkbox"
                    name="post"
                    id="post"
                    checked={isPostChecked}
                    onChange={handlePostCheckboxChange}
                  />
                </div>
                {isPostChecked && (
                  <>
                    <NewImageField
                      name="photos"
                      titleName="Grafika zajęcia"
                      className="mx-auto md:mx-0 mb-8"
                      imgSrc={values.image}
                      base64={base64}
                      setBase64={setBase64}
                    />
                    <TextAreaField
                      name="Content"
                      label={"Opis"}
                      isError={false}
                      style={{ paddingBottom: "9px" }}
                    />
                  </>
                )}

                <TextAreaField
                  name="Note"
                  label={"Uwagi"}
                  isError={false}
                  style={{ paddingBottom: "9px" }}
                />
              </SingleColumn>
            </ColumnsContainer>
          </Container>
          <div
            style={{
              display: "flex",
              marginLeft: "15px",
              height: "65px",
              minHeight: "65px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",

                height: "100%",
                borderTop: "5px solid rgba(163,158,157,0.3)",
                width: "calc(100% - 59px)",
              }}
            >
              {isCyclical && (
                <Button
                  onClick={() => handleDraftSubmit(values)}
                  variant={ButtonVariant.Add}
                  style={{
                    width: "280px",
                    marginTop: "13px",
                    marginRight: "8px",
                  }}
                >
                  Zapisz jako draft
                </Button>
              )}

              <SubmitButton
                isSubmitting={isSubmitting}
                variant={ButtonVariant.Save}
                style={{
                  width: "280px",
                  marginTop: "13px",
                }}
                submitText="Tworzenie zajęcia..."
              >
                Utwórz zajęcie
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DigitalReleaseForm;

const InputWithSearchResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 11px;
    color: white;
    width: 80px;
  }

  & > input {
    color: white;
    font-size: 11px;
    width: 100%;
    outline: none;
    background: transparent;
    border: none;
    color: white;
  }
`;

const PlayersBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  /* height: 250px; */
  max-height: 250px;
  overflow-y: auto;
  /* background: red; */
  margin-top: 9px;
`;

const PlayerBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  /* background: blue; */
  background: rgba(163, 158, 157, 0.1);
  cursor: pointer;
  margin-bottom: 9px;
  display: flex;

  & > img {
    width: 48px;
    height: 48px;
  }

  & > div:nth-of-type(1) {
    width: calc(100% - 52px);
    height: 100%;
    display: flex;
    align-items: center;

    & > p {
      color: #0091ff;
      padding-left: 10px;
      width: 100%;
      margin-right: 17px;
      text-align: right;
      font-size: 13px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 52px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(163, 158, 157, 0.3);
  }
`;

const RightSectionAddressAndTitleWriteMode = styled.div`
  display: flex;
  width: 100%;
  background: rgba(163, 158, 157, 0.1);
  height: 48px;
  position: relative;

  & > div {
    height: 100%;
    width: 52px;
    background: #0091ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* padding: 5px 11.5px; */
  /* border-radius: 5px; */
  /* border: 1px solid rgba(112, 112, 112, 0.3); */

  & > p {
    font-size: 11px;
    color: rgb(163, 158, 157);
    position: absolute;
    top: 7px;
    left: 9px;
    font-weight: 400;
    width: 80px;
  }
  & > img {
    width: 48px;
    height: 100%;
    /* background: white; */
  }

  & > input {
    color: white;
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    padding-right: 17px;
    width: calc(100% - 52px);
    outline: none;
    background: transparent;
    border: none;

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }
`;

const SingleSearchResultWrapper = styled.div`
  width: 100%;
  height: 20px;
  background: rgba(163, 158, 157, 0.1);
  /* border-radius: 5px; */
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  z-index: 5;
  cursor: pointer;

  & > p {
    font-size: 11px;
    color: white;
  }

  &:hover {
    /* background: #16265c; */
    background: #89bfff;
  }
`;

const EventTypeBox = styled.div<{ isCyclical: boolean }>`
  width: 280px;
  background: rgba(163, 158, 157, 0.11);
  height: 48px;
  min-height: 48px;
  display: flex;
  margin-bottom: 9px;

  & > div:nth-of-type(1) {
    height: 100%;
    width: 140px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: ${({ isCyclical }) => (isCyclical ? "none" : "1px solid #0091FF")};

    & > p {
      color: ${({ isCyclical }) => (isCyclical ? "#a39e9d" : "#0091FF")};
      font-size: 15px;
    }
  }

  & > div:nth-of-type(2) {
    height: 100%;
    cursor: pointer;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({ isCyclical }) => (isCyclical ? "1px solid #0091FF" : "none")};
    & > p {
      color: ${({ isCyclical }) => (isCyclical ? "#0091FF" : "#a39e9d")};
      font-size: 15px;
    }
  }
`;

const DateBox = styled.div`
  width: 280px;
  height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;



  input {
    font-size: 12px;
    text-align: right;
    padding-right: 0 !important;
  }

  & > div {
    width: 136px;
    & > div {
      min-width: 136px !important;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const NameBox = styled.div`
  padding: 0 15px;
  display: flex;
  gap: 20px;
  /* align-items: center; */
`;

const CheckBoxDiv = styled.div`
  width: 280px;
  height: 48px;
  display: flex;
  align-items: center;

  & > div:nth-of-type(1) {
    width: calc(100% - 46px);
    text-align: right;

    & > p {
      color: white;
      font-size: 15px;
      font-weight: 600;
    }
  }

  & > div:nth-of-type(2) {
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > input {
      width: 28px;
      height: 28px;
    }
  }
`;

const SearchResultWrapper = styled.div<{
  isFocused: boolean;
  autoCompleted: boolean;
}>`
  width: 100%;
  position: absolute;
  background: rgba(2, 2, 15, 1);
  /* border-radius: 5px; */
  /* border: 1px solid #e1e1e1; */
  backdrop-filter: blur(3px);
  color: white;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 51px;
  /* padding: 5px 0; */
  z-index: 5;
  visibility: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "visible" : "hidden"};
  display: ${({ autoCompleted, isFocused }) =>
    autoCompleted && isFocused ? "flex" : "none"};
`;

const SingleFolder = styled.div`
  cursor: pointer;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(255, 0, 0, 0.5);
`;

const GroupModalContainer = styled.div`
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    /* width: 0px; */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 145, 255, 1);
    border-radius: 0;
  }
`;
