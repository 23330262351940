import HeaderContainer from "components/common/Containers/HeaderContainer";
import InfoContainerItem from "components/common/Containers/InfoContainerItem";
import { InfoContainer } from "styles/styles";
import { BUSINESS_TYPE } from "types/merchant.interface";
import { IAcademy } from "types/academy.interface";

interface IAcademyCompanyDataProps {
  academy: IAcademy;
}

const AcademyCompanyData = ({ academy }: IAcademyCompanyDataProps) => {
  const isNIPVisible = academy.Merchant.BusinessType !== 1;

  const isREGONVisible = academy.Merchant.BusinessType !== 1;

  const isKRSVisible = academy.Merchant.BusinessType > 3;

  return (
    <HeaderContainer title="Dane firmowe">
      <InfoContainer className="w-full">
        <InfoContainerItem header="Nazwa pełna" content={academy.Name} />

        <InfoContainerItem
          header="Typ"
          content={BUSINESS_TYPE[academy.Merchant.BusinessType]}
        />

        {isNIPVisible && (
          <InfoContainerItem header="NIP" content={academy.NIP} />
        )}

        {isREGONVisible && (
          <InfoContainerItem header="Regon" content={academy.REGON} />
        )}

        {isKRSVisible && (
          <InfoContainerItem header="KRS" content={academy.KRS} />
        )}

        <InfoContainerItem
          header="Nr rach. bankowego"
          content={academy.Merchant.BankAccount}
        />
        <div style={{ height: "45px" }}>
          <InfoContainer />
        </div>
        <div style={{ height: "45px" }}>
          <InfoContainer />
        </div>
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyCompanyData;
