import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ISortingProps } from "types/sortType";
import HomeSearchIco from "assets/icons/home-search-ico.png";
import { ISelectOption, LIST_SORT } from "types/globalTypes";
import { BlurBox, SearchBox, SearchInput, SideMenu } from "styles/styles";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import { LIST_VIEW } from "types/transfer/player.interface";
import { LIST_VIEW as a } from "types/transfer/trainer.interface";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import Button from "components/common/buttons/basicButton/Button";
import ScannerSideMenuTrainer from "../../trainers/detail/ScannerSideMenuTrainer";
import { IFilterPlayerValue } from "types/filterPassData";
import FilterButton from "components/common/buttons/basicButton/FilterButton";

interface IViewLabel {
  label: string;
  value: number;
}
interface IScannerTopbarProps extends ISortingProps<LIST_SORT> {
  handleQueryChange: (value: string) => void;
  setListView: Dispatch<SetStateAction<ISelectOption<LIST_VIEW> | null>>;
  listView: ISelectOption<LIST_VIEW> | null;
  listViews: ISelectOption<string>[];
  refreshScanner: () => Promise<void>;
  refreshFilters: () => Promise<void>;
  filter: ISelectOption<string> | null;
  setFilter: React.Dispatch<React.SetStateAction<ISelectOption<string> | null>>;
  filters: ISelectOption<string>[];
  isQueryEmpty: boolean;
  viewLabels: IViewLabel[];
  handleFilterData?: (obj: IFilterPlayerValue) => void;
  isFilterTrainerActive?: boolean;
}

const ScannerTopbarTrainer = ({
  defaultSort,
  handleQueryChange,
  setSortBy,
  sortBy,
  sorts,
  setListView,
  listView,
  listViews,
  refreshScanner,
  refreshFilters,
  filter,
  filters,
  setFilter,
  isQueryEmpty,
  viewLabels,
  handleFilterData,
  isFilterTrainerActive,
}: IScannerTopbarProps) => {
  const [isScannerMenuOpen, toggleScannerMenuOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [query, setQuery] = useState("");

  const [visibility, setVisibility] = useState(viewLabels[0]?.label || "");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
    handleQueryChange(newValue);
  };

  useEffect(() => {
    setQuery("");
  }, [isQueryEmpty]);

  const changeVisibility = () => {
    const nextView = isChecked ? viewLabels[0] : viewLabels[1];
    setIsChecked(!isChecked);
    setListView({
      label: nextView.label,
      value: nextView.value,
    });
    setVisibility(nextView.label);
  };

  const sortFilters = [
    {
      id: 1,
      type: "select",
      name: "LicenseType",
      values: [
        { backend: 0, pl: "UEFA" },
        { backend: 1, pl: "UEFA_PRO" },
      ],
      pl: "Typ licencji",
    },
    {
      id: 2,
      type: "select",
      name: "TrainerType",
      values: [
        { backend: 0, pl: "Trener" },
        { backend: 1, pl: "Scout" },
        { backend: 2, pl: "Manager" },
        { backend: 3, pl: "Analityk" },
      ],
      pl: "Typ trenera",
    },

    {
      id: 3,
      type: "rangeOfAmount",
      name: "Area",
      pl: "Promień",
    },
  ];

  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2 pr-24 "
      style={{ gap: "3px" }}
    >
      <BlurBox
        isMenuOpen={
          isScannerMenuOpen
          // &&
          // listView?.value === viewLabels[0].value
        }
        onClick={() => toggleScannerMenuOpen((prev) => !prev)}
      />

      <SideMenu isMenuOpen={isScannerMenuOpen}>
        <ScannerSideMenuTrainer
          // refreshScanner={refreshScanner}
          // refreshFilters={refreshFilters}
          // filter={sortFilters}
          // setFilter={setFilter}
          filterArray={sortFilters}
          // handleFilterData={handleFilterData}
          // isOpen={isScannerMenuOpen}
        />
        {/* <NewFilterPanel filters={sortFilters} /> */}
      </SideMenu>

      <div
        className="flex mr-0 xl:mr-4 "
        style={{
          alignContent: "center",
          border: "2px solid #323a44",
          borderRadius: "2px",
        }}
      >
        {/* <div
          style={{
            backgroundColor: '#222c38',
            transition: 'background-color 0.3s ease',
            padding: '3px',
          }}
        >
          <button
            onClick={changeVisibility}
            style={{
              color: 'white',
              height: '38px',
              padding: '7px 2px',
              width: '188px',
              backgroundColor: isChecked ? '#222c38' : 'rgba(163, 158, 157, 0.38)',
              pointerEvents: !isChecked ? 'none' : 'auto',
              transition: 'background-color 0.3s ease',
              borderRadius: isChecked ? '0px' : '2px',
            }}
            disabled={!isChecked}
          >
            {viewLabels[0]?.label}
          </button>

          <button
            onClick={changeVisibility}
            style={{
              color: 'white',
              height: '38px',
              padding: '0px 18px',
              backgroundColor: !isChecked ? '#222c38' : 'rgba(163, 158, 157, 0.38)',
              pointerEvents: isChecked ? 'none' : 'auto',
              transition: 'background-color 0.3s ease',
              borderRadius: isChecked ? '2px' : '0px',
            }}
            disabled={isChecked}
          >
            {viewLabels[1]?.label}
          </button>
        </div> */}
      </div>

      <div>
        <div style={{ height: "48px" }}></div>

        <div className="flex gap-1 items-center">
          {sorts.length ? (
            <NewSelectStatystics<LIST_SORT>
              name="Sort"
              items={sorts}
              label="Sortowanie"
              selectedItem={sortBy}
              setSelectedItem={setSortBy}
              defaultValue={defaultSort}
            />
          ) : null}
          <div style={{ display: isFilterTrainerActive ? "block" : "none" }}>
            <SearchBox>
              <SearchInput
                className="w-full md:w-36 xl:w-72"
                placeholder="Szukaj..."
                autoComplete="off"
                name="search"
                onChange={handleSearch}
                value={query}
              />
              <div>
                <img src={HomeSearchIco} alt="ico" />
              </div>
            </SearchBox>
          </div>

          {(listView?.value === viewLabels[0].value ||
            listView?.value === undefined) && (
            <FilterButton
              variant={ButtonVariant.Abort}
              onClick={() =>
                // listView?.value === viewLabels[1].value &&
                toggleScannerMenuOpen(true)
              }
              className="transition delay-150 duration-700 ease-in-out"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScannerTopbarTrainer;
