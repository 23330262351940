import { ArtistPropItem } from "types/artistTypes";
import {
  DigitalReleaseRequest,
  IDigitalRelease,
  IAddProduct,
} from "types/digitalReleaseTypes";
import { IListPageRequest, IListPageResponse } from "types/globalTypes";

import { conn } from "../api/BaseConnection";
import jwtDecode from "jwt-decode";

const endpoint = conn.endpoints.cyclicalEvent;

const token: any = localStorage?.getItem("token");
const decodedToken: any = token && jwtDecode(token);
console.log("tokendeccyc", decodedToken);

const add = (digitalRelease: IDigitalRelease | any) => {
  return conn.postJSON(endpoint, "json", digitalRelease);
};

const remove = (id: string) => {
  return conn.deleteJSON(`${endpoint}/${id}`);
};

const edit = (digitalRelease: Partial<DigitalReleaseRequest>) => {
  return conn.putJSON(endpoint, "json", digitalRelease);
};

const getAll = (
  pageInfo: IListPageRequest,
): Promise<IListPageResponse<IDigitalRelease>> => {
  return conn.getJSON<IListPageResponse<IDigitalRelease>>(
    endpoint,
    "json",
    pageInfo,
  );
};

const deleteCyclicalEvent = (EventCyclicalId: string) => {
  return conn.deleteJSON(`${endpoint}/${EventCyclicalId}`, "json");
};

const editCyclicalEvent = (
  array: any,
  imageUrl: any,
  OccurenceDate: any,
  EndDate: any,
  Visibility: any,
  Level: any,
  Type: any,
  SurfaceId: any,
  SurfaceDimensionsId: any,
  Limit: any,
  allPlayers: any,
  OrganizerType: any,
  RepetitionInterval: any,
  Latitude: number,
  Longitude: number,
  IsCalendarChecked: boolean,
  TimeInterval: number,
  IsPostChecked: boolean,
  IsDraft: boolean,
  CyclicalEventId: string,
  FilesArray: any,
  allFolders: any,
) => {
  delete array.photos;
  delete array.Localization;
  delete array.country;
  delete array.Address;
  delete array.Attendances;
  delete array.Author;
  delete array.City;
  delete array.Invitations;
  delete array.EventId;
  delete array.AttendanceCount;
  delete array.Organizer;
  delete array.Country;
  delete array.OrganizerType;

  const LimitAmount = array.Limit.Limit;

  let allFolderIds = allFolders.map((folder: any) => folder.Id);

  delete array.CountLimit;
  let filesArray: any = [];
  if (IsPostChecked && imageUrl) {
    filesArray = [
      {
        Name: "test",
        FilePath: imageUrl,
        FilePathMin: imageUrl,
      },
    ];
  } else if (IsPostChecked && FilesArray.length > 0) {
    filesArray = [
      {
        Name: FilesArray[0].Name,
        FilePath: FilesArray[0].FilePath,
        FilePathMin: FilesArray[0].FilePathMin,
      },
    ];
  } else {
    filesArray = [];
  }

  const body = {
    ...array,
    IsDraft,
    CyclicalEventId,
    AddToCalendar: IsCalendarChecked,
    TimeInterval,
    Latitude,
    Longitude,
    OrganizerType,
    OccurenceDate,
    EndDate,
    RepetitionInterval,
    Visibility: Visibility,
    Level,
    Type,
    HasLimit: Limit,
    Limit: Limit ? +LimitAmount : 0,
    Files: IsPostChecked ? filesArray : [],
    DeleteFiles: [],
    Revoke: [],
    // Fields: SurfaceId
    //   ? [
    //       {
    //         SurfaceId,
    //         ValueId: SurfaceDimensionsId,
    //         PassedByHandValue: "",
    //       },
    //     ]
    //   : [],
    Fields: [],
    Invite:
      Visibility === 1
        ? allPlayers.map((player: any) => ({
            Id: player.Id,
            Type: 1,
          }))
        : [],
    Content: IsPostChecked ? array.Content : "",
    GroupIds: Visibility === 2 ? allFolders : [],
  };

  return conn.putJSON(`${endpoint}/UpdateEventCyclical`, "json", body);
};

const addCyclicalEvent = (
  array: any,
  imageUrl: any,
  OccurenceDate: any,
  EndDate: any,
  Visibility: any,
  Level: any,
  Type: any,
  SurfaceId: any,
  SurfaceDimensionsId: any,
  Limit: any,
  allPlayers: any,
  OrganizerType: any,
  RepetitationInterval: any,
  Latitude: number,
  Longitude: number,
  IsCalendarChecked: boolean,
  TimeInterval: number,
  IsPostChecked: boolean,
  IsDraft: boolean,
  FolderIds: boolean,
  PostCode: any,
  Localization: any,
) => {
  delete array.photos;
  delete array.country;
  delete array.Address;
  delete array.City;
  delete array.Country;

  const LimitAmount = array.CountLimit;
  delete array.CountLimit;
  let filesArray: any = [];
  if (IsPostChecked && imageUrl) {
    filesArray = [
      {
        FileName: "test",
        FilePath: imageUrl,
        FilePathMin: imageUrl,
      },
    ];
  }
  console.log("LIMIT>>>limitamout", array.LimitAmount);
  console.log("LIMIT>>>countlimit", array.CountLimit);
  const body = {
    ...array,
    IsDraft,
    AddToCalendar: IsCalendarChecked,
    TimeInterval,
    AcademyId: decodedToken?.AcademyId,
    ProfileType: +decodedToken?.Role,
    Latitude,
    Longitude,
    OrganizerType,
    OccurenceDate,
    EndDate,
    RepetitionInterval: RepetitationInterval,
    SurfaceId: SurfaceId,
    SurfaceDimensionsId: SurfaceDimensionsId,
    Visibility: Visibility === 3 ? 1 : Visibility,
    Level,
    Type,
    HasLimit: Limit,
    Limit: Limit && +LimitAmount ? +LimitAmount : 0,
    Files: filesArray,
    Fields: [],
    Invited:
      Visibility === 1
        ? allPlayers.map((player: any) => ({
            Id: player.Id,
            Type: 1,
          }))
        : [],
    GroupIds: Visibility === 2 ? FolderIds : [],
    PostCode: PostCode ? PostCode : array.PostCode,
    Localization: Localization ? Localization : array.Localization,
    InviteAllAcademyPlayers: Visibility === 3 ? true : false,
  };

  return conn.postJSON(`${endpoint}/CreateEventCyclical`, "json", body);
};

const cyclicalEventServices = {
  add,
  edit,
  remove,
  getAll,
  addCyclicalEvent,
  editCyclicalEvent,
  deleteCyclicalEvent,
};

export default cyclicalEventServices;
