import DataTable, {
  IDataTableRows,
} from "components/common/dataTable/DataTable";
import React, { useEffect, useState } from "react";
import { IDelivery } from "types/marketpleace/delivery.interface";
import placeholderImage from "../../../assets/images/placeholder.svg";
import { deliveryHeaders } from "./utils/helpers";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { PRODUCT_TYPES } from "types/marketpleace/product.interface";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";
import EditButton from "components/common/buttons/basicButton/EditButton";

interface IDeliveriesTableProps {
  deliveries: IDelivery[];
  isLoading: boolean;
  openEditMenu: (delivery: IDelivery) => void;
  removeDelivery: (id: string) => void;
}

const DeliveriesTable = ({
  deliveries,
  isLoading,
  openEditMenu,
  removeDelivery,
}: IDeliveriesTableProps) => {
  const tableRows = deliveries.map(
    (delivery) =>
      ({
        cols: [
          <div className="flex justify-center w-full h-24 overflow-hidden">
            <img
              className="h-full"
              src={
                delivery.PhotoFilePath.length
                  ? delivery.PhotoFilePath
                  : placeholderImage
              }
              alt={delivery.Name}
            />
          </div>,
          delivery.Name,
          delivery.Description,
          // PRODUCT_TYPES[delivery.AvailableFor],
          `${delivery.Deadline} H`,
          `${delivery.Netto.toFixed(2)} zł`,
          `${delivery.Tax} %`,
          `${delivery.Gross} Zł`,
          `${delivery.ConditionForFree.toFixed(2)} zł`,
          <div className="flex items-center justify-center w-full h-full gap-2 ">
            {delivery.AvailableFor === PRODUCT_TYPES["Elektroniczny"] ? null : (
              <EditButton
                onClick={() => openEditMenu(delivery)}
                className="p-8 w-2/5"
                variant={ButtonVariant.Submit}
              />
            )}
            <DeleteButton
              onClick={() => removeDelivery(delivery.DeliveryId)}
              className="p-8 w-2/5"
              variant={ButtonVariant.Remove}
            />
          </div>,
        ],
      } as IDataTableRows)
  );

  return (
    <DataTable
      rows={tableRows}
      headers={deliveryHeaders}
      isDataLoading={isLoading}
    />
  );
};

export default DeliveriesTable;
