import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #9cb0c4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15.004"
        viewBox="1074.002 449.503 15 15.004"
      >
        <path
          d="m1088.826 463.42-4.171-4.21a5.977 5.977 0 0 0-4.672-9.707 5.98 5.98 0 0 0-5.98 5.98c0 3.3 2.675 5.977 5.98 5.977 1.43 0 2.738-.5 3.77-1.336l4.144 4.183a.64.64 0 0 0 .906.024.646.646 0 0 0 .023-.91Zm-8.843-3.246a4.666 4.666 0 0 1-3.32-1.375 4.663 4.663 0 0 1-1.375-3.316 4.65 4.65 0 0 1 1.375-3.316 4.666 4.666 0 0 1 3.32-1.375c1.254 0 2.433.488 3.32 1.375a4.663 4.663 0 0 1 1.375 3.316 4.65 4.65 0 0 1-1.375 3.316 4.666 4.666 0 0 1-3.32 1.375Z"
          fill="#02020f"
          fill-rule="evenodd"
          data-name="Icon ionic-ios-search"
        />
      </svg>
    </Button>
  );
};

export default SearchButton;
