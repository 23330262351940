import HeaderContainer from 'components/common/Containers/HeaderContainer';
import DataTable from 'components/common/dataTable/DataTable';
import TabContent from 'components/common/tabs/TabContent';
import {
  IPlayerDetail,
  PLAYER_STATE,
  PLAYER_STATE_CHANGE,
} from 'types/transfer/player.interface';
import PlayerStateHeader from './PlayerStateHeader';
import { playerStateConditions } from 'middleware/transfer/playerTransferConditions';
import { getFormatedDate } from 'utils/dateUtils';

interface IPlayerHistoryStateProps {
   player: IPlayerDetail;
  refresh: () => Promise<void>;
  historyStatus: any | null
}

const PlayerHistoryState = ({ player, refresh, historyStatus }: IPlayerHistoryStateProps) => {


  const getStateChangeOptions = playerStateConditions(
    historyStatus?.PlayerId,
    historyStatus?.Level,
    historyStatus?.TransferRequest,
    historyStatus?.IsExclude
  ).filter(state => !!state.conditions.every(c => c === true));

  const selectItems = getStateChangeOptions.map(state => ({
    label: PLAYER_STATE_CHANGE[state.id],
    value: state.id,
  }));

  const tableRows = historyStatus?.PlayerHistories.map((state: any) => ({
    cols: [
      getFormatedDate(state.UpdatedAt, 'date-time'),
      PLAYER_STATE[state.State],
    ],
  }));

  return (
    <TabContent id='historyState'>
      <div className='flex w-full'>
        <HeaderContainer
          title='Historia zawodnika'
          withContainer={!historyStatus?.PlayerHistories.length}
          headerContent={
            <PlayerStateHeader
              refresh={refresh}
              stateChangeOptions={getStateChangeOptions}
              selectItems={selectItems}
            />
          }>
          {!historyStatus?.PlayerHistories.length ? (
            'Brak historii statusów'
          ) : (
            <div style={{
              maxHeight: '650px',
              overflowY:'auto',
              overflowX: 'auto',
              }}
            >
              <DataTable
                headers={['Data', 'Status']}
                rows={tableRows}
                isDataLoading={false}
              />
            </div>
          )}
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default PlayerHistoryState;
