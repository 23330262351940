import { FormikHelpers, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect, useRef, useState } from "react";
import TextArea from "components/common/inputs/textArea/TextArea";
import DropdownPanel, { Modes } from "components/common/panels/DropdownPanel";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { showServerErrors } from "utils/errorsUtils";
import styled from "styled-components";
import ProductImageInput from "components/common/inputs/imageInput/ProductImageInput";
// @ts-ignore
import Modal from "react-modal";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import { addFile, deleteFile } from "services/eventServices";
import systemServices from "services/systemServices";
import { getFormatedDate } from "utils/dateUtils";
import eventsServices from "services/eventsServices";
import { utcToLocalDate } from "../EventsDetail";

interface Props {
  event: any;
  getEventDetailFn: any;
}

const ProductDescription: React.FC<Props> = ({ event, getEventDetailFn }) => {
  const [base64, setBase64] = useState("");
  const fileInputRef = useRef(null);
  const presspack = useSelector((state: RootState) => state.artist.presspack);
  const permissions = useSelector(
    (state: RootState) => state.userPermissions?.Artist,
  );
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [addedImageBase64, setAddedImageBase64] = useState("");
  const [editContent, setEditContent] = useState(event?.Content);
  const endDateLocal = utcToLocalDate(event?.OccurenceDate);
  const backendDate = new Date(endDateLocal);
  const descriptionPanelRef = useRef<any>(null);
  const photosPanelRef = useRef<any>(null);
  const today = new Date();

  const isDateExpired = today > backendDate;

  const handleEditContentChange = (e: any) => {
    setEditContent(e.target.value);
  };

  const editContentFn = async () => {
    await eventsServices.editContent(id, editContent);
    setIsEditing(false);
    toast.success("Zmieniono opis zajęcia!");
    await getEventDetailFn();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
    },
  };

  const handleSubmit = async () => {
    if (
      formik.values.biography.length < 5 ||
      formik.values.biography.length > 700
    )
      return toast.error("Opis musi mieć od 5 do 700 znaków!");

    try {
      // await productServices.updateDescription(id, formik.values.biography);
      toast.success("Zmieniono opis produktu!");
      push("/shop/products/");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const deletePost = async () => {
    await eventsServices.deleteContentAndFiles(id);
    await getEventDetailFn();
    await toast.success("Usunięto");
  };

  const openModal = (photo: any) => {
    setSelectedImage(photo);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const formik = useFormik({
    initialValues: { biography: event.Content || "" },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const addImageFn = async (base64: any) => {
    const fileUrl = await systemServices.uploadFile(base64);

    await addFile(event.EventId, "test", fileUrl, fileUrl);
    getEventDetailFn();
  };

  useEffect(() => {
    if (addedImageBase64) {
      addImageFn(addedImageBase64);
    }
  }, [addedImageBase64]);

  return (
    <div style={{ padding: "24" }}>
      <div
        className=" opacity-80"
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "rgba(2,2,15,0.3)",
          color: "white",
          opacity: 1,
          height: "60px",
          alignItems: "center",
          borderLeft: "5px solid rgb(255,146,38)",
          marginBottom: "10px",
          padding: "17px 37px",
        }}
      >
        <span
          style={{
            textTransform: "uppercase",
            fontSize: "15px",
            fontWeight: 300,
            letterSpacing: -0.5,
          }}
        >
          POST
        </span>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          {!event?.PostPublishDate ? (
            <p style={{ marginRight: "50px" }}>Post nieopublikowany</p>
          ) : (
            <p style={{ marginRight: "50px" }}>
              Opublikowany:{" "}
              {getFormatedDate(
                event?.PostPublishDate?.slice(0, 10),
                "dd.MM.yyyy",
              )}
            </p>
          )}

          {!isDateExpired && (
            <>
              {isEditing && (
                <Button
                  onClick={() => {
                    setIsEditing(false);
                  }}
                  variant={ButtonVariant.Cancel}
                  style={{ cursor: "pointer", userSelect: "none" }}
                >
                  Anuluj
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsEditing((prev) => !prev);
                  if (descriptionPanelRef?.current) {
                    descriptionPanelRef.current.collapse();
                  }
                  if (photosPanelRef?.current) {
                    photosPanelRef.current.collapse();
                  }

                  if (isEditing) {
                    editContentFn();
                  }
                }}
                variant={
                  isEditing
                    ? ButtonVariant.Save
                    : event?.PostPublishDate
                    ? ButtonVariant.Edit
                    : ButtonVariant.Add
                }
                style={{ cursor: "pointer", userSelect: "none" }}
              >
                {isEditing
                  ? "Zapisz"
                  : event?.PostPublishDate
                  ? "Edytuj"
                  : "Dodaj"}
              </Button>
            </>
          )}
          {event?.PostPublishDate && (
            <Button onClick={() => deletePost()} variant={ButtonVariant.Delete}>
              Usuń
            </Button>
          )}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "48%" }}>
          <DropdownPanel
            ref={descriptionPanelRef}
            canAdd={false}
            canEdit={false}
            label="Opis zajęcia"
            // editable
            initialExpanded
            onSubmit={formik.handleSubmit}
            onClear={() => {
              formik.setFieldValue("biography", "");
            }}
            render={({ mode }) =>
              mode === Modes.View ? (
                <div style={{ width: "100%" }}>
                  {isEditing ? (
                    <StyledTextArea
                      value={editContent}
                      onChange={handleEditContentChange}
                    />
                  ) : (
                    <p
                      className="text-sm opacity-70 py-24 px-36"
                      style={{
                        overflowWrap: "break-word",
                        width: "100%",
                      }}
                    >
                      {event?.Content ? event.Content : "-"}
                    </p>
                  )}
                </div>
              ) : (
                <p
                  className="text-sm opacity-70 py-24 px-36"
                  style={{
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {event.Content}
                </p>
              )
            }
          />
        </div>

        <div style={{ width: "50%" }}>
          <DropdownPanel label="Zdjęcia" initialExpanded ref={photosPanelRef}>
            <div className="py-30 px-18 gap-8 flex flex-wrap justify-center xl:justify-start ">
              {isEditing && (
                <ProductImageInput
                  key={""}
                  disabled={false}
                  onChange={(e) => {
                    const { value } = e.target;
                    setAddedImageBase64(value.base64String);
                  }}
                  name={""}
                  imgSrc={""}
                  withRemoveIcon={false}
                  // @ts-ignore
                  setBase64={setBase64}
                />
              )}

              <GridContainer>
                {event.Files.length === 0 && !isEditing && <p>Brak zdjęć</p>}

                {event.Files.map((photo: any, index: any) => {
                  console.log("foto", photo);
                  return (
                    <GridItem key={index} onClick={() => openModal(photo)}>
                      <img src={photo.FilePath} alt={`Zdjęcie ${index + 1}`} />
                      {isEditing && (
                        <div
                          onClick={async (e) => {
                            e.stopPropagation();
                            console.log("photo,", photo);
                            await deleteFile(id, photo?.FileId);
                            getEventDetailFn();
                            // getEventDetailFn();
                          }}
                        >
                          <p>x</p>
                        </div>
                      )}
                    </GridItem>
                  );
                })}
              </GridContainer>
              <Modal
                isOpen={selectedImage !== null}
                onRequestClose={closeModal}
                style={customStyles}
              >
                {selectedImage && (
                  <img
                    src={selectedImage?.FilePath}
                    alt={`Zdjęcie ${event.Files.indexOf(selectedImage) + 1}`}
                  />
                )}
              </Modal>
            </div>
          </DropdownPanel>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;

const TextAreaContainer = styled.div`
  width: 100%;

  & > div {
    & > div {
      & > div {
        background: transparent !important;
        & > div {
          background: transparent !important;
        }
      }
    }
  }
`;

const GridContainer = styled.div`
  display: grid;

  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  /* padding: 16px; */
`;

const GridItem = styled.div`
  position: relative;

  & > img {
    border-radius: 4px;
    width: auto;
    height: 238px;
  }

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid black;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: red;
    cursor: pointer;
  }
`;

const NewGridItem = styled.div`
  cursor: pointer;
  background-color: #f0f0f0;
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledTextArea = styled.textarea`
  background: transparent;
  resize: none;
  width: 100%;
  outline: none;
  padding-top: 20px;
  width: 100%;
  height: 300px;
`;
