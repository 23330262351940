import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BlurBox, SideMenu } from "styles/styles";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import Button from "components/common/buttons/basicButton/Button";
import { deliveryServices } from "services/marketpleace";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import DeliveryForm, { DeliveryType } from "./DeliveryForm/DeliveryForm";
import {
  IAddDelivery,
  IDelivery,
  IUpdateDelivery,
} from "types/marketpleace/delivery.interface";
import { IUpdateCombination } from "types/marketpleace/combination.interface";
import { deliveryInitialValues } from "./utils/helpers";
import AddDelivery from "./DeliveryForm/AddDelivery";
import EditDelivery from "./DeliveryForm/EditDelivery";
import { PRODUCT_TYPES } from "types/marketpleace/product.interface";

interface IDeliveriesTopbarProps {
  deliveries: IDelivery[];
  refreshDeliveries: () => void;
  hideSideMenu: () => void;
  isAddMenuOpen: boolean;
  toggleAddMenuOpen: Dispatch<SetStateAction<boolean>>;
  isEditMenuOpen: boolean;
  toggleEditMenuOpen: Dispatch<SetStateAction<boolean>>;
  openAddSideMenu: () => void;
  selectedDelivery: IDelivery | null;
}

const DeliveriesTopbar = ({
  deliveries,
  refreshDeliveries,
  hideSideMenu,
  isAddMenuOpen,
  toggleAddMenuOpen,
  isEditMenuOpen,
  toggleEditMenuOpen,
  openAddSideMenu,
  selectedDelivery,
}: IDeliveriesTopbarProps) => {
  const [digitalDeliveryExists, setDigitalDeliveryExists] = useState(true);

  const addDigitalDelivery = async () => {
    try {
      await deliveryServices.addDigital();

      toast.success("Pomyślnie dodano fizyczną dostawę");

      refreshDeliveries();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    if (deliveries.length) {
      setDigitalDeliveryExists(
        !!deliveries.find(
          (d) => d.AvailableFor === PRODUCT_TYPES["Elektroniczny"]
        )
      );
    }
  }, [deliveries]);

  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2"
      style={{ gap: "3px", marginTop: "8px" }}
    >
      <BlurBox
        isMenuOpen={isAddMenuOpen || isEditMenuOpen}
        onClick={hideSideMenu}
      />
      {!selectedDelivery ? null : (
        <SideMenu isMenuOpen={isEditMenuOpen && !isAddMenuOpen}>
          <div className="flex h-full">
            <EditDelivery
              editDelivery={selectedDelivery}
              refresh={refreshDeliveries}
              toggleEditMenuOpen={toggleEditMenuOpen}
            />
          </div>
        </SideMenu>
      )}

      <SideMenu isMenuOpen={isAddMenuOpen && !isEditMenuOpen}>
        <div className="flex h-full">
          <AddDelivery
            refresh={refreshDeliveries}
            toggleAddMenuOpen={toggleAddMenuOpen}
          />
        </div>
      </SideMenu>

      <Button
        onClick={addDigitalDelivery}
        variant={ButtonVariant.Add}
        disabled={digitalDeliveryExists}
      >
        {!digitalDeliveryExists
          ? "Dodaj elektroniczną dostawe"
          : "Posiadasz elektroniczną dostawę"}
      </Button>

      <Button onClick={openAddSideMenu} variant={ButtonVariant.Add}>
        Dodaj fizyczną dostawę
      </Button>
    </div>
  );
};

export default DeliveriesTopbar;
