import DataTable, {
  IDataTableRows,
} from "components/common/dataTable/DataTable";
import PassesDataTable from "components/common/dataTable/PassesDataTable";
import { format } from "date-fns";
import { sortPassesByListSort } from "middleware/pass/sortPassesByListSort";
import { ITableProps } from "types/globalTypes";
import {
  IPass,
  IPassTransaction,
  PASSES_SORT_LIST,
  PASS_PAYMENT_METHOD,
  PASS_TRANSACTION_STATUS,
} from "types/transfer/pass.interface";
import { getMonthDifference } from "utils/dateUtils";

const PassesTable = ({
  containerRef,
  data,
  isDataLoading,
  lastItemRef,
  sortBy,
  detailPassPermission,
  selectedPassId,
  setSelectedPassId,
  setIsModalOpen,
}: any) => {
  const sortedtransactions = sortPassesByListSort(data, sortBy?.value);

  const headers = [
    "Nr zamówienia",
    "Zawodnik",
    "Typ karnetu",
    "Data początkowa",
    "Data końcowa",
    "Wartość",
    "Płatność",
    "Status",
  ];

  const rows = sortedtransactions.map(
    (transaction: IPassTransaction) =>
      ({
        passId: transaction?.Pass?.PassId,
        data: { link: `/players/detail/${transaction.Buyer.PlayerId}` },
        cols: [
          transaction.Number,
          transaction.Buyer.Name,
          transaction.Pass.Name,
          transaction.Period.StartAt
            ? format(new Date(transaction.Period.StartAt), "dd-MM-yyyy")
            : "-",
          transaction.Period.EndAt
            ? format(new Date(transaction.Period.EndAt), "dd-MM-yyyy")
            : "-",
          `${transaction.Price.Gross} zł`,
          PASS_PAYMENT_METHOD[transaction?.PaymentMethod],
          PASS_TRANSACTION_STATUS[transaction.Status],
        ],
      } as IDataTableRows),
  );

  return (
    <div
      style={{
        pointerEvents: detailPassPermission ? "auto" : "none",
      }}
    >
      <PassesDataTable
        rows={rows}
        headers={headers}
        isDataLoading={isDataLoading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
        selectedPassId={selectedPassId}
        setSelectedPassId={setSelectedPassId}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default PassesTable;
