import { IAdditionalFile } from "types/fileType";
import { ICombination } from "./combination.interface";
import { IAttributeSoldDetailStatstics } from "./attribute.interface";
import { IOrderDetailStatstics } from "./order.interface";

export interface IProductDto {
  ProductId: string;
  Name: string;
  ShortDescription: string;
  BannerPhotoFilePath: string;
  Gross: number;
  Amount: number;
  Created: Date;
  SellerId: string;
  SellerName: string;
  SystemStatus: PRODUCT_VERIFICATION_STATUS;
  CurrentStatus: PRODUCT_ACTIVATION_STATUS;
  CategoryId: string;
  CategoryName: string;
  Type: PRODUCT_TYPES;
}

export interface IProductSeller {
  SellerId: string;
  Name: string;
}

export interface IProductCategory {
  CategoryId: string;
  Name: string;
}

export interface IProductPhoto {
  ProductPhotoFileId: string;
  FilePath: string;
}

export interface IProductDetail {
  ProductId: string;
  Name: string;
  Description: string;
  ShortDescription: string;
  Tag: string;
  SystemStatus: PRODUCT_VERIFICATION_STATUS;
  Status: PRODUCT_ACTIVATION_STATUS;
  BannerPhotoFilePath: string;
  Type: PRODUCT_TYPES;
  Seller: IProductSeller;
  Category: IProductCategory;
  Combinations: ICombination[];
  Photos: IProductPhoto[];
  AdditionalFiles: IAdditionalFile[];
  Visibility?: Number;
}

export interface IProductForm {
  Name: string;
  Description: string;
  ShortDescription: string;
  BannerPhotoFilePath: string;
  Tag: string;
  CategoryId: string;
  Visibility: number;
  Type?: PRODUCT_TYPES;
}

export interface IAddProduct extends IProductForm {
  PhotoFilesPath: string[];
}

export interface IUpdateProduct extends IProductForm {
  ProductId: string;
}

export interface IProductFilter {
  id: number;
  type: string;
  name: string;
  values?: number;
  pl: string;
}

export interface IProductStatstics {
  Name: string;
  SoldCount: number;
  SoldAmount: number;
  RefundCount: number;
  RefundAmount: number;
  AttributeSoldDetails: IAttributeSoldDetailStatstics[];
  OrderDetails: IOrderDetailStatstics[];
}

export enum PRODUCT_TYPES {
  "Fizyczny",
  "Elektroniczny",
}

export enum PRODUCT_FILTERS {
  "Wszystkie produkty",
  "Nasze produkty",
}

export enum PRODUCT_VERIFICATION_STATUS {
  "Przetwarzany",
  "Zweryfikowany",
  "Anulowany",
}

export enum PRODUCT_ACTIVATION_STATUS {
  "Aktywny" = 1,
  "Nieaktywny" = 2,
}
