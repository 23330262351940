import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import ContentContainer from "components/layout/ContentContainer";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import { IPlayerDetail } from "types/transfer/player.interface";
import PlayerDetailInfo from "./PlayerDetailInfo";
import PlayerDetailTabs from "./tabs/PlayerDetailTabs";
import playerService from "services/transfer/playerServices";
import PlayerDetailTopbar from "./PlayerDetailTopbar";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import PlayerContentContainer from "components/layout/PlayerContentContainer";

import { IAddress, IHistoryStatus } from 'types/address.interface';

interface IPlayerDetailProps {
  isStructure?: boolean;
  playerId?: string;
  closePlayerModalOpen?: any;
}

const PlayersDetail = ({
  isStructure,
  playerId,
  closePlayerModalOpen,
}: IPlayerDetailProps) => {
  const { id } = useParams<{ id: string }>();

  const [player, setPlayer] = useState<any | null>(null);
  const [playerAddress, setPlayerAddress] = useState<IAddress | null>(null);
  const [playerCard, setPlayerCard] = useState<any | null>(null);
  const [historyStatus, setHistoryStatus ] = useState<any | null>(null);
  const [trainerTab, setTrainerTab] = useState<null | any>(null);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAdmin = permission[0]?.Id === -1;
  const isPlayerDetailActive = permission.some((tab: any) => tab.Id === 134);

  const isPlayerDetailStructureActive = permission.some((tab: any) => tab.Id === 205);

  const isPlayerDetailAddressActive = permission.some((tab: any) => tab.Id === 147);

  const isPlayerDetailCardActive = permission.some((tab: any) => tab.Id === 148);

  const isPlayerCardDetailIsStructure = permission.some((tab: any) => tab.Id === 185);

  const isPlayerAddressDetailIsStructure = permission.some((tab: any) => tab.Id === 184);

  const isPlayerDetailHistoryStatusActive = permission.some((tab: any) => tab.Id === 153);

  const isPlayerDetailHistoryStatusActiveIsStructure = permission.some((tab: any) => tab.Id === 190);

  const isPlayerPassActiveIsStructure = permission.some((tab: any) => tab.Id === 189);

  const isPlayerPassActive = permission.some((tab: any) => tab.Id === 152);

  const isDesignateTrainersActive = permission.some((tab: any) => tab.Id === 149);

  const isDesignateTrainersActiveIsStructure = permission.some((tab: any) => tab.Id === 186);

  const getPlayerDetail = async () => {
    try {
      if (isAdmin) {
        if (isStructure && playerId) {
          const detail = await playerService.getDetail(playerId);
          const {Address, Card,  ...rest} = detail;

          setPlayer(rest);
          setPlayerAddress(Address);
          setPlayerCard(Card);
          setHistoryStatus(detail);
          setTrainerTab(rest);
        } else {
          const detail = await playerService.getDetail(id);
          const {Address, Card, ...rest} = detail;

          setPlayer(rest);
          setPlayerAddress(Address);
          setPlayerCard(Card);
          setHistoryStatus(detail);
          setTrainerTab(rest);
        }
      }

      if (!isAdmin && (isPlayerDetailActive  && !isStructure)) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse) {
          const detail = await playerService.getBasicInformation(idToUse);
          const { Address, Card, ...rest } = detail;
           setPlayer(rest);
        }
      }

      if (!isAdmin && (isPlayerDetailStructureActive && isStructure)) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse) {
          const detail = await playerService.getBasicInformation(idToUse);
          const { Address, Card, ...rest } = detail;
          setPlayer(rest);
        }
      }

      if (!isAdmin && (isPlayerDetailAddressActive  && !isStructure)) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerAddress(idToUse);
          setPlayerAddress(response.Address);
        }
      }

      if (!isAdmin && ((isPlayerAddressDetailIsStructure && isStructure))) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerAddress(idToUse);
          setPlayerAddress(response.Address);
        }
      }

      if (!isAdmin &&  (isPlayerDetailCardActive  && !isStructure)) {
        const idToUse: string | undefined  = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerCard(idToUse);
          setPlayerCard(response.Card);
        }
      }

      if (!isAdmin && ((isPlayerCardDetailIsStructure && isStructure))) {
        const idToUse: string | undefined = isStructure ? playerId : id;

        if (idToUse) {
          const response = await playerService.getPlayerCard(idToUse);
          setPlayerCard(response.Card);
        }
      }

      if (!isAdmin && (isPlayerDetailHistoryStatusActive  && !isStructure )) {
        const idToUse: string | undefined  = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerHistoryStatus(idToUse);
          setHistoryStatus(response);
        }
      }

      if (!isAdmin && ((isPlayerDetailHistoryStatusActiveIsStructure && isStructure ))) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerHistoryStatus(idToUse);
          setHistoryStatus(response);
        }
      }

      if (!isAdmin && (isPlayerPassActive && !isStructure)) {
        const idToUse: string | undefined  = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerAppliedPasses(idToUse);
          setPlayer(response);
        }
      }

      if (!isAdmin && (isPlayerPassActiveIsStructure && isStructure)) {
        const idToUse: string | undefined = isStructure ? playerId : id;
        if (idToUse)  {
          const response = await playerService.getPlayerAppliedPasses(idToUse);
          setPlayer(response);
        }
      }

      if (!isAdmin && (isDesignateTrainersActive && !isStructure)) {
       const idToUse: string | undefined  = isStructure ? playerId : id;
       if (idToUse) {
        const response = await playerService.getPlayerDesignatedTrainers(idToUse);
       setTrainerTab(response);
       }
      }

      if (!isAdmin && ((isDesignateTrainersActiveIsStructure && isStructure))) {
        const idToUse: string | undefined  = isStructure ? playerId : id;
        if (idToUse) {
          const response = await playerService.getPlayerDesignatedTrainers(idToUse);
          setTrainerTab(response);
        }
       }

    } catch (error) {
      console.error('Error fetching player information:', error);
    }
  };

  useEffect(() => {
    getPlayerDetail();
  }, []);

  if (!player) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <PlayerContentContainer
      title={player.Name}
      TopBar={
        <PlayerDetailTopbar
          appliedPasses={player.AppliedPasses}
          playerId={playerId}
        />
      }
      noArrow={isStructure ? true : false}
      fn={() => closePlayerModalOpen()}
      path={player.Level === null ? "/outside-players" : "/players"}
    >
      <div>
        <PlayerDetailInfo player={ player } />
        <PlayerDetailTabs
          playerCard={playerCard}
          playerAddress={playerAddress}
          player={player}
          refresh={getPlayerDetail}
          historyStatus={historyStatus}
          trainerTab={trainerTab}
        />
      </div>
    </PlayerContentContainer>
  );
};

export default PlayersDetail;
