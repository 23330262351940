import { ReactNode, RefObject, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

type DataTableRowProps = {
  row: { data?: { link?: string }; cols: Array<ReactNode> };
  innerRef?: RefObject<HTMLTableRowElement>;
  selectedPassId?: any;
  setSelectedPassId?: any;
  setIsModalOpen: any;
};

const PassesDataTableRow: React.FC<any> = ({
  innerRef,
  row,
  selectedPassId,
  setSelectedPassId,
  setIsModalOpen,
}) => {
  const { push } = useHistory();
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleRowClick = () => {
    // if (row.data?.link) {
    //   push(row.data.link);
    // }
    // setSelectedPassId(row.col)
    innerRef?.current?.focus();
    setSelectedPassId(row?.passId);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && selectedPassId === row?.passId) {
      event.preventDefault();
      setIsModalOpen(true);
    }
  };

  const handleDoubleClick = () => {
    if (row.data?.link) {
      push(row.data.link);
    }
  };

  return (
    <StyledTr
      ref={innerRef}
      onClick={handleRowClick}
      data-clickable={!!row.data?.link}
      onDoubleClick={handleDoubleClick}
      onKeyDown={handleKeyPress}
      isFocused={isFocused}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
      selectedPassId={selectedPassId}
      passId={row?.passId}
    >
      {row.cols.map((col: any, idx: any) => {
        const title = typeof col === "string" ? col : "";
        return (
          <td
            style={{ opacity: 1 }}
            // colSpan={idx === 3 ? 3 : 1}
            colSpan={1}
            key={idx}
            title={title}
          >
            {/* <div> */}
            <span style={{ opacity: 1 }}>{col || "- brak -"}</span>
            {/* </div> */}
          </td>
        );
      })}
    </StyledTr>
  );
};

export default PassesDataTableRow;

const StyledTr = styled.tr<{
  selectedPassId: any;
  passId: any;
  isFocused: boolean;
}>`
  ${({ selectedPassId, passId, isFocused }) =>
    selectedPassId === passId &&
    isFocused &&
    css`
      outline: 2px solid lightblue;
      outline-offset: -1px;
    `}
`;
