import HeaderContainer from 'components/common/Containers/HeaderContainer';
import InfoContainerItem from 'components/common/Containers/InfoContainerItem';
import React from 'react';
import { InfoContainer } from 'styles/styles';
import { ISellerDetail } from 'types/seller.interface';

interface IMerchantConditionsProps {
  seller?: ISellerDetail | any;
}

const MerchantConditions = ({ seller }: IMerchantConditionsProps) => {

  return (
    <HeaderContainer title='Warunki'>
      <InfoContainer>
        <InfoContainerItem
          header='Procent opłaty'
          content={seller?.EventFeeProcent !== undefined
            ? `${seller.EventFeeProcent} %`
            : seller?.Seller?.Fee !== undefined
              ? `${seller.Seller.Fee} %`
              : '-'}
        />
        <InfoContainerItem
          header='Opłata eventu'
          content={seller?.FeeProcent !== undefined
            ? `${seller.FeeProcent} %`
            : seller?.Seller?.EventFee !== undefined
              ? `${seller.Seller.EventFee} %`
              : '-'}
        />
      </InfoContainer>
    </HeaderContainer>
  );
};

export default MerchantConditions;
