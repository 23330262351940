import { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { RootState } from "store/store";
import { useTranslation } from "react-i18next";

import SubmitButton from "components/common/buttons/submitButton/SubmitButton";
import TextField from "components/common/inputs/textInput/TextField";

import { ISelectOption } from "components/common/inputs/inputTypes";

import styled from "styled-components";

import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";

import eventsServices from "services/eventsServices";

import useInfiniteScroll from "hooks/useInfiniteScroll";
import { PlayerProfileInterface } from "types/userTypes";

import logo from "../../../assets/images/logo.svg";
// import { getAllAcademyPlayersQuery } from "services/transferServices";
import MaskedField from "components/common/inputs/maskedInput/MaskedField";
import { Mask } from "utils/constants/constants";

const SingleColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > p {
    text-align: center;
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;
interface IEditDigitalReleaseProps {
  user: any;
  isEdit: boolean;
  onSubmit: any;
}

const EditDigitalReleaseForm: React.FC<IEditDigitalReleaseProps> = ({
  user,
  isEdit,
  onSubmit,
}) => {
  const handleSubmit = async (values: any) => {
    // if (!base64) return toast.error("Dodaj zdjęcie!");

    const newValues = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Contact.Email,
      PhoneNumber: values.Contact.PhoneNumber,
    };

    // const imageUrl = await sellerServices.uploadFile(base64);
    await onSubmit(newValues, user?.UserId);
  };

  return (
    <Formik initialValues={user} onSubmit={handleSubmit} validateOnMount>
      {({ errors, isSubmitting, values, initialValues, setFieldValue }) => (
        <Form
          className="flex"
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <SingleColumn style={{ alignItems: "center", paddingLeft: "15px" }}>
            <p>dane podstawowe</p>

            <TextField name="FirstName" label={"Imię"} />
            <TextField name="LastName" label={"Nazwisko"} />
            <TextField name="Contact.Email" label={"Email"} />
            <MaskedField
              mask={Mask.Phone}
              name="Contact.PhoneNumber"
              placeholder="Uzupełnij"
              label="Number telefonu"
              autoComplete="off"
            />

            <SubmitButton
              isSubmitting={isSubmitting}
              type="submit"
              className="w-80"
              style={{
                borderRadius: 0,
                width: "280px",
                fontSize: "15px",
                fontWeight: "bold",
                marginTop: "100px",
                color: "white",
                background: "rgb(0, 160, 255)",
              }}
            >
              Zapisz
            </SubmitButton>
          </SingleColumn>
        </Form>
      )}
    </Formik>
  );
};

export default EditDigitalReleaseForm;
