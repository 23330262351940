import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import { toast } from 'react-toastify';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import TextField from 'components/common/inputs/textInput/TextField';
import { ILabelObject } from 'types/marketpleace/order.interface';
import { generateInpostLabel } from 'services/inpost';
import trashIcon from '../../../../assets/icons/trash-bin.svg';
import plusIcon from '../../../../assets/icons/add-circle-white.svg';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';

const validationSchema = Yup.object().shape({
  InsuranceValue: Yup.number()
    .min(10, 'Ubezpieczenie musi wynosić min. 10 PLN')
    .required('Ubezpieczenie przesyłki jest wymagane'),
  CurierParcels: Yup.array()
    .of(
      Yup.object().shape({
        ShipmentType: Yup.number().required('Wybierz typ przesyłki'),
        Length: Yup.number()
          .required('Długość jest wymagana')
          .when('ShipmentType', {
            is: 1,
            then: (schema) =>
              schema
                .min(1, 'Minimalna długość to 1 cm')
                .max(23, 'Maksymalna długość dla Gabarytu XS to 23 cm'),
            otherwise: (schema) =>
              schema
                .min(1, 'Minimalna długość to 1 cm')
                .max(64, 'Maksymalna długość to 64 cm'),
          }),
        Width: Yup.number()
          .required('Szerokość jest wymagana')
          .when('ShipmentType', {
            is: 1,
            then: (schema) =>
              schema
                .min(1, 'Minimalna szerokość to 1 cm')
                .max(40, 'Maksymalna szerokość dla Gabarytu XS to 40 cm'),
            otherwise: (schema) =>
              schema
                .min(1, 'Minimalna szerokość to 1 cm')
                .max(38, 'Maksymalna szerokość to 38 cm'),
          }),
        Height: Yup.number()
          .required('Wysokość jest wymagana')
          .when('ShipmentType', {
            is: 1,
            then: (schema) =>
              schema
                .min(1, 'Minimalna wysokość to 1 cm')
                .max(4, 'Maksymalna wysokość dla Gabarytu XS to 4 cm'),
            otherwise: (schema) =>
              schema
                .min(1, 'Minimalna wysokość to 1 cm')
                .max(41, 'Maksymalna wysokość to 41 cm'),
          }),
        Weight: Yup.number()
          .required('Waga jest wymagana')
          .when('ShipmentType', {
            is: 1,
            then: (schema) =>
              schema
                .min(0.5, 'Minimalna waga to 0.5 kg')
                .max(3, 'Maksymalna waga dla Gabarytu XS to 3 kg'),
            otherwise: (schema) =>
              schema
                .min(1, 'Minimalna waga to 1 kg')
                .max(25, 'Maksymalna waga to 25 kg'),
          }),
      })
    )
    .required('Wymagana jest przynajmniej jedna paczka'),
});

enum SHIPMENT_TYPES {
  Paczka = 0,
  Koperta = 1,
}

const shipmentOptions = Object.keys(SHIPMENT_TYPES)
  .filter((key) => isNaN(Number(key)))
  .map((key, index) => ({
    label: key,
    value: index,
  }));

const initialValues = {
  YourOwnReferenceNumber: '',
  AdditionalInformationLabel: '',
  InsuranceValue: 0,
  CurierParcels: [
    {
      ShipmentType: shipmentOptions[0]?.value || 0,
      Length: 0,
      Width: 0,
      Height: 0,
      Weight: 0,
    },
  ],
};

interface Props {
  objLabel: ILabelObject;
  openLabelForm: (value: boolean) => void;
  activateDownloadLabelBtn: (value: boolean) => void;
}

const InpostForm: React.FC<Props> = ({
  objLabel,
  openLabelForm,
  activateDownloadLabelBtn,
}) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const mergedObj = { ...objLabel, ...values };

      await generateInpostLabel(mergedObj);
      toast.success('Formularz została wysłany');

      const id = setTimeout(() => {
        openLabelForm(true);
        activateDownloadLabelBtn(true);
      }, 400);
      setTimeoutId(id);
    } catch (error) {
      const id = setTimeout(() => {
        openLabelForm(true);
      }, 400);
      setTimeoutId(id);
      toast.error('Podczas wysyłania etykiety, wystąpił błąd');
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <div>
      <div className="text-white-dirty text-center pb-18 font-semibold space-x-2 text-lg">
        Formularz Przesyłki
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
                gap: '10px',
              }}
            >
              <TextField
                name="YourOwnReferenceNumber"
                type="text"
                label="Własny numer przesyłki"
                placeholder="..."
              />
              <TextField
                name="AdditionalInformationLabel"
                type="text"
                label="Dodatkowe informacje"
                placeholder="..."
              />
              <TextField
                name="InsuranceValue"
                type="number"
                label="Ubezpieczenie przesyłki"
                placeholder=""
              />
            </div>

            <FieldArray name="CurierParcels">
              {({ push, remove }) => (
                <>
                  {values.CurierParcels.map((parcel, formIndex) => (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <div
                        key={formIndex}
                        style={{
                          borderBottom: 'solid whitesmoke 1px',
                          paddingBottom: '20px',
                        }}
                      >
                        <h3 className="text-white pb-8 pt-12">
                          Paczka {formIndex + 1}
                        </h3>
                        <div
                          style={{
                            color: 'rgba(0, 0, 0, 0.1)',
                            display: 'grid',
                            gridTemplateColumns:
                              'repeat(auto-fill, minmax(290px, 1fr))',
                            gap: '10px',
                            justifyContent: 'center',
                          }}
                        >
                          <NewSelectStatystics
                            name={`CurierParcels.${formIndex}.ShipmentType`}
                            items={shipmentOptions}
                            label="Typ przesyłki"
                            setSelectedItem={(item) => {
                              if (item && 'value' in item) {
                                setFieldValue(
                                  `CurierParcels.${formIndex}.ShipmentType`,
                                  item.value
                                );
                              }
                            }}
                            selectedItem={
                              shipmentOptions.find(
                                (item) =>
                                  item.value ===
                                  values.CurierParcels[formIndex]?.ShipmentType
                              ) || null
                            }
                            style={{
                              background: 'rgba(0, 0, 0, 0.1)',
                              width: '100%',
                            }}
                            className="mb-4 ,sm:mb-0"
                          />
                          {['Length', 'Width', 'Height', 'Weight'].map(
                            (dimension) => (
                              <div key={dimension}>
                                <TextField
                                  name={`CurierParcels.${formIndex}.${dimension}`}
                                  type="number"
                                  label={
                                    dimension === 'Length'
                                      ? 'Długość cm'
                                      : dimension === 'Width'
                                      ? 'Szerokość cm'
                                      : dimension === 'Height'
                                      ? 'Wysokość cm'
                                      : 'Waga kg'
                                  }
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div>
                        {formIndex === 0 ? (
                          <div style={{ width: '30px' }}></div>
                        ) : (
                          <button
                            type="button"
                            className="text-white-dirty hover:opacity-70"
                            onClick={() => remove(formIndex)}
                          >
                            <img
                              src={trashIcon}
                              alt="trashIcon"
                              style={{
                                width: '30px',
                                height: '100%',
                                color: 'white',
                              }}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="pt-30">
                    <Button
                      variant={ButtonVariant.Abort}
                      type="button"
                      className="text-white-dirty"
                      onClick={() =>
                        push({
                          ShipmentType: shipmentOptions[0]?.value || 0,
                          Length: 0,
                          Width: 0,
                          Height: 0,
                          Weight: 0,
                        })
                      }
                    >
                      <div className="flex gap-2 items-center">
                        <img
                          src={plusIcon}
                          alt="plusIcon"
                          style={{
                            width: '18px',
                            height: '100%',
                          }}
                        />
                        <p>Dodaj paczkę</p>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
            <div>
              <SubmitButton
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                className="px-18 mb-2 text-sm"
              >
                Generuj etykietę
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InpostForm;
