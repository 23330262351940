import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ContentContainer from "components/layout/ContentContainer";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import EventsList from "./tiles/DigitalReleaseList";
import EventsTable from "./EventsTable";
import EventsTopbar from "./EventsTopbar";

import { DefaultSortContext } from "contexts/defaultSortContext";
import eventsServices from "services/eventsServices";
import {
  getAllCyclicalEvents,
  getAllEvents,
  getAllEventsFilter,
  getEventDetail,
} from "services/eventServices";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Events: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [viewType, setViewType] = useState("list");
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const viewMode = useSelector(
    (state: RootState) => state.ui.dataViewModes.digitalRelease,
  );
  const { id } = useParams<{ id: string }>();
  const [displayType, setDisplayType] = useState<{
    value: number;
    label: string;
  } | null>({
    label: "Zajęcia",
    value: 0,
  });
  const [displayTypes, setDisplayTypes] = useState<
    { value: number; label: string }[]
  >([]);

  const [queryString, setQueryString] = useState(() => {
    return localStorage.getItem("searchQuery") || "";
  });
  const [isDraftMenuOpen, toggleDraftMenuOpen] = useState(false);
  const [clickedCyclicalEventId, setClickedCyclicalEventId] = useState("");

  const getAllDisplayTypes = async () => {
    try {
      setDisplayTypes([
        {
          label: "Zajęcia",
          value: 0,
        },
        {
          label: "Drafty",
          value: 1,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllDisplayTypes();
  }, []);

  // const [events, setEvents] = useState([]);
  // const {
  //   items: events,
  //   loading,
  //   containerRef,
  //   lastItemRef,
  // } = useInfiniteScroll<any>(
  //   // @ts-ignore
  //   eventsServices.getAllEvents,
  //   queryString,
  // );

  // const getAllEventsFn = () => {
  //   getAllEvents()
  //     .then((data) => {
  //       setEvents(data.Data.Items);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   getAllEventsFn();
  // }, []);

  const {
    items: events,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    getAllEventsFilter,
    localStorage.getItem("searchQuery") || "",
  );

  const {
    items: cyclicalEvents,
    loading: cyclicalLoading,
    containerRef: cyclicalContainerRef,
    lastItemRef: cyclicalLastItemRef,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    getAllCyclicalEvents,
    localStorage.getItem("searchQuery") || "",
  );

  const getAllSorts = async () => {
    try {
      setSorts([
        {
          label: "Najnowsze",
          value: 0,
        },
        {
          label: "Najstarsze",
          value: 1,
        },
        {
          label: "Nazwa (A-Z)",
          value: 2,
        },
        {
          label: "Nazwa (Z-A)",
          value: 3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSorts();
  }, []);

  useEffect(() => {
    if (sortBy) {
      // @ts-ignore
      setDefaultSort(sortBy.value);
    }
  }, [sortBy]);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
    localStorage.setItem("searchQuery", value);
  };

  useEffect(() => {
    const storedQuery = localStorage.getItem("searchQuery");
    if (storedQuery) {
      setQueryString(storedQuery);
    }
  }, []);

  const hasDraftPermissions =
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === 209);

  const isEventListingActive =
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === 132);

  const uniqueEvents = events.filter(
    (event, index, self) =>
      index === self.findIndex((e) => e.EventId === event.EventId),
  );

  const mergedEvents = [...cyclicalEvents, ...uniqueEvents];

  return (
    <ContentContainer
      title={"Zajęcia"}
      TopBar={
        <EventsTopbar
          displayType={displayType}
          setDisplayType={setDisplayType}
          displayTypes={displayTypes}
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          isDraftMenuOpen={isDraftMenuOpen}
          toggleDraftMenuOpen={toggleDraftMenuOpen}
          clickedCyclicalEventId={clickedCyclicalEventId}
          setClickedCyclicalEventId={setClickedCyclicalEventId}
          handleQueryChange={handleQueryChange}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
        />
      }
    >
      {/* {viewType === "list" ? (
        <EventsTable
          events={cyclicalEvents}
          containerRef={cyclicalContainerRef}
          lastItemRef={cyclicalLastItemRef}
          isDataLoading={cyclicalLoading}
          sortBy={sortBy}
        />
      ) : (
        <EventsList
          sortBy={sortBy}
          events={cyclicalEvents}
          containerRef={cyclicalContainerRef}
          lastItemRef={cyclicalLastItemRef}
          isDataLoading={cyclicalLoading}
        />
      )} */}

      <div
        onClick={() => {
          if (!isEventListingActive) {
            toast.error("Nie masz uprawnień. Skontaktuj się z Administratorem");
          }
        }}
      >
        <div
          style={{
            pointerEvents: isEventListingActive ? "auto" : "none",
          }}
        >
          <EventsTable
            events={
              hasDraftPermissions && displayType?.value === 0
                ? uniqueEvents
                : cyclicalEvents
            }
            containerRef={
              displayType?.value === 0 ? containerRef : cyclicalContainerRef
            }
            lastItemRef={
              displayType?.value === 0 ? lastItemRef : cyclicalLastItemRef
            }
            isDataLoading={displayType?.value === 0 ? loading : cyclicalLoading}
            sortBy={sortBy}
            setClickedCyclicalEventId={setClickedCyclicalEventId}
            toggleDraftMenuOpen={toggleDraftMenuOpen}
          />
        </div>
      </div>
    </ContentContainer>
  );
};

export default Events;
