import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import SubmitButton from "../../common/buttons/submitButton/SubmitButton";
import SwitchBlock from "../../common/inputs/switch/SwitchBlock";
import AssignToProfileSelect from "./AssignToProfileSelect";
import PermissionSection from "./PermissionSection";

import permissionService from "../../../services/permissionServices";
import {
  PermissionsLabels,
  permissoinsFromDTO,
} from "../../../utils/permissionsUtils";
import { ICheckboxValue } from "../../../types/globalTypes";
import {
  IPermissions,
  PermissionsDomainsShort,
  IPermissionTransformDTO,
  IPermissionsActionTypes,
} from "../../../types/permissionsTypes";
import { useDispatch } from "react-redux";
import { changePermissions } from "../../../store/actions/permissions";
import permissionsServices from "services/permissionsServices";
import FormSection from "components/common/forms/FormSection";
import SelectProfiles from "components/common/inputs/select/SelectProfiles";
import { Permission, UserInterface } from "types/userTypes";
import {
  translateDomainNames,
  translateFlagNames,
} from "./PermissionsTranslate";
import NotificationSwitchBlock from "components/common/inputs/switch/NotificationSwitchBlock";

interface Props {
  user: UserInterface;
}

const EditNotifications: React.FC<Props> = ({ user }) => {
  const [initState, setInitState] =
    useState<IPermissions<ICheckboxValue<number>> | null>(null);
  const [changed, setChanged] = useState<IPermissionTransformDTO>({});

  const [selectedItem, setSelectedItem] =
    useState<{ value: number; label: string } | null>(null);
  const [profiles, setProfiles] = useState([]);
  const [domainNames, setDomainNames] = useState([]);

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const handleChange = (
    domain: keyof IPermissions,
    action: keyof IPermissionsActionTypes,
    value: ICheckboxValue<number>,
  ) => {
    const key = `${domain}_${action}`;
    if (!changed[key]) {
      setChanged((prev) => ({
        ...prev,
        [key]: {
          permissionDomainName: PermissionsDomainsShort[domain],
          permissionFlagName: action,
          permissionFlagValue: value.value,
          value,
        },
      }));
    } else {
      setChanged((prev) => {
        const { [key]: deleted, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (values: typeof initValues, bag: any) => {
    try {
      if (values) {

        console.log(values, 'valuesvaluesvaluesvaluesvaluesvaluesvaluesvaluesvalues')
        // dispatch(changePermissions(changed, id));
        // setChanged({});
        // toast.success(`Zmieniono uprawnienia`);
      }
    } catch (errors: any) {
      toast.error("Błąd: " + errors.join(" | "));
    }
  };

  const getAllProfiles = async () => {
    try {
      const resp: any = await permissionsServices.getAllProfiles();

      const profilesArray: any = [];

      const convertedObiect = Object.values(resp);

      convertedObiect.map((single, index) => {
        return profilesArray.push({
          label: single,
          value: index,
        });
      });

      setProfiles(profilesArray);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getAllProfiles();
  // }, []);

  // useEffect(() => {
  //   const domainNamesArray: any = [];
  //   user.NotificationsType.forEach((Permission: any) => {
  //     if (
  //       domainNamesArray.filter(
  //         (el: any) => Permission.PermissionDomainName === el,
  //       ).length > 0
  //     ) {
  //       return;
  //     } else {
  //       domainNamesArray.push(Permission.PermissionDomainName);
  //     }
  //   });

  //   // @ts-ignore
  //   setDomainNames(domainNamesArray);
  // }, []);

  const initValues = {
    Auth: {
      CreateUser: 1,
      EditUser: 1,
      DeleteUser: 1,
      ViewManagementList: 1,
      ViewContactList: 1,
      ChangeOwnPassword: 1,
      ManagePermissions: 1,
      ChangeRole: 1,
      ChangePassword: 1,
      RevokePermission: 1,
      GrantPermission: 1,
    },
  };

  const allNotificationsType = [
    {
      NotificationType: 0,
      NotificationName: "PlayerAcceptedTransfer"
    },
    {
      NotificationType: 1,
      NotificationName: "AcademyAcceptedTransfer"
    },
    {
      NotificationType: 2,
      NotificationName: "PlayerSentTransferRequest"
    },
    {
      NotificationType: 3,
      NotificationName: "AcademySentTransferRequest"
    },
    {
      NotificationType: 4,
      NotificationName: "PlayerDeletedTransfer"
    },
    {
      NotificationType: 5,
      NotificationName: "AcademyDeletedTransfer"
    },
    {
      NotificationType: 6,
      NotificationName: "PlayerDeletedTransferRequest"
    },
    {
      NotificationType: 7,
      NotificationName: "AcademyDeletedTransferRequest"
    },
    {
      NotificationType: 8,
      NotificationName: "TrainerRelationCreated"
    },
    {
      NotificationType: 9,
      NotificationName: "TrainerRelationRequestCreated"
    },
    {
      NotificationType: 10,
      NotificationName: "TrainerRelationDeleted"
    },
    {
      NotificationType: 11,
      NotificationName: "TrainerRelationRequestDeleted"
    },
    {
      NotificationType: 12,
      NotificationName: "PlayerPassNotPaid"
    },
    {
      NotificationType: 13,
      NotificationName: "PlayerPassPaid"
    },
    {
      NotificationType: 14,
      NotificationName: "ProductVerified"
    },
    {
      NotificationType: 15,
      NotificationName: "ProductCanceled"
    },
  ];

  const translateNotificationName = (notificationName: string): string => {
    switch (notificationName) {
      case "PlayerAcceptedTransfer":
        return "Gracz zaakceptował transfer";
      case "AcademyAcceptedTransfer":
        return "Akademia zaakceptowała transfer";
      case "PlayerSentTransferRequest":
        return "Gracz wysłał prośbę o transfer";
      case "AcademySentTransferRequest":
        return "Akademia wysłała prośbę o transfer";
      case "PlayerDeletedTransfer":
        return "Gracz usunął transfer";
      case "AcademyDeletedTransfer":
        return "Akademia usunęła transfer";
      case "PlayerDeletedTransferRequest":
        return "Gracz usunął prośbę o transfer";
      case "AcademyDeletedTransferRequest":
        return "Akademia usunęła prośbę o transfer";
      case "TrainerRelationCreated":
        return "Relacja trenera została utworzona";
      case "TrainerRelationRequestCreated":
        return "Prośba o relację trenera utworzona";
      case "TrainerRelationDeleted":
        return "Relacja trenera usunięta";
      case "TrainerRelationRequestDeleted":
        return "Prośba o relację trenera usunięta";
      case "PlayerPassNotPaid":
        return "Opłata za składkę gracza nie została uiszczona";
      case "PlayerPassPaid":
        return "Opłata za składkę gracza została uiszczona";
      case "ProductVerified":
        return "Produkt zweryfikowany";
      case "ProductCanceled":
        return "Produkt został anulowany";
      default:
        return notificationName;
    }
  };

  return (
    <PermissionSection label={"Powiadomienia"}>
      {allNotificationsType.map((singleNotification, index) => {
        return (
          <>
            <NotificationSwitchBlock
              domainName={singleNotification.NotificationName}
              flagName={singleNotification.NotificationName}
              flagValue={singleNotification.NotificationType}
              name={singleNotification.NotificationName}
              key={index}
              label={translateNotificationName(singleNotification.NotificationName)}
              checked={
                user.NotificationsType.filter(
                  (test: any) =>
                    test.Id ===
                    singleNotification.NotificationType,
                ).length > 0
              }
            />
          </>
        );
      })}
    </PermissionSection>
  );
};

export default EditNotifications;
