import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import TabContent from "components/common/tabs/TabContent";
import React, { useState, useEffect } from "react";
// @ts-ignore
import Modal from "react-modal";
import Map from "../../../events/Map";
import favoriteLocationServices, {
  deleteFavoriteLocation,
} from "services/favoriteLocationServices";
import eventsServices from "services/eventsServices";
import styled from "styled-components";
import { toast } from "react-toastify";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";
import EditButton from "components/common/buttons/basicButton/EditButton";
import SearchButton from "components/common/buttons/basicButton/SearchButton";
import SaveButton from "components/common/buttons/basicButton/SaveButton";

interface Props {
  isLocalizationPermissionActive?: Boolean;
  isLocatlizationListActive?: Boolean;
}

const AcademyLocalizations: React.FC<Props> = ({
  isLocalizationPermissionActive,
  isLocatlizationListActive,
}) => {
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [editAttributes, setEditAttributes] = useState(false);
  const [favLocations, setFavLocations] = useState([]);
  const [editedLocationId, setEditedLocationId] = useState("");
  const [editedName, setEditedName] = useState("");

  const [location, setLocation] = useState<any>(null);
  const closeMapModalOpen = () => {
    setIsMapModalOpen(false);
  };

  const handleEditNameChange = (e: any) => {
    setEditedName(e.target.value);
  };

  const getAllFavLocations = async () => {
    try {
      const resp: any =
        await favoriteLocationServices.getAllFavoriteLocations();
      console.log("alllocations", resp);

      setFavLocations(resp);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLocatlizationListActive) {
      getAllFavLocations();
    }
  }, [isLocatlizationListActive]);

  const handleLocationChange = (newLocation: any) => {
    setLocation(newLocation);
  };

  const editLocationFn = async (
    Id: string,
    Name: string,
    Latitude: number,
    Longitude: number,
  ) => {
    await favoriteLocationServices.editFavoriteLocation(
      Id,
      Name,
      "",
      Latitude,
      Longitude,
    );
    await getAllFavLocations();
    toast.success("Edytowano ulubioną lokalizacje!");
    setEditedLocationId("");
    setEditedName("");
    setLocation(null);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
      width: "80%",
      height: "80%",
      background: "#1a2530",
      margin: 0,
      padding: 0,
      border: "2px solid lightgray",
      borderTop: "30px solid lightgray",
    },
  };

  const parseAddress = (input: any) => {
    const parts = input.split(",").map((part: any) => part.trim());
    let postalCode: any = "";
    let city: any = "";
    let address: any = "";
    console.log(input, parts.length);

    if (parts.length === 3) {
      address = parts[0];
      postalCode = parts[1].split(" ")[0];
      city = parts[1].split(" ").slice(1).join(" ");
    } else if (parts.length === 4) {
      address = `${parts[0]}, ${parts[1]}`;
      const cityParts = parts[2].split(" ");
      postalCode = cityParts.pop() || "";
      city = cityParts.join(" ");
    } else if (parts.length === 5) {
      address = `${parts[0]}, ${parts[1]}`;
      postalCode = parts[3].split(" ")[0];
      city = parts[3].split(" ").slice(1).join(" ");
    }

    return { postalCode, city, address };
  };

  return (
    <TabContent id="localizations">
      <Modal
        isOpen={isMapModalOpen}
        onRequestClose={closeMapModalOpen}
        style={customStyles}
      >
        <Map onLocationChange={handleLocationChange} />
      </Modal>

      <div className="flex flex-col lg:flex-row gap-16 mx-auto w-full">
        <div className="w-full text-sm">
          <div
            className="px-18 py-12 opacity-80"
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "rgba(2,2,15,0.3)",
              color: "white",
              opacity: 1,
              height: "49px",
              alignItems: "center",
              borderLeft: "5px solid rgb(255,146,38)",
            }}
          >
            <span
              style={{
                textTransform: "uppercase",
                fontSize: "15px",
                fontWeight: 300,
                letterSpacing: -0.5,
              }}
            >
              Ulubione lokalizacje
            </span>
            <div style={{ display: "flex", gap: "5px" }}>
              {/* <Button
            variant={ButtonVariant.Remove}
            style={{ cursor: "pointer", userSelect: "none" }}

          >
           Anuluj Dodaj
          </Button> */}
              <div
                onClick={() => {
                  if (!isLocalizationPermissionActive) {
                    toast.error(
                      "Nie masz uprawnień. Skontaktuj się z Administratorem",
                    );
                  }
                }}
              >
                <div
                  style={{
                    pointerEvents: isLocalizationPermissionActive
                      ? "auto"
                      : "none",
                    opacity: isLocalizationPermissionActive ? "" : "0.6",
                  }}
                >
                  <Button
                    variant={
                      editAttributes ? ButtonVariant.Cancel : ButtonVariant.Edit
                    }
                    style={{ cursor: "pointer", userSelect: "none" }}
                    onClick={() => {
                      setEditAttributes((prev) => !prev);
                    }}
                  >
                    {editAttributes ? "Anuluj" : "Edytuj"}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <>
            <div className={`grid relative grid-cols-5 gap-1 my-1`}>
              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Nazwa</span>
              </div>

              <>
                <div
                  className="bg-opacity-70 p-12 text-center"
                  style={{
                    background: "rgba(2,2,15,0.3)",
                    color: "white",
                    textTransform: "uppercase",
                    fontSize: "11px",
                    fontWeight: 500,
                  }}
                >
                  <span>Kod pocztowy</span>
                </div>
                <div
                  className="bg-opacity-70 p-12 text-center"
                  style={{
                    background: "rgba(2,2,15,0.3)",
                    color: "white",
                    textTransform: "uppercase",
                    fontSize: "11px",
                    fontWeight: 500,
                  }}
                >
                  <span>Miasto</span>
                </div>
              </>

              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Adres</span>
              </div>

              <div
                className="bg-opacity-70 p-12 text-center"
                style={{
                  background: "rgba(2,2,15,0.3)",
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                <span>Zmiana parametrów</span>
              </div>
            </div>
          </>

          {favLocations.length > 0 &&
            favLocations.map((favLocation: any) => {
              const {
                Address,
                FavoriteLocationId,
                FieldId,
                Latitude,
                Longitude,
                Name,
                Note,
                ValueId,
              } = favLocation;

              const { postalCode, city, address }: any = Address
                ? parseAddress(Address)
                : {};

              return (
                <div className={`grid relative grid-cols-5 gap-1 my-1`}>
                  {editedLocationId === FavoriteLocationId ? (
                    <div
                      style={{
                        background: "rgba(163,158,157,0.09)",
                        color: "white",
                        fontSize: "15px",
                        fontWeight: 400,
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <StyledInput
                        type="text"
                        placeholder={Name}
                        value={editedName}
                        onChange={handleEditNameChange}
                      />
                    </div>
                  ) : (
                    <div
                      className="p-12 text-center"
                      style={{
                        background: "rgba(163,158,157,0.09)",
                        color: "white",
                        fontSize: "15px",
                        fontWeight: 400,
                        height: "48px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>{Name}</span>
                    </div>
                  )}

                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className=" cursor-pointer hover:text-blue">
                      {/* {Address
                        ? Address.split(",")[1].slice(0, 7)
                        : "Brak informacji"} */}
                      {postalCode}
                    </span>
                  </div>

                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {/* {Address
                        ? Address.split(",")[1].slice(7, 77)
                        : "Brak informacji"} */}
                      {city}
                    </span>
                  </div>

                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* {Address ? Address.split(",")[0] : "Brak informacji"} */}
                    {address}
                  </div>

                  <div
                    className="p-12 text-center"
                    style={{
                      background: "rgba(163,158,157,0.09)",
                      color: "white",
                      fontSize: "15px",
                      fontWeight: 400,
                      height: "48px",
                      display: "flex",
                      padding: "5px 8px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    {editAttributes && (
                      <>
                        {editedLocationId === FavoriteLocationId && (
                          <SearchButton
                            onClick={() => setIsMapModalOpen(true)}
                            className="cursor-pointer"
                          />
                        )}

                        {editedLocationId === FavoriteLocationId ? (
                          <SaveButton
                            onClick={() =>
                              editLocationFn(
                                FavoriteLocationId,
                                editedName,
                                location?.position?.lat
                                  ? location?.position?.lat
                                  : Latitude,
                                location?.position?.lng
                                  ? location?.position?.lng
                                  : Longitude,
                              )
                            }
                            className="cursor-pointer"
                          />
                        ) : (
                          <EditButton
                            onClick={() => {
                              setEditedLocationId(FavoriteLocationId);
                              setEditedName(Name);
                              setLocation(null);
                            }}
                            className="cursor-pointer"
                          />
                        )}

                        <DeleteButton
                          onClick={async () => {
                            await deleteFavoriteLocation(FavoriteLocationId);
                            await getAllFavLocations();
                            toast.success("Usunięto ulubioną lokalizacje!");
                          }}
                          className="cursor-pointer"
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </TabContent>
  );
};

export default AcademyLocalizations;

const StyledInput = styled.input`
  background: transparent;
  width: 80%;
  text-align: center;
`;
