import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #02020f;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00ddf7;
`;

const SaveButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="903 449 15 15"
      >
        <g data-name="Icon feather-save">
          <path
            d="M916.333 464h-11.666c-.92 0-1.667-.746-1.667-1.667v-11.666c0-.92.746-1.667 1.667-1.667h9.166l4.167 4.167v9.166c0 .92-.746 1.667-1.667 1.667Z"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="#00e5ff"
            fill="transparent"
            data-name="Path 3700"
          />
          <path
            d="M914.667 464v-6.667h-8.334V464"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="#00e5ff"
            fill="transparent"
            data-name="Path 3701"
          />
          <path
            d="M906.333 449v4.167H913"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke="#00e5ff"
            fill="transparent"
            data-name="Path 3702"
          />
        </g>
      </svg>
    </Button>
  );
};

export default SaveButton;
