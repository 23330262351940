import React, { useRef, useCallback, useEffect, useState } from 'react';
import HeaderContainer from 'components/common/Containers/HeaderContainer';
import { usePrimaryDataFormContext } from '../usePrimaryDataFormContext';
import boldText from 'assets/icons/text/boldText.svg';
import italicText from 'assets/icons/text/italicText.svg';
import leftText from 'assets/icons/text/leftText.svg';
import rightText from 'assets/icons/text/rightText.svg';
import centerText from 'assets/icons/text/centerText.svg';
import justifyText from 'assets/icons/text/justifyText.svg';

const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const sanitizeHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.innerHTML;
};

const AcademyDescription = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();
  const editorRef = useRef<HTMLDivElement | null>(null);
  const [content, setContent] = useState<string| undefined>(form.primaryData.Description);
  const [isEditText, setIsEditText] = useState<boolean | null>(null);

  useEffect(() => {
    if (editEnable) {
      setIsEditText(false);
    }

    if (!editEnable && !isEditText) {
      setContent(form.primaryData.Description);
      setIsEditText(null);
    }
  }, [editEnable, isEditText]);

  const sanitizedHTML = sanitizeHTML(content || '');

  const handleFormat = (command: string, value?: string) => {
    if (editorRef.current) {
      document.execCommand(command, false, value || undefined);
    }
  };

  const updateForm = useCallback(() => {
    const updatedContent = editorRef.current?.innerHTML || '';
    setForm((prev) => ({
      ...prev,
      primaryData: {
        ...prev.primaryData,
        Description: updatedContent,
      },
    }));
  }, [setForm]);

  const debouncedUpdateForm = useCallback(debounce(updateForm, 300), [
    updateForm,
  ]);

  const handleInput = () => {
    debouncedUpdateForm();
  };

  useEffect(() => {
    if (editorRef.current && !editEnable) {
      const currentContent = editorRef.current.innerHTML;
      if (currentContent !== sanitizedHTML) {
        editorRef.current.innerHTML = sanitizedHTML;
      }
    } else if (editorRef.current && editEnable) {
      editorRef.current.innerHTML = sanitizedHTML;
    }
  }, [sanitizedHTML, editEnable]);

  const handleOnFocus = () => {
    if (editorRef.current && !editEnable) {
      editorRef.current.innerHTML = sanitizedHTML;
    }
  };

  return (
    <HeaderContainer title="Opis" withContainer>
      <div
        style={{
          height: '355px',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        {!editEnable ? (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        ) : (
          <div>
            <div className="flex gap-4 flex-wrap">
              <button onClick={() => handleFormat('bold')}>
                <img
                  src={boldText}
                  alt="icon bold"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
              <button onClick={() => handleFormat('italic')}>
                <img
                  src={italicText}
                  alt="icon italic"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
              <button onClick={() => handleFormat('justifyLeft')}>
                <img
                  src={leftText}
                  alt="icon justify"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
              <button onClick={() => handleFormat('justifyCenter')}>
                <img
                  src={centerText}
                  alt="icon center text"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
              <button onClick={() => handleFormat('justifyRight')}>
                <img
                  src={rightText}
                  alt="icon right text"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
              <button onClick={() => handleFormat('justifyFull')}>
                <img
                  src={justifyText}
                  alt="icon justify text"
                  width="20px"
                  height="20px"
                  className="hover:opacity-50"
                />
              </button>
            </div>

            <div className="flex gap-3 pt-12 items-center">
              <div className="w-8">
                <input
                  type="color"
                  onChange={(e) => handleFormat('foreColor', e.target.value)}
                  title="Select Text Color"
                />
              </div>
              <p className="text-sm">Wybierz kolor czcionki</p>
            </div>

            <div
              ref={editorRef}
              contentEditable={editEnable}
              style={{
                border: '1px solid #ccc',
                height: '280px',
                padding: '10px',
                marginTop: '10px',
                whiteSpace: 'pre-wrap',
                overflowY: 'scroll',
                boxSizing: 'border-box',
              }}
              onInput={handleInput}
              onFocus={handleOnFocus}
              suppressContentEditableWarning={true}
            />
          </div>
        )}
      </div>
    </HeaderContainer>
  );
};

export default AcademyDescription;
