import { useTranslation } from "react-i18next";
import DataTable from "components/common/dataTable/DataTable";
import UserStatusInfo from "components/users/status/UserStatusInfo";
import { TRAINER_TYPE, LICENSE_TYPE } from 'types/transfer/transfer.interface';
import logo from 'assets/images/logo.svg';
interface IDigitalReleaseTableProps {
  players: Array<any>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
  sortBy: any;
}

const TrainersTable: React.FC<IDigitalReleaseTableProps> = ({
  players,
  containerRef,
  isDataLoading,
  lastItemRef,
  sortBy,
}) => {
  const { t } = useTranslation();
  const headers = [
    "Imię i nazwisko",
    "Funkcja",
    "Typ licencji",
    "Status",
  ];

  console.log("players", players);
  const compare = (a: any, b: any, sort: any) => {
    if (a[sort] < b[sort]) {
      return 1;
    }
    if (a[sort] > b[sort]) {
      return -1;
    }
    return 0;
  };

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Name"))
          .reverse();
      case 3:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Name"));
      default:
        return array;
    }
  };

  const sortedArray = sortingFunction(sortBy?.value, players);

  const rows = sortedArray.map((trainer: any) => {
    return {
      data: { link: `/trainers/detail/${trainer.TrainerId}` },
      cols: [

        <div className='h-full flex items-center justify-center text-base'>
          {trainer.Name}
        </div>,
        <div className='h-full flex items-center justify-center text-base'>
          {TRAINER_TYPE[trainer.Type]}
        </div>,
        <div className='h-full flex items-center justify-center text-base'>
          {LICENSE_TYPE[trainer.LicenseType]}
        </div>,
        <div className='h-full flex items-center justify-center text-base'>
          Współpracownik
        </div>,
      ],
    };
  });

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
      emptyListMessage={'Brak trenerów'}
    />
  );
};

export default TrainersTable;
