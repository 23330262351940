import { useState, useRef, useEffect } from "react";
import { ReactComponent as Arrow } from "../../../assets/icons/sortArrow.svg";
import { TABLE_AVG_VAL, TABLE_HEADER } from "../../../types/homeTabelTypes";

type DataTableHeaderProps = {
  headers: TABLE_HEADER;
  filterBy?: (primaryValue: string, secondaryValue: string) => void;
  tabelAvgVal?: TABLE_AVG_VAL;
};

const HomeDataTableHeader: React.FC<DataTableHeaderProps> = ({
  headers,
  filterBy,
  tabelAvgVal,
}) => {
  const [isRotated, setIsRotated] = useState<{ [key: string]: boolean }>({});
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: string }>(
    {}
  );
  const [originalWidths, setOriginalWidths] = useState<{
    [key: string]: number;
  }>({});

  const tableContainerRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    const storedWidths = localStorage.getItem("columnWidths");
    if (storedWidths) {
      setColumnWidths(JSON.parse(storedWidths));
    } else {
      const initialWidths: { [key: string]: string } = {};
      const originalWidthsTemp: { [key: string]: number } = {};
      Object.keys(headers).forEach((key) => {
        initialWidths[key] = "auto";
      });
      setColumnWidths(initialWidths);

      setTimeout(() => {
        Object.keys(headers).forEach((key) => {
          const width = (
            tableContainerRef.current!.querySelector(
              `th[data-key="${key}"]`
            ) as HTMLElement
          ).offsetWidth;
          originalWidthsTemp[key] = width;
        });
        setOriginalWidths(originalWidthsTemp);
      }, 0);
    }
  }, [headers]);

  useEffect(() => {
    localStorage.setItem("columnWidths", JSON.stringify(columnWidths));
  }, [columnWidths]);

  const handleMouseDown = (e: React.MouseEvent, key: string) => {
    const startX = e.clientX;
    const startWidth = (
      tableContainerRef.current!.querySelector(
        `th[data-key="${key}"]`
      ) as HTMLElement
    ).offsetWidth;
    const originalWidth = originalWidths[key] || startWidth;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const newWidth = startWidth + moveEvent.clientX - startX;
      const minWidth = 0.55 * originalWidth;

      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [key]: `${Math.max(newWidth, minWidth)}px`,
      }));
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleSortPlayer = (
    key: string,
    primaryValue: string,
    secondaryValue?: string
  ) => {
    if (filterBy) {
      filterBy(primaryValue, secondaryValue || "");

      let keys = primaryValue;
      if (secondaryValue) {
        keys = `${primaryValue} ${secondaryValue}`;
      }

      setIsRotated((prevState) => {
        const newState = Object.keys(prevState).reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {} as { [key: string]: boolean });

        return {
          ...newState,
          [keys]: !prevState[keys],
        };
      });
    }
  };

  const isTableContains = {
    Zadań: "Zadań",
    Dynamika: "Dynamika",
    Energia: "Energia",
    Kondycja: "Kondycja",
  };

  return (
    <thead ref={tableContainerRef}>
      <tr>
        {Object.entries(headers).map(([key, values]) => (
          <th
            key={key}
            data-key={key}
            style={{
              padding: "10px 0 0 0",
              width: columnWidths[key] || "auto",
              position: "relative",
            }}
          >
            <div>
              <span className="text-center" style={{ fontSize: "1cqw" }}>
                <div
                  style={{
                    fontWeight: "500",
                    width: "100%",
                    marginBottom: "10px",
                    fontSize: "0.7cqw",
                  }}
                >
                  <div className="flex justify-center items-center">
                    <span>{values[0]}</span>
                    {Object.keys(isTableContains).includes(values[0]) && (
                      <Arrow
                        className="ml-1"
                        style={{
                          width: "12px",
                          height: "12px",
                          cursor: "pointer",
                          color: isRotated[`${values[0]}`]
                            ? "rgb(210, 210, 210)"
                            : "rgb(120, 120, 120)",
                          transform: isRotated[`${values[0]}`]
                            ? "rotate(0deg)"
                            : "rotate(300deg)",
                          transition: "transform 0.3s ease",
                          margin: "2px 0 0 10px",
                        }}
                        onClick={() => handleSortPlayer(key, values[0])}
                      />
                    )}
                    <div
                      onMouseDown={(e) => handleMouseDown(e, key)}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "100%",
                        width: "5px",
                        cursor: "col-resize",
                      }}
                    />
                  </div>
                </div>
              </span>
              <div
                style={{
                  display: `${
                    Object.keys(isTableContains).includes(values[0])
                      ? ""
                      : "grid"
                  }`,
                  gridTemplateColumns: `${
                    Object.keys(isTableContains).includes(values[0])
                      ? "repeat(1, 1fr)"
                      : "repeat(2, 50%)"
                  }`,
                  height: "10px",
                }}
              >
                {values.slice(1).map((value: string, valueIdx: number) => (
                  <div
                    key={valueIdx}
                    className="text-sm font-regular opacity-70 flex items-center justify-center"
                    onClick={() => handleSortPlayer(key, values[0], value)}
                    style={{
                      fontSize: "12px",
                      color: "white",
                      opacity: "100%",
                      borderRight: `${
                        valueIdx === 0 &&
                        !Object.keys(isTableContains).includes(values[0])
                          ? "3px solid rgba(2, 2, 15, 0.8)"
                          : ""
                      }`,
                      padding: "0 1px",
                    }}
                  >
                    <span
                      className="font-regular lowercase"
                      style={{ fontSize: "0.70cqw" }}
                    >
                      {value === "" ? (
                        <div style={{ minHeight: "20px" }}></div>
                      ) : (
                        value
                      )}
                    </span>
                    <div style={{ cursor: "pointer" }}>
                      {isNaN(Number(value)) && (
                        <Arrow
                          className="ml-1"
                          style={{
                            color: isRotated[`${values[0]} ${value}`]
                              ? "rgb(210, 210, 210)"
                              : "rgb(120, 120, 120 )",
                            width: "12px",
                            height: "12px",
                            cursor: "pointer",
                            transform: isRotated[`${values[0]} ${value}`]
                              ? "rotate(0deg)"
                              : "rotate(300deg)",
                            transition: "transform 0.3s ease",
                            margin: "2px 0 0 10px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {tabelAvgVal && key in tabelAvgVal && (
                <div
                  style={{
                    gridTemplateColumns: Object.keys(isTableContains).includes(
                      values[0]
                    )
                      ? "repeat(1, 1fr)"
                      : "repeat(2, 50%)",
                    display: Object.keys(isTableContains).includes(values[0])
                      ? ""
                      : "grid",
                  }}
                >
                  {tabelAvgVal[key as keyof TABLE_AVG_VAL].map(
                    (avgValue, idx) => (
                      <div
                        key={idx}
                        className="opacity-70"
                        style={{
                          fontSize: "0.45cqw",
                          textTransform: "lowercase",
                          minHeight: "20px",
                          borderRight: `${
                            idx === 0 &&
                            !Object.keys(isTableContains).includes(values[0])
                              ? "3px solid rgba(2, 2, 15, 0.8)"
                              : ""
                          }`,
                          padding: "12px 1px 5px",
                        }}
                      >
                        {avgValue}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default HomeDataTableHeader;
