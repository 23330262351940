import HeaderContainer from 'components/common/Containers/HeaderContainer';
import TabContent from 'components/common/tabs/TabContent';
import React from 'react';
import { InfoContainer } from 'styles/styles';
import {
  DOMINANT_LEG,
  IPlayerCard,
  PREFERRED_POSITION,
} from 'types/transfer/player.interface';

interface IPlayerCardProps {
  card: IPlayerCard | null;
}

const PlayerCard = ({ card }: IPlayerCardProps) => {
  return (
    <TabContent id='playerCard'>
      <div className='flex w-full max-w-xl'>
        <HeaderContainer title='KARTA ZAWODNIKA' withContainer={!card}>
          {!card ? (
            'Brak dostępu'
          ) : (
            <InfoContainer className='w-full'>
              <div>
                <p>Cel</p>
                  <p>
                    <div className='flex justify-end'>
                      {card.Target.Name}
                    </div>
                  </p>
              </div>
              <div>
                <p>Preferowana Pozycja</p>
                  <p>
                    <div className='flex justify-end'>
                      {PREFERRED_POSITION[card.PreferredPosition]}
                    </div>
                  </p>
              </div>
              <div>
                <p>Noga Dominująca</p>
                  <p>
                  <div className='flex justify-end'>
                    {DOMINANT_LEG[card.DominantLeg]}
                  </div>
                  </p>
              </div>
              <div>
                <p>Fifa Id</p>
                  <p>
                    <div className='flex justify-end'>
                      {!!card.FifaId ? card.FifaId : '-'}
                    </div>
                  </p>
              </div>
              <div>
                <p>Id Zawodnika</p>
                  <p>
                    <div className='flex justify-end'>
                      {!!card.PlayerId ? card.PlayerId : '-'}
                    </div>
                  </p>
              </div>
              <div>
                <p>Charakterystyka</p>
                  <p>
                    <div className='flex justify-end'>
                      {card.Characteristics}
                    </div>
                  </p>
              </div>
            </InfoContainer>
          )}
        </HeaderContainer>
      </div>
    </TabContent>
  );
};

export default PlayerCard;
