import cs from "classnames";
import { ButtonVariant, IButtonProps } from "../buttonTypes";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
const Button = styled.button`
  width: 33px;
  height: 33px;
  background: #02020f;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff3700;
`;

const DeleteButton = ({
  className = "",
  children,
  disabled = false,
  size,
  type = "button",
  variant = ButtonVariant.Normal,
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <Button
      {...props}
      className="rounded-sm"
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid="button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.7"
        height="13"
        viewBox="1290.614 450 11.7 13"
      >
        <g data-name="Icon feather-trash">
          <path
            d="M1290.614 452.6H1302.314"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1.5"
            stroke="#ff0300"
            fill="transparent"
            data-name="Path 3663"
          />
          <path
            d="M1301.014 452.6v9.1a1.3 1.3 0 0 1-1.3 1.3h-6.5a1.3 1.3 0 0 1-1.3-1.3v-9.1m1.95 0v-1.3a1.3 1.3 0 0 1 1.3-1.3h2.6a1.3 1.3 0 0 1 1.3 1.3v1.3"
            stroke-linejoin="round"
            stroke-linecap="round"
            stroke-width="1.5"
            stroke="#ff0300"
            fill="transparent"
            data-name="Path 3664"
          />
        </g>
      </svg>
    </Button>
  );
};

export default DeleteButton;
