import TabContent from 'components/common/tabs/TabContent';
import { useEffect, useState } from 'react';
import trainerService from 'services/transfer/trainerService';
import { IDesignateTrainer } from 'types/transfer/player.interface';
import { ITrainer } from 'types/transfer/trainer.interface';
import PlayerManagerTable from './table/PlayerManagerTable';
import FormTableHeader from 'components/common/FormTable/FormTableHeader';

export interface IPlayerManagerProps {
  designateTrainers: IDesignateTrainer[];
  refresh: () => Promise<void>;
}

const PlayerManager = ({ designateTrainers, refresh }: IPlayerManagerProps) => {
  const [isAddFormDisplayed, displayAddForm] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [academyUnassignedTrainers, setAcademyUnassignedTrainers] = useState<
    ITrainer[]
  >([]);

  const getAcademyUnassignedTrainers = async () => {
    const { Items } = await trainerService.getAcademy();

    const unassignedTrainers = Items.filter(
      item =>
        !designateTrainers?.find(trainer => item.TrainerId === trainer.TrainerId)
    );

    setAcademyUnassignedTrainers(unassignedTrainers);
  };

  useEffect(() => {
    getAcademyUnassignedTrainers();

    if (!designateTrainers?.length) {
      setEditMode(false);
      displayAddForm(true);
    }
  }, [designateTrainers]);

  return (
    <TabContent id='trainner'>
      <div className='flex flex-col w-full gap-1'>
        <FormTableHeader
          header='Trener'
          displayAddForm={displayAddForm}
          isAddFormDisplayed={isAddFormDisplayed}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          editDisabled={!designateTrainers?.length}
          canAdd={false}
          canEdit={false}
        />
        <PlayerManagerTable
          designateTrainers={designateTrainers}
          academyUnassignedTrainers={academyUnassignedTrainers}
          refresh={refresh}
          displayAddForm={displayAddForm}
          isAddFormDisplayed={isAddFormDisplayed}
          isEditMode={isEditMode}
        />
      </div>
    </TabContent>
  );
};

export default PlayerManager;
