// @ts-nocheck
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import ContentContainer from "../../layout/ContentContainer";
import MaskedField from "../../common/inputs/maskedInput/MaskedField";
import SelectProfiles from "../../common/inputs/select/SelectProfiles";
import SubmitButton from "../../common/buttons/submitButton/SubmitButton";
import TextField from "../../common/inputs/textInput/TextField";
import styled from "styled-components";
import { IRegisterDTO } from "../../../types/userTypes";
import { useProfiles } from "../../../hooks/useProfiles";
import { registerInitValues, registerValidationSchema } from "../authHelpers";
import { Mask } from "../../../utils/constants/constants";
import FormSection from "../../common/forms/FormSection";
import authService from "../../../services/authServices";
import { getNotEmptyFields } from "../../../utils/objectUtils";
import { showServerErrors } from "../../../utils/errorsUtils";
import { useHistory } from "react-router-dom";
import { ISelectOption } from "components/common/inputs/inputTypes";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import { createUser } from "services/usersServices";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";

const SingleColumn = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;

  & > p {
    text-align: center;
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;

const SingleRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 280px);
  column-gap: 5px;
`;

const ImageBox = styled.div<{ isAddMenuOpen: any }>`
  position: ${(props) => (props.isAddMenuOpen ? "absolute" : "unset")};

  top: 0;
  left: 0;
  height: 105px;
  background: rgba(0, 145, 255, 1);
  width: 312px;
  display: flex;
  align-items: center;
  z-index: 99;
  transition: all 3s;

  & > * {
    margin-bottom: unset;
  }
`;

interface Props {
  isAddMenuOpen?: any;
}

const RegisterForm: React.FC<Props> = ({ isAddMenuOpen }) => {
  const [selectedItem, setSelectedItem] =
    useState<{ value: number; label: string } | null>(null);
  const { goBack, push } = useHistory();
  const [profiles, setProfiles] = useState([]);

  const handleSubmit = async (values: IRegisterDTO) => {
    const { Email, FirstName, LastName, Position, PhoneNumber } = values;
    try {
      await createUser(Email, FirstName, LastName, Position, PhoneNumber);
      toast.success("Dodano nowego pracownika");
      window.location.reload();
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error(error?.response?.data?.Errors?.Message[0]);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={registerInitValues}
        onSubmit={handleSubmit}
        validationSchema={registerValidationSchema}
      >
        {({ values, isSubmitting }) => (
          <Form
            className="flex"
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <SingleColumn style={{ alignItems: "center", paddingLeft: "15px" }}>
              <p>dane podstawowe</p>
              {/* <NewSelectStatystics
                name="Profile"
                items={profiles}
                label="Profil"
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                style={{ width: "280px", marginBottom: "20px" }}
                // defaultValue={values.profile}
              /> */}
              <TextField name="FirstName" label="Imię" />
              <TextField name="LastName" label="Nazwisko" />
            </SingleColumn>

            <SingleColumn style={{ alignItems: "center" }}>
              <p>email</p>
              <TextField name="Email" label="Adres e-mail" />
            </SingleColumn>
            <SingleColumn>
              <p>dodatkowe</p>

              <MaskedField
                mask={Mask.Phone}
                name="PhoneNumber"
                label="Numer telefonu"
                placeholder="Uzupełnij"
              />
              <TextField
                name="Position"
                label="Stanowisko"
                type="text"
                style={{ marginTop: "15px" }}
              />

              <Button
                variant={ButtonVariant.Save}
                isSubmitting={isSubmitting}
                type="submit"
                className="w-80"
                style={{
                  width: "280px",
                  marginTop: "439px",
                }}
              >
                Zapisz
              </Button>
            </SingleColumn>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisterForm;
