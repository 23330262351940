import { ITab, LIST_SORT } from "types/globalTypes";
import {
  IAddProduct,
  PRODUCT_TYPES,
  PRODUCT_VERIFICATION_STATUS,
} from "types/marketpleace/product.interface";
import { enumToSelectOptions } from "utils/baseUtils";
import ProcessingIco from "../../../../assets/icons/status/processing.svg";
import HoldIco from "../../../../assets/icons/status/hold.svg";
import VerifiedIco from "../../../../assets/icons/status/verified.svg";
import { Filter } from "components/filters/NewFilterPanel";
import { getCategoriesTabFilterValues } from "middleware/category/getCategories";
import { getDetailTabsByEnum, getFilterTabsByEnum } from "utils/objectUtils";
import { EnumDeclaration, EnumType } from "typescript";

export const getProductTypes = enumToSelectOptions(PRODUCT_TYPES);

export const getSorts = enumToSelectOptions(LIST_SORT);

export const productInitialValues: IAddProduct = {
  Visibility: 1,
  Name: "",
  Description: "",
  ShortDescription: "",
  BannerPhotoFilePath: "",
  Tag: "",
  PhotoFilesPath: [""],
  Type: 0,
  CategoryId: "",
};

export const productHeaders = [
  "Nazwa",
  "Kategoria",
  "Typ produktu",
  "Zapas",
  "Dostępność w sklepie",
  "Status",
];

export const getIconSrcByProductStatus = (
  status: PRODUCT_VERIFICATION_STATUS,
): string => {
  switch (status) {
    case 0:
      return ProcessingIco;

    case 1:
      return VerifiedIco;

    case 2:
      return HoldIco;
    default:
      return "";
  }
};

export enum PRODUCT_DETAIL_TABS {
  supplementaryData = "Dane uzupełniające",
  attributes = "Atrybuty",
  conditions = "Warunki",
  raport = "Raport",
  documents = "Dokumenty",
}

export const productDetailTabs: Array<ITab> =
  getDetailTabsByEnum(PRODUCT_DETAIL_TABS);

export const getProductFilters = async (): Promise<Filter[]> => [
  {
    id: 0,
    name: "CategoryId",
    pl: "Kategoria",
    type: "select",
    values: await getCategoriesTabFilterValues(),
  },
  {
    id: 1,
    name: "Type",
    pl: "Typ produktu",
    type: "select",
    values: getFilterTabsByEnum(PRODUCT_TYPES),
  },
  {
    id: 2,
    name: "Status",
    pl: "Status",
    type: "select",
    values: getFilterTabsByEnum(PRODUCT_VERIFICATION_STATUS),
  },
];
