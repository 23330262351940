import { SET_MERCHANT_STATUS } from '../actions/academyMerchantStatus';

const initialState = {
  value: false,
};

export const academyMerchantStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MERCHANT_STATUS:
      return { ...state, value: action.payload };
    default:
      return state;
  }
};
