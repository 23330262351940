import React, { ReactNode } from "react";

interface IInfoContainerItemProps {
  header: string;
  content?: ReactNode;
}

const InfoContainerItem = ({ header, content }: IInfoContainerItemProps) => {
  const text =
    typeof content === "string" || content === null || content === undefined
      ? content
        ? content
        : "-"
      : null;

  const isWWW = header.includes("Strona www");
  const isType = header.includes("Typ");

  return (
    <div title={text ? `${header}: ${content}` : header}>
      {!isWWW && !isType && <p>{header}</p>}
      {isWWW && (
        <p style={{ marginRight: "240px" }}>
          <span>Strona</span>{" "}
          <span style={{ textTransform: "lowercase" }}>www</span>
        </p>
      )}
      {isType && (
        <p style={{ width: "50px" }}>
          <span>Typ</span>{" "}
        </p>
      )}
      {text ? (
        <p
          style={{
            display: "flex",
            justifyContent: "flex-end",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: isType ? "98%" : "70%",
            fontSize: isType ? "10px" : "unset",
          }}
        >
          {text}
        </p>
      ) : (
        <div>{content}</div>
      )}
    </div>
  );
};

export default InfoContainerItem;
