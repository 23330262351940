import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ContentContainer from "components/layout/ContentContainer";
import InfoBox from "components/common/boxes/InfoBox/InfoBox";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import Tabs from "components/common/tabs/Tabs";
import TabsView from "components/common/tabs/TabsView";
import TabContent from "components/common/tabs/TabContent";

import DigitalReleaseDetailTopbar from "./TrainersDetailTopbar";
import logo from "../../../assets/images/logo.svg";
import { getAcademy } from "services/academyServices";
import { getPlayerDetail, getTrainerDetail , getTrainerBasicInfo, getTrainerAddress, getTrainerBio} from "services/transferServices";
import RelatedProfilesTab from "./RelatedProfilesTab";
import TrainerDescription from "./TrainerDescription";
import AgreementsTab from "./AgreementsTab";
import TrainerMenager from "./TrainerMenager";
import TrainerHistoryState from 'components/trainers/detail/tabs/HistoryState/TrainerHistoryState';
import { getRelationRequestsToTrainers } from 'services/transferServices'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getTrainerRelationDetail } from 'services/transferServices';
import { TrainerRelationDetail } from '../../../../src/types/transfer/trainer.interface';
import { getDetailTabsByEnum } from 'utils/objectUtils';

interface Address {
  Country: number | null;
  Region: string;
  City: string;
  Street: string;
  BuildingNumber: string;
  FlatNumber: string;
  PostCode: string;
  Longitude: number | null;
  Latitude: number | null;
}

interface Contact {
  PhoneNumber: string;
  Email: string;
}

interface MarketingConsents {
  AcceptedPrivatePolicyAndRegulation: boolean;
  InformationClausule: boolean;
  LaunchTask: boolean;
  MarketingDataProcessing: boolean;
  MarketingDataRecieving: boolean;
}

interface TrainerDetails {
  TrainerId: string;
  Name: string;
  Discipline: string;
  LicenseId: string;
  Address: Address;
  Contact: Contact;
  MarketingConsents: MarketingConsents;
  Description: string;
  JoinAt: Date | null;
  Type: number | null;
}

const initialTrainerDetails = {
  TrainerId: "",
  Name: "",
  Discipline: "",
  LicenseId: "",
  Address: {
      Country: null,
      Region: "",
      City: "",
      Street: "",
      BuildingNumber: "",
      FlatNumber: "",
      PostCode: "",
      Longitude: null,
      Latitude: null
  },
  Contact: {
      PhoneNumber: "",
      Email: ""
  },
  MarketingConsents: {
      AcceptedPrivatePolicyAndRegulation: false,
      InformationClausule: false,
      LaunchTask: false,
      MarketingDataProcessing: false,
      MarketingDataRecieving: false
  },
  Description: "",
  JoinAt: null,
  Type: null
};


interface Props {
  isStructure?: boolean;
  playerId?: string;
  closeTrainerModalOpen?: any;
}

interface ITrainerPermissionsTab {
  permission: boolean;
  label: string;
  id: string;
  disabled?: boolean;
  content?: React.ReactElement;
}

const TrainersDetail: React.FC<Props> = ({
  isStructure,
  playerId,
  closeTrainerModalOpen,

}) => {
  const [trainer, setTrainer] = useState<TrainerDetails>(initialTrainerDetails);
  const { id } = useParams<{ id: string }>();
  const [trainerRelationDetail, setTrainerRelationDetail] = useState<TrainerRelationDetail>();
  const [basicTraninerInfo, setBasicTrainerInfo] = useState<any>(null);
  const [basicTrainerBio, setBasicTrainerBio] = useState<any>(null);

  const [relationRequest, setRelationRequest] = useState('');

  const globalRelationRequestId = useSelector((state: RootState) => state.trainerReqIdReducer);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const  [whichTabActive, setWhichTabActive] = useState<string>('')

  const trainerId = isStructure && playerId ? playerId : id;
  const isAdmin = permission[0]?.Id === -1;
  const isTrainerBioActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 155 );

  const isTrainerAddressActive = isAdmin ? true : !!permission.find((permission: any) => permission.Id === 156 );

  const setTabActive = (tab: string): void => {
  setWhichTabActive(tab)

  }

  useEffect(() => {
    const fetchData = async () => {
      if (!globalRelationRequestId.relationRequestIds.length) {
        const data = await getRelationRequestsToTrainers(trainer);
        const trainerRequests = data.Items
          .filter((req: any) => req.TrainerId === id)
          .map((req: any) => req.RelationRequestId)
          .join('');
        setRelationRequest(trainerRequests);
      } else {
        const trainerRequests = globalRelationRequestId.relationRequestIds
          .filter((req: any) => req.TrainerId === id)
          .map((req: any) => req.RelationRequestId)
          .join('');
        setRelationRequest(trainerRequests);
      }
    };

    fetchData();
  }, [globalRelationRequestId, id, trainer,  basicTrainerBio]);

  const getTrainerDetailFn = async () => {
    try {
      const basicInfoData = await getTrainerBasicInfo(trainerId);
      setBasicTrainerInfo(basicInfoData.Data);

      const promises = [];
      if (isTrainerBioActive && !isAdmin && (whichTabActive === undefined || whichTabActive === 'description')) {
        promises.push(
          getTrainerBio(trainerId).then((data) => {
            setBasicTrainerBio(data.Data);
          })
        );
      }

      if (isTrainerAddressActive && !isAdmin && (whichTabActive === undefined || whichTabActive === 'address')) {
        promises.push(
          getTrainerAddress(trainerId).then((data) => {
            setTrainer(data.Data);
          })
        );
      }
      if (isAdmin) {
        promises.push(
          getTrainerDetail(trainerId).then((data) => {
            setTrainer(data.Data);
          })
        );
      }
      await Promise.all(promises);
    } catch (error) {
      console.error("Error fetching trainer details:", error);
    }
  };

  useEffect(() => {
    getTrainerDetailFn();
  }, [isStructure, playerId, id, permission, whichTabActive]);

  useEffect(() => {
    const fetchTrainerRelationDetail = async () => {
      if (trainer) {
        try {
          const data = await getTrainerRelationDetail(trainer.TrainerId);
          setTrainerRelationDetail(data.Data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchTrainerRelationDetail();
  }, [trainer]);

  const defaultTrainerRelationDetail: TrainerRelationDetail = {
    IsRelationExist: false,
    RelationReceiver: 0,
    RelationRequestId: '',
    TrainerId: '',
    TrainerRelationHistory: [],
  };

  if (!trainer) {
    return <InfoBoxPlaceholder />;
  }

  const tabs = [
    {
      tab: {
        id: "description",
        label: "BIO",
      },
      content: (
        <TabContent id="description">
          <div
            className="flex flex-col lg:flex-row gap-16 mx-auto w-full"
          >
            <TrainerDescription
              order={{
                Description:
                trainer?.Description
                  ? trainer.Description
                  : "Brak opisu",
              }}
            />
          </div>
        </TabContent>
      ),
    },
    {
      tab: {
        id: "address",
        label: "Adres",
      },

      content: <RelatedProfilesTab trainer={trainer} />,
    },
    {
      tab: {
        id: "payments",
        label: "Płatności",
      },
      content: <TabContent id="payments"></TabContent>,
    },

    {
      tab: {
        id: "manager",
        label: "Obserwowani",
      },
      content: (
        <TabContent id="manager">
          <TrainerMenager />
        </TabContent>
      ),
    },

    {
      tab: {
        id: "status",
        label: "Status",
      },
      content: <TabContent id="status">
        <TrainerHistoryState
          trainderData={trainer}
          getReqIdtrainer={relationRequest}
          trainerRelationDetail={trainerRelationDetail || defaultTrainerRelationDetail}
        />
      </TabContent>
    },

    // {
    //   tab: {
    //     id: "agreements",
    //     label: "Zgody",
    //   },
    //   content: <AgreementsTab key="agreements" trainerProfile={trainer} />,
    // },
  ];

  const trainerStatus = trainerRelationDetail?.IsRelationExist ? 1 : 0;

  enum TRAINERS_TABS {
    description = 'BIO',
    address = 'Adres',
    payments = 'Płatności',
    manager = 'Obserwowani',
    status = 'Status'
  }

  const PERMISSIONS: Record<string, string> = {
    "192": "BIO",
    "193": "Adres",
    "194": "Płatności",
    "195": "Obserwowani",
    "196": "Status",
  };

  const TRAINER_PERMISSIONS: Record<string, string> = {
    "155": "BIO",
    "156": "Adres",
    "157": "Płatności",
    "158": "Obserwowani",
    "178": "Status",
  }

  const trainersTabs = getDetailTabsByEnum(TRAINERS_TABS);

  const isTrainerOrGroupeActive = (id: number) => permission?.some((trainer: any) => trainer.Id === id);

  const trainerTabsWithPermissions: ITrainerPermissionsTab[] = trainersTabs.map((tab: any) => {
    const hasPermission = permission.find((perm: { Id: number }) => {
      if (isTrainerOrGroupeActive(137)) {
        return TRAINER_PERMISSIONS[perm.Id] === tab.label;
      } else if (isTrainerOrGroupeActive(224)) {
        return PERMISSIONS[perm.Id] === tab.label;
      }
      return false;
    });

    return {
      ...tab,
      disabled: !hasPermission,
    };
  });

  const trainersTabsPermissions = permission[0]?.Id === -1 ? trainersTabs : trainerTabsWithPermissions;

  return (
    <ContentContainer
      title={basicTraninerInfo?.Name}
      TopBar={
        <DigitalReleaseDetailTopbar
          event={[]}
          isStructure={isStructure}
          trainerStatus={trainerStatus}
          trainerId={playerId}
        />
      }
      path={"/outside-trainers"}
      fn={closeTrainerModalOpen}
      noArrow={isStructure}
    >
      <div>
        {!isStructure && (
          <div className="flex flex-col">
            <InfoBox className="p-18">
              <InfoBox.Image
                // src={logo}
                // src={trainer?.PhotoFilePath ? trainer?.PhotoFilePath : logo}
                src={logo}
              />

              <InfoBox.Items>
                <InfoBox.InfoItem
                  label={"Imię i nazwisko"}
                  value={`${basicTraninerInfo?.Name}`}
                // value={`Testowy trener`}
                />
                <InfoBox.InfoItem
                  label={"Email"}
                  value={basicTraninerInfo?.Contact?.Email ? basicTraninerInfo.Contact.Email : "-"}
                // value={"test@gmail.com"}
                />
                <InfoBox.InfoItem
                  label={"Status"}
                  value={trainerStatus ? 'Wspólpracownik' : 'Brak współpracy'}
                // value={"test@gmail.com"}
                />

                {/* <InfoBox.InfoItem
                  label={"Dyscyplina"}
                  value={trainer?.Discipline ?? "-"}
                  // value={"Testowa"}
                /> */}
                <InfoBox.InfoItem
                  label={"Licencja"}
                  value={basicTraninerInfo?.LicenseId ? basicTraninerInfo.LicenseId : "-"}
                // value={"Nie"}
                />
                <InfoBox.InfoItem
                  label={"Telefon"}
                  value={
                    basicTraninerInfo?.Contact?.PhoneNumber
                      ? basicTraninerInfo.Contact.PhoneNumber
                      : "-"
                  }
                />
                <InfoBox.InfoItem
                  label={"Typ umowy"}
                  value={"nie ma"}
                // value={
                //   trainer?.Contact?.PhoneNumber
                //     ? trainer.Contact.PhoneNumber
                //     : "-"
                // }
                />
                {/* <InfoBox.InfoItem
                  label={"Data przystÄ…pienia"}
                  value={trainer?.JoinAt?.slice(0, 10)}
                  // value={"25-12-2023"}
                /> */}
              </InfoBox.Items>
            </InfoBox>
          </div>
        )}

        <TabsView>
          <Tabs
            tabs={trainersTabsPermissions}
            setTabActive={setTabActive}
          />
          <div style={{ padding: "10px 0 0 0" }}>
            {tabs.map((t, index) => (
              <div key={index}>
                {t.content}
              </div>
            ))}
          </div>
        </TabsView>
      </div>
    </ContentContainer>
  );
};

export default TrainersDetail;
