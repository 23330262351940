import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React from 'react';

interface IInfoHeaderContentProps {
  isEditing: boolean;
  onSave: () => void;
  handleEdit: () => void;
  isLoading: boolean;
  isSmaller?: boolean;
  isSaveDisable?: boolean;
  isEditDisable?: boolean;
}

const InfoHeaderContent = ({
  isEditing,
  onSave,
  handleEdit,
  isLoading,
  isSmaller,
  isSaveDisable,
  isEditDisable,
}: IInfoHeaderContentProps) => {
  return (
    <div className={`flex ${isSmaller ? 'p-4 gap-1' : 'p-8 gap-2'}`}>
      {isEditing && (
        <Button
          className={isSmaller ? 'h-9 w-11' : ''}
          variant={ButtonVariant.Save}
          onClick={onSave}
          disabled={isLoading || isSaveDisable}>
          {!isLoading || isSaveDisable ? 'Zapisz' : 'Zapisuję...'}
        </Button>
      )}
      <Button
        className={isSmaller ? 'h-9 w-11' : ''}
        variant={isEditing ? ButtonVariant.Cancel : ButtonVariant.Edit}
        onClick={handleEdit}
        disabled={isLoading || isEditDisable}>
        {!isEditing || isEditDisable ? 'Edytuj' : 'Anuluj'}
      </Button>
    </div>
  );
};

export default InfoHeaderContent;
