import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GridColumn } from 'styles/styles';
import AcademyPassesAddForm from './AcademyPassesAddForm';
import AcademyPassesRow from './AcademyPassesRow';
import { IPass, IPassDetail } from 'types/transfer/pass.interface';
import AcademyPassesHeaders from './AcademyPassesHeaders';

interface IAcademyPassesTableProps {
  isAddFormDisplayed: boolean;
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  passes: IPass[];
  setPass: Dispatch<SetStateAction<IPass | null>>;
  refreshPasses: () => Promise<void>;
}

const AcademyPassesTable = ({
  displayAddForm,
  isAddFormDisplayed,
  isEditMode,
  passes,
  setPass,
  refreshPasses,
}: IAcademyPassesTableProps) => {
  const [updatedPass, setUpdatedPass] = useState<IPass>();

  useEffect(() => {
    console.log(updatedPass);
  }, [updatedPass]);

  return (
    <div>
      <GridColumn cols={9} largerColumn={1}>
        <AcademyPassesHeaders />
        {isAddFormDisplayed && (
          <AcademyPassesAddForm
            displayAddForm={displayAddForm}
            refreshPasses={refreshPasses}
          />
        )}

        {passes.map((pass: any, idx: number) => (
          <AcademyPassesRow
            isEditMode={isEditMode}
            updatedPass={
              updatedPass?.PassId === pass.PassId ? updatedPass : undefined
            }
            setUpdatedPass={setUpdatedPass}
            pass={pass}
            setPass={setPass}
            refreshPasses={refreshPasses}
            key={idx}
          />
        ))}
      </GridColumn>
    </div>
  );
};

export default AcademyPassesTable;
